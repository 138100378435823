const turf = require("@turf/turf");

function calculateTotalArea(feature) {
  if (!feature) return 0;

  const polygon = feature.geometry;
  if (polygon.type === "Polygon" || polygon.type === "MultiPolygon") {
    return turf.area(polygon);
  }
  return 0;
}

export default function getColorAreas(selectedPlot) {
  if (
    !selectedPlot ||
    !selectedPlot.features ||
    !Array.isArray(selectedPlot.features)
  ) {
    return [];
  }

  const features = selectedPlot.features;
  const colorInfoMap = new Map();

  for (const feature of features) {
    const properties = feature.properties;
    if (properties) {
      const fillColor = properties.FillColor || properties.fillColor;
      const area = calculateTotalArea(feature);
      const fertilizerType = properties.fertilizer_type;
      const kgPerH = properties.kg_p_h;
      const numberOfSeeds = properties.number_of_seeds_p_ha;
      const seedBrand = properties.seed_brand;

      const colorData = {
        area,
        fertilizerType,
        kgPerH,
        numberOfSeeds,
        seedBrand
      };

      if (colorInfoMap.has(fillColor)) {
        const existingData = colorInfoMap.get(fillColor);
        colorInfoMap.set(fillColor, { ...existingData, ...colorData });
      } else {
        colorInfoMap.set(fillColor, colorData);
      }
    }
  }

  const colorAreas = [...colorInfoMap.entries()].map(([color, data]) => ({
    color,
    size: (data.area * 0.0001).toFixed(2),
    fertilizerType: data.fertilizerType || "N/A",
    kgPerH: data.kgPerH || "N/A",
    numberOfSeeds: data.numberOfSeeds || "N/A",
    seedBrand: data.seedBrand || "N/A",
  }));

  return colorAreas;
}
