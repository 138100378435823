import React, {useEffect, useState} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
}));

const AuthImage = ({src, className, ...props}) => {
  const classes = useStyles();

  const [imageSrc, setSrc] = useState(null);

  useEffect(() => {
    (async() => {
      if (!src || src === 'Example Street') {
        setSrc('/images/empty-avatar.png');
        return;
      }
      setSrc(src);
    })();
  }, [src]);

  return (
    <div
      className={clsx(classes.root, className)}
    >
      {src && <img
        alt=''
        className={classes.img}
        src={imageSrc || null}
        {...props}
      />}
    </div>
  );
};

AuthImage.propTypes = {
  src: PropTypes.string,
};

export default AuthImage;
