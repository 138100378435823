import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <rect
            x="1.9"
            y="14"
            className="st0"
            width="8.9"
            height="35.5"
            fill="#4E6F7B"
          />
          <rect
            x="20.6"
            y="0.5"
            className="st0"
            width="8.9"
            height="49"
            fill="#4E6F7B"
          />
          <rect
            x="39.2"
            y="28.5"
            className="st0"
            width="8.9"
            height="21"
            fill="#4E6F7B"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
