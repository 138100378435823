import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M40.6,43.4L29.7,25c0-0.1-0.1-0.1-0.1-0.2c0-3.5,0-6.9,0-10.4c0.1,0,0.3,0,0.4,0c1.1,0,2.1-0.8,2.4-1.9
		c0.3-1.1-0.2-2.3-1.2-2.8c-0.5-0.3-1.1-0.4-1.6-0.4c-2.2,0-4.3,0-6.5,0h-4.3c-1.4,0-2.5,1-2.6,2.3C16,13,17,14.2,18.3,14.4
		c0.2,0,0.4,0,0.6,0l0,1.2c0,3.1,0,6.2,0,9.2c0,0.1,0,0.1-0.1,0.2L9.7,42.7l-0.1,0.2c-0.2,0.3-0.4,0.7-0.5,1.1
		c-0.5,2.2,0.7,4.5,2.8,5.3c0.2,0.1,0.5,0.2,0.8,0.2c4.8,0,9.6,0,14.4,0c3.3,0,6.6,0,9.9,0c0.2,0,0.4,0,0.5-0.1
		c1.8-0.6,2.9-1.9,3.2-3.8C41,45,41,44.2,40.6,43.4z M11.4,44.4c0.1-0.2,0.2-0.5,0.3-0.7l2.7-5.3h20.6l3.3,5.8
		c0.3,0.5,0.3,1.1-0.1,1.9c-0.2,0.5-0.6,0.8-1.2,1c-0.1,0.1-0.3,0.1-0.4,0.1c-7.8,0-15.7,0-23.5,0c-0.4,0-0.8-0.2-1.2-0.7
		C11.4,45.8,11.3,45.2,11.4,44.4z M21,25.5c0.1-0.2,0.2-0.5,0.2-0.7c0-3.3,0-6.7,0-10l0-2.3c0-0.3-0.2-0.5-0.5-0.5h-1.9
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.3,0h10.9c0.2,0,0.3,0.1,0.4,0.2
		c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.2,0.1-0.3,0.1h-2c-0.3,0-0.5,0.2-0.5,0.5l0,2.2c0,3.3,0,6.7,0,10c0,0.2,0.1,0.5,0.2,0.7l6.3,10.8
		H15.5L21,25.5z"
          />
          <circle className="st0" cx="22.8" cy="6.2" r="1.3" />
          <circle className="st0" cx="26.7" cy="1.8" r="1.3" />
          <circle className="st0" cx="27" cy="6.8" r="1.3" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
