import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  LinearProgress
} from "@material-ui/core";
import { toast } from "material-react-toastify";
import { getSamplingFile, downloadExcelFile } from "endpoints/zoneSampling";
import { useMutation } from "react-query";

const ExportSamplingModal = ({
  isOpen,
  onClose,
  selectedSamplingItem,
  selectedSampingName,
  selectedSamplingId,
  type
}) => {
  const [fileType, setFileType] = useState("kmz");
  const [fileName, setFileName] = useState(selectedSampingName);

  const handleClose = () => {
    onClose();
  };

  const { mutate, isLoading } = useMutation(params => getSamplingFile(params), {
    onSuccess: response => {
      onClose();
    }
  });

  const handleExport = async () => {
    const params = {
      geoData: selectedSamplingItem,
      fileType,
      name: fileName
    };

    try {
      const response = await getSamplingFile(params);

      const json = JSON.stringify(response.data);

      const blob = new Blob([json], {
        type: "application/json"
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${fileName}.${fileType}`;

      link.click();

      window.URL.revokeObjectURL(url);
      toast.success("Sampling map exported successfully!");
      onClose();
    } catch (error) {
      toast.error("Error while exporting the file.");
    }
  };

  const excelMutation = useMutation(() => downloadExcelFile(selectedSamplingId), {
    onSuccess: response => {
        downloadFile(response);
        onClose();
  }});

  const downloadFile = data => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Template.xls";

    link.click();
    toast.success("Excel template downloaded successfully!");

    window.URL.revokeObjectURL(url);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
          minWidth: "400px"
        }}
      >
        <Typography variant="h6">{type === 'sampling' ? 'Export Sampling' : 'Export Rx Map'}</Typography>
        {isLoading && <LinearProgress />}
        <Box mt={2}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={fileName}
            onChange={e => setFileName(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <TextField
            select
            label="File type"
            variant="outlined"
            fullWidth
            value={fileType}
            onChange={e => setFileType(e.target.value)}
          >
            <MenuItem value="geojson">geoJson</MenuItem>
            <MenuItem value="kml">kml</MenuItem>
            <MenuItem value="kmz">kmz</MenuItem>
          </TextField>
        </Box>
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleExport}>
            Export
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={excelMutation.mutate}
          style={{ marginTop: "20px", width: "100%" }}
        >
          Download excel template
        </Button>
      </div>
    </Modal>
  );
};

export default ExportSamplingModal;
