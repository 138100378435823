import axiosInstance from '.';

export const getOrganizations = async({ limit, page }) => {
  return axiosInstance
    .get('/organizations', { params: { limit, page: page + 1 } })
    .then(res => res.data);
};

export const getSingleOrganization = async(id) => {
  return axiosInstance
    .get(`/organizations/${id}`)
    .then(res => res.data);
};

export const createOrganization = async organization => {
  return axiosInstance
    .post('/organizations', organization)
    .then(res => res.data);
};

export const editOrganization = async organization => {
  return axiosInstance
    .put(`/organizations/${organization.id}`, organization)
    .then(res => res.data);
};

export const deleteOrganization = async organization => {
  return axiosInstance
    .delete(`/organizations/${organization.id}`)
    .then(res => res.data);
};

export const createOrganizationUser = async({ organization, user }) => {
  return axiosInstance
    .post(`/organizations/${organization.id}/users`, user)
    .then(res => res.data);
};

export const getOrganizationUsers = async({ organization, limit, page }) => {
  return axiosInstance
    .get(`/organizations/${organization.id}/users`, {
      params: { limit, page: page + 1 },
    })
    .then(res => res.data);
};

export const removeOrganizationUser = async({ organization, user }) => {
  return axiosInstance
    .delete(`/organizations/${organization.id}/users/${user.id}`)
    .then(res => res.data);
};

export const changeUserRank = async({ organization, user, rank }) => {
  return axiosInstance
    .put(`/organizations/${organization.id}/users/${user.id}`, { rank })
    .then(res => res.data);
};

export const uploadPhoto = async({organization, selectedPhoto}) => {
  return axiosInstance
    .post(`/organizations/${organization.id}/photo`, selectedPhoto)
    .then(res => res.data);
};