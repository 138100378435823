import _ from 'lodash';
export const DEV_ENV = 'development';
export const PROD_ENV = 'production';

/**
 * Get env
 *
 * @returns {string}
 */
export function getEnv() {
  return process.env.NODE_ENV;
}

/**
 * Check if is development env
 *
 * @returns {boolean}
 */
export function isDevelopment() {
  return _.isEqual(getEnv(), DEV_ENV);
}

/**
 * Check if is production env
 *
 * @returns {boolean}
 */
export function isProduction() {
  return _.isEqual(getEnv(), PROD_ENV);
}
