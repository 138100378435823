import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M4.9,36.4c0.3,0.1,0.6-0.2,0.6-0.5c0-0.2,0-0.4,0-0.6c0-0.6,0-1.2,0-1.9c1.3,0,2.5,0,3.8,0c0,0.8,0,1.6,0,2.3
		c0,0.4,0.2,0.6,0.6,0.6c0.4,0,0.6-0.2,0.6-0.6c0-0.3,0-0.7,0-1c0-0.5,0-0.9,0-1.4c1.3,0,2.5,0,3.8,0c0,0.3,0,0.6,0,0.8
		c0,0.5,0,1,0,1.5c0,0.4,0.2,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6c0-0.1,0-0.3,0-0.4c0-0.6,0-1.3,0-1.9c1.3,0,2.5,0,3.7,0
		c0,0.7,0,1.3,0,2c0,0.7,0.2,1,0.6,1c0.4,0,0.6-0.3,0.6-1c0-0.7,0-1.3,0-2c1.3,0,2.5,0,3.8,0c0,0.8,0,1.5,0,2.2
		c0,0.2,0.1,0.5,0.3,0.6c0.1,0.1,0.4,0.1,0.5,0c0.2-0.1,0.4-0.4,0.4-0.6c0-0.8,0-1.5,0-2.3c1.3,0,2.5,0,3.8,0c0,0.2,0,0.4,0,0.5
		c0,0.6,0,1.2,0,1.9c0,0.2,0.3,0.5,0.5,0.5c0.1,0,0.4-0.2,0.6-0.3c0.1-0.1,0.1-0.3,0.1-0.5c0-0.7,0-1.4,0-2.1c1.3,0,2.5,0,3.7,0
		c0,0.4,0,0.8,0,1.1c0,0.4,0,0.8,0,1.1c0,0.5,0.3,0.8,0.7,0.7c0.4-0.1,0.5-0.4,0.5-0.8c0-0.7,0-1.5,0-2.2c1.3,0,2.5,0,3.8,0
		c0,0.8,0,1.5,0,2.3c0,0.4,0.2,0.7,0.6,0.7c0.4,0,0.6-0.3,0.6-0.6c0-0.2,0-0.3,0-0.5c0-0.6,0-1.2,0-1.8c1.3,0,2.5,0,3.8,0
		c0,0.8,0,1.5,0,2.2c0,0.4,0.1,0.7,0.6,0.8c0.3,0,0.6-0.3,0.6-0.7c0-0.9,0-1.8,0-2.7c0-0.5-0.2-0.7-0.7-0.7c-0.2,0-0.3,0-0.5,0
		c-5.4,0-10.7,0-16.1,0c-0.4,0-0.5-0.1-0.7-0.4c-0.5-1.2-1.1-2.4-1.7-3.6c-0.1-0.2-0.2-0.5-0.2-0.8c0-2,0-4,0-6.1
		c0-0.4-0.2-0.7-0.6-0.7c-0.4,0-0.6,0.3-0.6,0.7c0,0.1,0,0.2,0,0.3c0,1.9,0,3.9,0,5.8c0,0.2-0.1,0.5-0.2,0.7
		c-0.5,1.2-1.1,2.4-1.7,3.6c-0.2,0.4-0.4,0.5-0.8,0.5c-4.8,0-9.7,0-14.5,0c-0.7,0-1.4,0-2.1,0c-0.4,0-0.7,0.2-0.7,0.5
		c0,1.1,0,2.1,0,3.2C4.4,36.1,4.7,36.3,4.9,36.4z"
          />
          <path
            className="st0"
            d="M14.9,28.1c0,1.5,1.1,2.5,2.6,2.6c0.5,0,0.9,0,1.4,0c1.5,0,2.7-1.1,2.7-2.6c0-3.2,0-6.4,0-9.6
		c0-1.5-1.2-2.6-2.7-2.6c-0.4,0-0.8,0-1.2,0c-1.7,0-2.8,1.1-2.8,2.8c0,1.6,0,3.1,0,4.7c0,0.8,0,1.6,0,2.4
		C14.9,26.5,14.9,27.3,14.9,28.1z"
          />
          <path
            className="st0"
            d="M32.5,15.9c-0.4,0-0.8,0-1.3,0c-1.5,0-2.7,1.1-2.7,2.6c0,3.2,0,6.4,0,9.6c0,1.5,1.1,2.6,2.6,2.6
		c0.4,0,0.8,0,1.2,0c1.8,0,2.8-1.1,2.8-2.8c0-1.5,0-3,0-4.6c0-0.8,0-1.6,0-2.4c0-0.8,0-1.6,0-2.4C35.1,17,34,15.9,32.5,15.9z"
          />
          <path
            className="st0"
            d="M15.9,15.2c1.3,0,2.6,0,3.9,0c0.4,0,0.6,0.1,0.8,0.4c0.7,0.7,1.4,1.4,2,2c0.4,0.4,0.8,0.5,1.1,0.2
		c0.3-0.3,0.3-0.6-0.1-1.1c0,0,0-0.1,0-0.1c0.9,0,1.8,0,2.8,0c0,0.1,0,0.1,0,0.1c-0.4,0.5-0.4,0.8-0.1,1.1c0.3,0.3,0.6,0.2,1.1-0.2
		c0.7-0.7,1.4-1.4,2-2c0.2-0.3,0.5-0.4,0.9-0.4c1.3,0,2.7,0,4,0c0.4,0,0.7-0.1,0.8-0.6c0-0.4-0.3-0.6-0.8-0.6c-0.6,0-1.1,0-1.7,0
		c-0.5,0-0.9,0-1.4,0c0-2.9,0-5.6,0-8.4c0.4,0,0.7,0,1.1,0c0.4,0,0.6-0.2,0.6-0.6c0-0.4-0.2-0.6-0.6-0.6c-0.1,0-0.3,0-0.4,0
		c-2.9,0-5.8,0-8.7,0c-0.2,0-0.4,0-0.7,0c0-0.3,0-0.5,0-0.7c0-0.8,0-1.6,0-2.4c0-0.4-0.2-0.7-0.6-0.7c-0.4,0-0.6,0.3-0.6,0.7
		c0,0.2,0,0.4,0,0.6c0,0.8,0,1.6,0,2.5c-0.2,0-0.4,0-0.5,0c-0.8,0-1.6,0-2.4,0c-0.8,0-1.1,0.2-1.1,0.6c0,0.4,0.3,0.6,1.1,0.6
		c0.2,0,0.4,0,0.6,0c0,2.9,0,5.6,0,8.4c-0.2,0-0.4,0-0.5,0c-0.9,0-1.8,0-2.6,0c-0.2,0-0.5,0.2-0.6,0.4c-0.1,0.2-0.1,0.5,0.1,0.7
		C15.4,15.1,15.7,15.2,15.9,15.2z"
          />
          <path
            className="st0"
            d="M44.8,45.6c0.2-0.5,0.5-1.1,0.9-1.5c1.5-1.7,1.5-4.1,0-5.7c-0.3-0.3-0.6-0.4-1-0.1c-0.3,0.2-0.3,0.6,0,1
		c1.2,1.5,1.1,2.8-0.1,4.2c-0.7,0.8-1.1,1.7-1.1,2.8c0,1.1,0.4,2.1,1.2,2.9c0.2,0.2,0.5,0.3,0.7,0.2c0.2,0,0.4-0.3,0.4-0.5
		c0-0.2-0.1-0.5-0.3-0.6C44.8,47.5,44.4,46.6,44.8,45.6z"
          />
          <path
            className="st0"
            d="M35.6,44.1c1.5-1.7,1.5-4,0-5.6c-0.3-0.4-0.7-0.4-1-0.1c-0.3,0.3-0.3,0.6,0,0.9c1.2,1.6,1.2,2.8-0.2,4.3
		c-0.7,0.8-1.1,1.7-1.1,2.4c0,1.4,0.5,2.6,1.2,3.3c0.3,0.2,0.6,0.3,0.9,0c0.3-0.3,0.3-0.6,0.1-0.9c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.5-0.7-0.8-1.5-0.6-2.3C34.8,45.2,35.2,44.6,35.6,44.1z"
          />
          <path
            className="st0"
            d="M41.8,41.3c0-1-0.4-2-1.1-2.8c-0.4-0.4-0.7-0.5-1.1-0.2c-0.3,0.3-0.2,0.6,0.1,1c0.3,0.4,0.6,0.9,0.7,1.4
		c0.3,1.1-0.2,2-0.9,2.8c-1.5,1.7-1.5,4.1,0,5.7c0.3,0.3,0.7,0.4,1,0.1c0.3-0.3,0.3-0.6,0-1c-1.2-1.5-1.1-2.8,0.1-4.2
		C41.4,43.4,41.8,42.4,41.8,41.3z"
          />
          <path
            className="st0"
            d="M10.6,44.1c1.5-1.7,1.5-4.1,0-5.6c-0.3-0.3-0.7-0.4-1-0.1c-0.3,0.2-0.3,0.6,0,1c1.1,1.4,1.1,2.8-0.1,4.2
		c-0.7,0.8-1.1,1.7-1.2,2.8c0,1.1,0.4,2,1.1,2.8c0.4,0.4,0.7,0.4,1,0.2c0.3-0.3,0.3-0.6,0-1c0,0-0.1-0.1-0.1-0.1
		c-0.6-0.7-0.8-1.5-0.6-2.4C9.7,45.1,10.1,44.6,10.6,44.1z"
          />
          <path
            className="st0"
            d="M16.7,41.3c0-1.1-0.4-2.1-1.2-2.9c-0.3-0.3-0.7-0.3-1-0.1c-0.3,0.3-0.3,0.6,0,0.9c0.2,0.3,0.4,0.5,0.6,0.8
		c0.6,1.1,0.3,2.3-0.7,3.4c-1.5,1.7-1.5,4.1,0,5.7c0.3,0.3,0.6,0.4,0.9,0.2c0.3-0.2,0.3-0.6,0-1c-1.1-1.5-1.1-2.8,0.1-4.2
		C16.3,43.4,16.7,42.5,16.7,41.3z"
          />
          <path
            className="st0"
            d="M4.5,38.4c-0.3,0.3-0.3,0.6,0,0.9c1.1,1.1,1.1,3.1-0.1,4.3c-0.8,0.7-1.1,1.7-1.1,2.8c0,1.2,0.4,2.1,1.2,2.9
		c0.2,0.2,0.5,0.2,0.7,0.1c0.2-0.1,0.3-0.4,0.3-0.6c0-0.2-0.2-0.4-0.3-0.6c-0.6-0.8-0.9-1.6-0.6-2.6c0.2-0.6,0.5-1.1,0.9-1.6
		C7,42.4,7,40,5.5,38.4C5.2,38.1,4.8,38.1,4.5,38.4z"
          />
          <path
            className="st0"
            d="M31.7,41.3c0-1-0.4-2-1.2-2.9c-0.3-0.3-0.6-0.3-0.9,0c-0.3,0.3-0.3,0.6,0,0.9c0.1,0.1,0.2,0.3,0.3,0.4
		c0.8,1.2,0.7,2.6-0.4,3.8c-1.6,1.8-1.6,4.3,0,5.8c0.3,0.3,0.6,0.4,0.9,0.1c0.3-0.2,0.3-0.6,0-0.9c-1.1-1.1-1.1-3,0.1-4.3
		C31.4,43.4,31.7,42.5,31.7,41.3z"
          />
          <path
            className="st0"
            d="M21.7,41.1c0-0.9-0.4-1.8-1.2-2.6c-0.3-0.3-0.6-0.4-0.9-0.1c-0.3,0.2-0.3,0.6,0,1c0.3,0.4,0.6,0.8,0.7,1.3
		c0.3,1.1-0.1,2.1-0.9,2.9c-1.5,1.6-1.4,4.2,0.1,5.7c0.3,0.3,0.6,0.4,0.9,0.1c0.3-0.2,0.3-0.7,0-1c-1.1-1-1.2-3,0.2-4.3
		C21.4,43.4,21.7,42.4,21.7,41.1z"
          />
          <path
            className="st0"
            d="M25.6,38.5c-0.3-0.4-0.7-0.4-1-0.2c-0.3,0.3-0.3,0.6,0,1c0.3,0.4,0.5,0.8,0.7,1.2c0.3,1.1,0,2.1-0.8,2.9
		c-0.8,0.8-1.2,1.8-1.2,3.2c0,0.7,0.4,1.7,1.1,2.4c0.4,0.4,0.7,0.5,1,0.2c0.3-0.3,0.3-0.6-0.1-1c-0.5-0.6-0.8-1.3-0.7-2.1
		c0-0.9,0.4-1.5,1-2.1C27.1,42.5,27.1,40.1,25.6,38.5z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
