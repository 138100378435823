import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <path
        className="st0"
        d="M48.2,48.2c-2-0.1-4,0-6,0c0-3.6,0-7.1,0-10.5c0.6,0,1,0,1.5-0.1c3.8-0.4,5.8-2.8,5.4-6.7
	c-0.1-0.9-0.5-1.5-1.4-1.4c-1.9,0.3-3.7,0.7-5.5,1.1c0-1.4,0-3.1,0-5c0.4,0,0.8-0.1,1.1-0.1c4-0.2,6.1-2.5,5.8-6.5
	c-0.1-1.1-0.4-1.8-1.7-1.6c-1.8,0.3-3.6,0.7-5.4,1.1c0-2-0.1-4.4,0-6.7c0-0.7,0.4-1.5,0.8-2c2.9-3.1,2.7-6.7-0.8-9.2
	c-0.3-0.2-1-0.3-1.3-0.1c-2.8,2.3-4.5,5.4-1.1,8.9c1.3,1.3,1.2,2.6,1.1,4.2c-2.3-1.3-4-1.8-5.6-1.6c-0.4,0.1-1.1,0.3-1.2,0.7
	c-1.1,2.6,0.9,6.4,3.6,7.1c1,0.3,2.1,0.3,3.2,0.5c0,1.8,0,3.6,0,5.5c-1.7-1.6-3.7-1.9-5.7-1.7c-0.4,0-1,0.4-1.1,0.7
	c-1,3,1,6.4,4.1,7.1c0.9,0.2,1.8,0.2,2.7,0.3c0,1.8,0,3.6,0,5.5c-2.1-1.4-3.5-1.8-5.6-1.6c-0.4,0-1,0.3-1.2,0.7
	c-1,2.7,0.8,6.3,3.6,7c1,0.3,2.1,0.3,3.2,0.5c0,1.2,0,2.5,0,3.7c-5.1,0-10,0-15,0c0-3.5,0-6.9,0-10.4c0.4,0,0.8-0.1,1.2-0.1
	c4.1-0.3,5.8-2.3,5.7-6.4c0-1.3-0.5-1.9-1.7-1.7c-1.8,0.3-3.6,0.8-5.3,1.2c0-1.5,0-3.2,0-5.1c0.4,0,0.7-0.1,1-0.1
	c4.3-0.2,6.1-2.3,6-6.6c0-1-0.4-1.5-1.4-1.5c-2-0.1-3.8,0.3-5.4,1.7c-0.3-1.9-0.3-1.9,0.7-2.9c3-3.1,2.8-6.5-0.7-9
	c-0.4-0.3-1.3-0.3-1.7,0c-3.4,2.6-3.6,6-0.6,9.1c0.3,0.3,0.8,0.7,0.8,1.1c0.1,2.8,0,5.6,0,8.6c-1.9-1.6-3.8-1.9-5.8-1.7
	c-0.4,0-0.9,0.4-1,0.7c-1,2.6,0.8,6.3,3.6,7c1,0.3,2.1,0.3,3.1,0.5c0,1.8,0,3.6,0,5.4c-2-1.4-3.5-1.8-5.6-1.6
	c-0.4,0.1-1,0.4-1.1,0.7c-1,2.7,0.8,6.2,3.6,7c1,0.3,2.1,0.3,3.2,0.5c0,1.2,0,2.5,0,3.7c-5,0-10,0-14.9,0c0-3.5,0-7,0-10.4
	c0.5,0,0.9,0,1.3-0.1c3.9-0.3,5.8-2.5,5.6-6.4c0-1.2-0.5-1.9-1.7-1.7c-1.8,0.3-3.5,0.7-5.2,1.1c0-1.4,0-3.2,0-5.1c0.5,0,0.8,0,1.2,0
	c4-0.2,6.1-2.7,5.7-6.7c-0.1-0.9-0.4-1.3-1.4-1.4c-2-0.1-3.8,0.3-5.6,1.8c0-2.7-0.1-5.1,0-7.4c0-0.6,0.3-1.4,0.8-1.8
	c2.9-3.1,2.8-6.4-0.6-9.1C9.2,0.6,8.3,0.5,8,0.7C4.9,3.3,3.8,6.5,7,9.7c1.3,1.3,1,2.5,0.9,4c-2-1.3-3.9-1.9-5.6-1.6
	c-0.4,0.1-1.1,0.5-1.2,0.8c-0.9,3.4,1.1,6.5,4.7,7.1c0.7,0.1,1.4,0.1,2.2,0.2c0,1.8,0,3.6,0,5.5c-2.1-1.5-3.6-1.9-5.7-1.6
	c-0.4,0.1-0.9,0.3-1.1,0.6c-1.1,2.5,0.8,6.3,3.5,7.1c1.1,0.3,2.2,0.3,3.3,0.5C8,34.2,8,36,8,38c-1.7-1.5-3.6-1.9-5.7-1.7
	c-0.4,0-1,0.4-1.1,0.7c-1,2.9,0.9,6.4,3.9,7.1C6,44.3,7,44.3,8,44.4c0,1.3,0,2.5,0,3.8c-2.1,0-4.1,0-6.2,0c-0.4,0-0.9,0.4-1.3,0.6
	c0.4,0.2,0.8,0.5,1.3,0.5c15.5,0,31,0,46.5,0c0.4,0,0.8-0.4,1.3-0.5C49.1,48.7,48.6,48.3,48.2,48.2z"
      />
    </SvgIcon>
  );
};

export default Icon;
