import {RestService} from './rest.service';

class ShapeFileService extends RestService {

  static GEO_JSON_STATUS_OK = 0
  static GEO_JSON_STATUS_IN_PROGRESS = -1
  static GEO_JSON_STATUS_ERROR = 1

  getShapeFile(fileId) {
    return super.get(`/organization/shape-files/${fileId}`);
  }

  upload(file, onUploadProgress) {
    let formData = new FormData();
    formData.append('file', file);
    return super.post('organization/shape-files', formData, {
      onUploadProgress,
    });
  }

}

export default ShapeFileService;
