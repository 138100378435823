import React, { Suspense, useEffect, useState, useContext } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import { NavBar, TopBar } from "./components";
import { Popups } from "components/ErrorPopup";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import authService from "services/auth.service";
import { getConfig, login } from "actions";
import CircularLoader from "components/Loader/CircularLoader";
import { PageLoaderContext } from "context/Page";
import { ActiveOrgContext, AuthContext } from "App";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative"
  },
  topBar: {
    zIndex: 2,
    position: "relative"
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  navBar: {
    zIndex: 3,
    width: 300,
    minWidth: 300,
    flex: "0 0 auto",
    position: "relative",
    left: 0
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
    display: "flex",
    position: "relative",
    left: 0
  },
  circularProgress: {
    margin: "auto"
  }
}));

const Dashboard = props => {
  const [activeOrg] = useContext(ActiveOrgContext);
  const [authContext] = useContext(AuthContext);
  const location = useLocation();
  const { t } = useTranslation();
  const { route } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const [isLoadingPage, setPageLoading] = useState(false);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [drawerCollapsed, setDrawerCollapsed] = useState(false);

  useEffect(() => {
    if (session.isLoggedIn && authService.getJwt()) {
      setPageLoading(true);
      dispatch(login());
      dispatch(getConfig());
      setPageLoading(false);
    }
  }, [dispatch, session.isLoggedIn]);

  const handleNavBarMobileOpen = () => setOpenNavBarMobile(true);
  const handleNavBarMobileClose = () => setOpenNavBarMobile(false);

  if (
    (!activeOrg?.userOrganizations?.find(
      user => user.userId === authContext.id
    ) ??
      false) &&
    location.pathname !== "/settings/profile" &&
    location.pathname !== "/settings/organizations"
  ) {
    toast.info(t("form:selectOrganization"));
    return <Redirect to="/settings/organizations" />;
  }

  if (
    !activeOrg &&
    location.pathname !== "/settings/profile" &&
    location.pathname !== "/settings/organizations"
  ) {
    toast.info(t("form:selectOrganization"));
    return <Redirect to="/settings/organizations" />;
  }

  return localStorage.getItem("access_token") ? (
    <>
      <PageLoaderContext.Provider value={{ isLoadingPage, setPageLoading }}>
        <div className={classes.root}>
          {isLoadingPage && <CircularLoader isLoading={true} />}
          <>
            <Popups />
            <TopBar
              className={classes.topBar}
              onOpenNavBarMobile={handleNavBarMobileOpen}
              setDrawerCollapsed={setDrawerCollapsed}
            />
            <div className={classes.container}>
              <NavBar
                className={classes.navBar}
                onMobileClose={handleNavBarMobileClose}
                openMobile={openNavBarMobile}
                drawerCollapsed={drawerCollapsed}
                style={{
                  position: drawerCollapsed ? "relative" : "relative",
                  minWidth: drawerCollapsed ? 110 : 300,
                  width: drawerCollapsed ? 110 : 300
                }}
              />
              <main className={classes.content}>
                <Suspense fallback={<LinearProgress />}>
                  {renderRoutes(route.routes)}
                </Suspense>
              </main>
            </div>
          </>
        </div>
      </PageLoaderContext.Provider>
    </>
  ) : (
    <Redirect to="/auth/login" />
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
