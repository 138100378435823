import * as turf from '@turf/turf';

const getAreaWithoutObstacles = geoJson => {
  if (geoJson.type === 'feature') {
    geoJson = {
      type: 'FeatureCollection',
      features: [geoJson],
    };
  }

  if (!geoJson || !geoJson.features) {
    return '0';
  }

  const calcArea = feature => {
    const featureCollection = {
      type: 'FeatureCollection',
      features: [feature],
    };
    const area = turf.area(featureCollection);
    return area;
  };

  const obstacles = geoJson.features.filter(
    feature => feature.properties && feature.properties.class === 'obstacle'
  );

  const nonObstacles = geoJson.features.filter(
    feature => feature.properties.class !== 'obstacle'
  );

  const obstacleSize = parseFloat(
    obstacles.reduce((acc, obstacle) => acc + calcArea(obstacle), 0) * 0.0001
  );

  const nonObstacleSize = parseFloat(
    nonObstacles.reduce((acc, nonObstacle) => acc + calcArea(nonObstacle), 0) * 0.0001
  );

  const totalNoObstacleSize = nonObstacleSize - obstacleSize;

  return totalNoObstacleSize.toFixed(2);
};

export default getAreaWithoutObstacles;
