import {isDevelopment} from 'utils/util';

export class logger {

  static info(...info) {
    if (isDevelopment()) {
      console.info(...info);
    }
  }

  static log(...log) {
    if (isDevelopment()) {
      console.log(...log);
    }
  }

  static warn(...warn) {
    if (isDevelopment()) {
      console.warn(...warn);
    }
  }

  static error(...error) {
    if (isDevelopment()) {
      console.error(...error);
    }
  }

}
