/* eslint-disable */

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { ActiveOrgContext } from 'App';
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  TextField,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'utils/axios';
import { PricingModal, NotificationsPopover } from 'components';
import { addErrorMessage, setActiveSeason, setSeasons } from 'actions';
import OrganizationsPopover from 'components/OrganizationsPopover';
import { PageLoaderContext } from 'context/Page';
import { OptionField } from 'components/Form/Fields';
import { useTranslation } from 'react-i18next';
import SeasonService from 'services/season.service';
import {
  getUsersActiveOrganizationDomain,
  userHasActiveOrganizationDomain,
} from 'utils/user';
import { getResponsePayload, isOk } from 'utils/request-response';
import { logger } from 'utils/logger';
import { locales } from 'const/locales';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '1px 1px 1px #dadada',
    borderBottom: '1px solid #cccccc',
    backgroundColor: '#ffffff',
  },
  flexGrow: {
    flexGrow: 1,
  },
  seasons: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 5,
    flexBasis: 200,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  seasonInput: {
    margin: 0,
    '& select': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '& fieldset': {
      borderColor: '#cccccc',
    },
  },
  seasonInputSelectSeason: {
    '& fieldset': {
      borderColor: 'red',
    },
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    border: '1px solid #cccccc',
    borderRadius: 5,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  organizationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, setDrawerCollapsed, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const organizationsRef = useRef(null);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openOrganizations, setOpenOrganizations] = useState(false);
  const user = useSelector(state => state.session.user);
  const organizations = useSelector(state => state.session.user.organizations);
  const seasons = useSelector(state => state.session.seasons);
  const activeSeason = useSelector(state => state.session.activeSeason);
  const { setPageLoading } = useContext(PageLoaderContext);
  const [activeOrg, setActiveOrg] = useContext(ActiveOrgContext);

  useEffect(() => {
    let mounted = true;

    const fetchNotifications = () => {
      axios.get('/api/account/notifications').then(response => {
        if (mounted) {
          setNotifications(response.data.notifications);
        }
      });
    };

    fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    (async() => {
      try {
        setPageLoading(true);
        if (userHasActiveOrganizationDomain(user)) {
          const service = new SeasonService();
          service.setDomain(getUsersActiveOrganizationDomain(user));
          const seasonsResponse = await service.getSeasons();
          if (isOk(seasonsResponse)) {
            const seasons = getResponsePayload(seasonsResponse);
            dispatch(setSeasons(seasons));
            if (seasons.length > 0) {
              dispatch(setActiveSeason(seasons[0]));
            }
          }
        }
        setPageLoading(false);
      } catch (e) {
        logger.error(e);
        setPageLoading(false);
        dispatch(addErrorMessage(t('form:seasons.errorGettingData')));
      }
    })();
  }, [dispatch, setPageLoading, t, user]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('userData');
    history.replace('/auth/login');
  };

  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleOrganizationsOpen = () => {
    setOpenOrganizations(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };
  const handleOrganizationsClose = () => {
    setOpenOrganizations(false);
  };

  const handleSearchChange = event => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const handleActiveSeasonChange = event => {
    const seasonId = event.target.value;
    const season = seasons.find(season => season.id === seasonId);
    if (season) {
      setPageLoading(true);
      dispatch(setActiveSeason(season));
      setPageLoading(false);
    }
  };

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState({id: i18n.language, label: t(`lang:${i18n.language}`)});

  const handleLanguageChange = val => {
    i18n.changeLanguage(val.id);
    setLanguage(t(`lang:${val.id}`));
    localStorage.setItem('lang', val.id);
  };

  const popularSearches = [
    'AcreFx Dashboard',
    'AcreFx',
    'Admin Pannel',
    'Project',
    'Pages',
  ];

  const allLanguages = locales.map(locale => {
    const langName = 'lang:' + locale;
    return {
      id: locale,
      label: t(langName)
    };
  });

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Agtrinity" src="/images/logos/agtrinity-logo.svg" height={50} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <div className={classes.seasons}>
            <OptionField
              className={classes.seasonInput}
              disabled
              label={t('form:topBar.seasons.label')}
              onChange={handleActiveSeasonChange}
              name="activeSeason"
              size="small"
              value={(activeSeason && activeSeason.id) || ''}
              options={seasons.map(season => ({
                id: season.id,
                label: season.label,
              }))}
            />
          </div>
          <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper className={classes.searchPopperContent} elevation={3}>
                <List>
                  {popularSearches.map(search => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <Autocomplete
            style={{width: 150}}
            label={t('form:language')}
            options={allLanguages}
            variant="outlined"
            name="language"
            value={language}
            onChange={(e, val) => handleLanguageChange(val)}
            disableClearable
            noOptionsText={t('form:noResults')}
            renderInput={params => (
              <TextField
                label={t('form:language')}
                name="rank"
                value={language}
                {...params}
              />
            )}
          />
        </Hidden>
        {/* <IconButton
          className={classes.organizationsButton}
          color="inherit"
          onClick={handleOrganizationsOpen}
          ref={organizationsRef}
        >
          <RadioButtonUncheckedIcon />
        </IconButton> */}
        <Hidden>
          {/* <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            {t('form:logout')}
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <IconButton color="inherit" onClick={() => setDrawerCollapsed(val => !val)}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <PricingModal onClose={handlePricingClose} open={pricingModalOpen} />
      {/* <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      /> */}
      {/* <OrganizationsPopover
        anchorEl={organizationsRef.current}
        organizations={organizations}
        onClose={handleOrganizationsClose}
        open={openOrganizations}
      /> */}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
