import _ from 'lodash';
import {UNDEFINED} from 'const/global';

export const STATUS_CODE_OK = 200;
export const STATUS_CODE_CREATED = 201;
export const STATUS_CODE_ACCEPTED = 202;
export const STATUS_CODE_BAD_REQUEST = 400;
export const STATUS_CODE_FORBIDDEN = 403;
export const STATUS_CODE_NOT_FOUND = 404;
export const STATUS_CODE_INTERNAL_SERVER_ERROR = 500;

/**
 * Get response payload
 *
 * @param response
 * @return {*}
 */
export function getResponsePayload(response) {
  const {
    payload,
  } = response;

  return payload;
}

/**
 * Check if response has status ok
 *
 * @param response
 * @returns {boolean}
 */
export function isOk(response) {
  return getStatusCode(response) === STATUS_CODE_OK;
}

/**
 * Check if response has status ok
 *
 * @param response
 * @returns {boolean}
 */
export function isCreated(response) {
  return getStatusCode(response) === STATUS_CODE_CREATED;
}

/**
 * Check if response has status accepted
 *
 * @param response
 * @returns {boolean}
 */
export function isAccepted(response) {
  return getStatusCode(response) === STATUS_CODE_ACCEPTED;
}

/**
 * Check if response has status bad request
 *
 * @param response
 * @returns {boolean}
 */
export function isBadRequest(response) {
  return getStatusCode(response) === STATUS_CODE_BAD_REQUEST;
}

/**
 * Get status code from response
 *
 * @param response
 * @returns {number}
 */
export function getStatusCode(response) {
  return !_.isNull(response) && !_.isUndefined(response) && _.has(response, 'status_code') ?
    _.get(response, 'status_code') : UNDEFINED;
}
