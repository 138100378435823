import React from 'react';
import {TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  optionField: {
    marginBottom: '20px',
  },
  helperText: {
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

const OptionField = (props) => {

  const {
    name,
    label,
    onChange,
    value,
    required,
    error,
    options,
    className,
    disabled,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <TextField
      {...rest}
      className={clsx(classes.optionField, className)}
      fullWidth
      disabled={disabled}
      label={label}
      name={name}
      error={error}
      onChange={onChange}
      select
      SelectProps={{native: true}}
      value={value}
      variant="outlined"
      required={required}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
    >
      <option key='xxx' value=''> </option>
      {options.map(option => (
        <option key={option.id} value={option.id}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};


OptionField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label:PropTypes.string,
  })),
  value: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

OptionField.defaultProps = {
  options: [],
  required: false,
  disabled: false,
  error: false,
};


export default OptionField;
