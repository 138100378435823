import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {CloudUpload, Done} from '@material-ui/icons';
import _ from 'lodash';
import {colors, TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import ShapeFileService from 'services/shapeFile.service';
import {
  addErrorMessage,
  addSuccessMessage,
} from 'actions/alertActions';
import {logger} from 'utils/logger';
import {getResponsePayload, isOk} from 'utils/request-response';
import {
  getUsersActiveOrganizationDomain,
  userHasActiveOrganizationDomain,
} from 'utils/user';
import clsx from 'clsx';
import {UNDEFINED} from 'const/global';

const useStyles = makeStyles(theme => ({
  inputField: {
    paddingBottom: '20px',
  },
  uploadButton: {
    cursor: 'pointer',
    marginLeft: '5px',
  },
  uploadButtonInactive: {
    marginLeft: '5px',
  },
  uploadProgress: {
    marginLeft: '5px',
    position: 'relative',
    display: 'inline-flex',
  },
  uploadProgressNumeric: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  successIcon: {
    color: colors.green[600],
  },
}));

const ShapeFileUpload = props => {
  const {
    name,
    label,
    file: initialFile,
    value: fileId,
    reset,
    required,
    onChange,
    extension,
    autoUpload,
    className,
    disabled,
    ...rest
  } = props;

  const classes = useStyles();
  const hiddenInputRef = useRef();
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(state => state.session.user);
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploaded, setUploaded] = useState(false);

  // used after user upload new file (or sent as value object)
  const [file, setFile] = useState(initialFile);
  // user attach new file name onto form (C:/fakepath/filename)
  const [fileToUploadPath, setFileToUploadPath] = useState('');
  // user attach new file onto form (entire file object => it is used for upload)
  const [fileToUpload, setFileToUpload] = useState(UNDEFINED);
  const showUploadButton = fileToUploadPath !== '' && fileToUpload != null && !isUploaded;
  const triggerUploadAllowed = !isUploading && !isUploaded && fileToUpload !== UNDEFINED;

  const uploadFile = useCallback(async() => {
    if (fileToUpload != null && userHasActiveOrganizationDomain(user)) {
      try {
        setUploading(true);
        const baseUrl = getUsersActiveOrganizationDomain(user);
        const service = new ShapeFileService();
        await service.setDomain(baseUrl).upload(fileToUpload, (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        }).then(response => {
          if (isOk(response)) {
            const payload = getResponsePayload(response);
            setFile(payload);
            setUploaded(true);
            const event = new Event('input', {bubbles: true});
            hiddenInputRef.current.dispatchEvent(event);
            dispatch(addSuccessMessage(`File ${fileToUpload.name} successfully uploaded`));
          } else {
            dispatch(addErrorMessage(`Error during file ${fileToUpload.name} upload`));
          }
        });
        setUploading(false);
      } catch (e) {
        setUploading(false);
        logger.error(e);
        dispatch(addErrorMessage(`Error during file ${fileToUpload.name} upload`));
      }
    }
  }, [dispatch, fileToUpload, user]);

  const attachFile = event => {
    setProgress(0);
    setUploaded(false);
    setFileToUpload(event.target.files[0]);
    setFileToUploadPath(event.target.value);
  };

  const resetFile = useCallback(() => {
    setProgress(0);
    setUploaded(false);
    setFileToUpload(UNDEFINED);
    setFileToUploadPath('');
    fileInputRef.current.value = '';
  }, []);

  useEffect(() => {
    (async() => {
      setTimeout(() => autoUpload && triggerUploadAllowed && uploadFile(), 4000);
    })();
  }, [triggerUploadAllowed, uploadFile, autoUpload]);

  useEffect(() => {
    (async() => {
      reset && resetFile();
    })();
  }, [reset, resetFile]);

  return (
    <React.Fragment>
      <input
        ref={hiddenInputRef}
        type={'hidden'}
        name={name}
        value={file && _.has(file, 'id') ? file.id : ''}
        onInput={onChange}
        onChange={onChange}
        required={required}
      />
      <TextField
        {...rest}
        ref={fileInputRef}
        required={required}
        className={clsx(classes.inputField, className)}
        fullWidth
        label={label}
        value={fileToUploadPath}
        name={name + '_shapeFile'}
        type='file'
        onChange={attachFile}
        variant="outlined"
        InputLabelProps={{
          readOnly: disabled,
          shrink: true,
        }}
        inputProps={{
          accept: extension,
        }}
        disabled={isUploading || disabled}
        InputProps={{
          className: (disabled) ? 'Mui-disabled' : UNDEFINED,
          endAdornment: (
            <React.Fragment>
              {showUploadButton && <React.Fragment>
                <Box className={classes.uploadProgress}>
                  <CircularProgress
                    variant="static"
                    value={progress}
                  />
                  <Box className={classes.uploadProgressNumeric}>
                    <Typography variant="caption" component="div" color="textPrimary">
                      {`${Math.round(progress)}%`}
                    </Typography>
                  </Box>
                </Box>
                <Tooltip title="Upload file">
                  <CloudUpload
                    className={!isUploaded ? classes.uploadButton : classes.uploadButtonInactive}
                    fontSize='large'
                    color='primary'
                    onClick={!isUploaded ? uploadFile : () => {}}
                  />
                </Tooltip>
              </React.Fragment>}
              {isUploaded && <Done className={classes.successIcon}/>}
            </React.Fragment>
          ),
        }}
      />
    </React.Fragment>
  );
};

ShapeFileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  file: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoUpload: PropTypes.bool,
  reset: PropTypes.bool,
  className: PropTypes.string,
  extension: PropTypes.oneOf(['.zip', '.rar', '.zip, .rar']),
};

ShapeFileUpload.defaultProps = {
  reset: false,
  required: false,
  disabled: false,
  autoUpload: false,
  extension: '.zip',
};

export default ShapeFileUpload;
