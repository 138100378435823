import axios from 'axios';
import {logger} from 'utils/logger';

const URL = 'http://telemetry.ml/api/event/';
const config = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
};

/**
 * We use axios create because we do not need Authorization Bearer token (for now)
 */
class TelemetryService {

  getAllEvents() {
    return axios.create().get(URL)
      .then(response => response.data)
      .catch((error) => logger.error(error));
  }

  getEvent(recordId) {
    return axios.create().get(`${URL}${recordId}`)
      .then(response => response.data)
      .catch((error) => logger.error(error));
  }

  saveEvent(event) {
    const data = JSON.stringify({
      payload: {
        body: event,
        path: window.location.href,
        date: (new Date()).toISOString(),
      },
    });

    return axios.create().post(URL, data, config)
      .then(response => response.data)
      .catch((error) => logger.error(error));
  }

}

const telemetryService = new TelemetryService();

export default telemetryService;

