import _ from 'lodash';
import {translate} from 'utils/translate';
import {UNDEFINED} from 'const/global';
import validate from 'validate.js';

export const REQUIRED = getRequiredFieldSchema();
export const REQUIRED_NUMBER = getRequiredNumericFieldSchema();
export const REQUIRED_PERCENT = getRequiredIntegerFieldSchema(false, 0, 100);
export const DATETIME = getDateTimeFieldSchema();
export const REQUIRED_DATETIME = getRequiredDateTimeFieldSchema();
export const REQUIRED_POSITIVE_INTEGER = getRequiredIntegerFieldSchema(true,0);
export const REQUIRED_POSITIVE_NUMBER = getRequiredIntegerFieldSchema(false,0);
export const EMAIL = getEmailFieldSchema();
export const REQUIRED_EMAIL = getRequiredEmailFieldSchema();
export const REGEX = (regex, msg) => getRegexFieldSchema(regex, msg);
export const REQUIRED_REGEX = (regex, msg) => getRequiredRegexFieldSchema(regex, msg);

/**
 * Create form initial state object
 *
 * @returns {{touched: {}, isValid: boolean, values: {}, errors: {}}}
 */
export function getFormInitState(values = {}) {
  return {
    isValid: false,
    values: values,
    touched: {},
    errors: {},
  };
}

/**
 * Check if field has invalid value in state
 *
 * @param name
 * @param formState
 * @return {boolean}
 */
export function isFieldInvalid(name, formState) {
  const {
    errors,
    touched,
  } = formState;

  const isTouched = touched && _.has(touched, name);
  const isInvalid = errors && _.has(errors, name);
  return isTouched && isInvalid ? true : UNDEFINED;
}

/**
 * Extract field error (if there is some)
 *
 * @param name
 * @param formState
 * @return {*}
 */
export function getFieldError(name, formState) {
  const {
    errors,
  } = formState;

  const errorArr = errors && _.has(errors, name) ? errors[name] : [];

  return isFieldInvalid(name, formState) ? errorArr.join(' ') : UNDEFINED;
}

/**
 * Handle form field change
 *
 * @param event
 * @param formState
 * @param setFormState
 */
export function handleFieldChange(event, formState, setFormState) {
  event.persist();
  const name = getFieldName(event);
  setFormState(formState => ({
    ...formState,
    values: {
      ...formState.values,
      [name]: getFieldValue(event),
    },
    touched: {
      ...formState.touched,
      [name]: true,
    },
  }));
}

/**
 * Handle form field change
 *
 * @param date
 * @param name
 * @param formState
 * @param setFormState
 */
export function handleDateFieldChange(date, name, formState, setFormState) {
  setFormState(formState => ({
    ...formState,
    values: {
      ...formState.values,
      [name]: date.toISOString(),
    },
    touched: {
      ...formState.touched,
      [name]: true,
    },
  }));
}

/**
 * Validate form by given validation schema
 *
 * @param values
 * @param validationSchema
 * @param setFormState
 */
export function validateForm(values, validationSchema, setFormState) {
  const errors = validate(values, validationSchema);
  const noAnswers = _.size(Object.keys(values)) === 0;

  setFormState(formState => ({
    ...formState,
    isValid: !(noAnswers || errors),
    errors: errors || {},
  }));

}

/**
 * Resolve options label (translate if needed)
 *
 * @param options
 * @param value
 * @param trans
 * @param labelName
 * @return {*}
 */
export function getOptionLabel(options, value, trans = false, labelName = 'label') {
  const option = _.find(options, p => p.id === value);
  const label  = _.isObject(option) && _.has(option, labelName) ? option[labelName] : '';
  return trans ? translate(label) : label;
}


function getFieldName(event) {
  return event.target.name;
}

function getFieldValue(event) {
  if (event.target.type === 'checkbox') {
    return event.target.checked;
  } else {
    return event.target.value;
  }
}

/**
 * Required field validation schema
 *
 * @return {{presence: {allowEmpty: boolean, message: *}}}
 */
function getRequiredFieldSchema() {
  return {
    presence: {
      allowEmpty: false,
      message: translate('form:isRequired'),
    },
  };
}

/**
 * Number field validation schema
 *
 * @return {{presence: {allowEmpty: boolean, message: *}}}
 */
function getRequiredNumericFieldSchema() {
  return {
    presence: {
      allowEmpty: false,
      message: translate('form:isRequired'),
    },
    numericality: true,
  };
}

/**
 * Email field validation schema
 *
 * @return {{email: {message: string}}}
 */
function getEmailFieldSchema() {
  return {
    email: {
      message: translate('form:mustBeEmail'),
    },
  };
}

/**
 * Required email field validation schema
 *
 * @return {{numericality: boolean, presence: {allowEmpty: boolean, message: *}}}
 */
function getRequiredEmailFieldSchema() {
  return {
    presence: {
      allowEmpty: false,
      message: translate('form:isRequired'),
    },
    email: {
      message: translate('form:mustBeEmail'),
    },
  };
}

/**
 * Date time validation schema
 *
 * @return {{datetime: {message: *}}}
 */
function getDateTimeFieldSchema() {
  return {
    datetime: {
      message: translate('form:mustBeDateTime'),
    },
  };
}

/**
 * Required date time validation schema
 *
 * @return {{datetime: {message: *}, presence: {allowEmpty: boolean, message: *}}}
 */
function getRequiredDateTimeFieldSchema() {
  return {
    presence: {
      allowEmpty: false,
      message: translate('form:isRequired'),
    },
    datetime: {
      message: translate('form:mustBeDateTime'),
    },
  };
}

/**
 * Required url field validation schema
 */
function getRequiredRegexFieldSchema(regex, msg) {
  return {
    presence: {
      allowEmpty: false,
      message: translate('form:isRequired'),
    },
    format: {
      pattern: regex,
      message: translate(msg),
    },
  };
}

/**
 * Required url field validation schema
 */
function getRegexFieldSchema(regex, msg) {
  return {
    format: {
      pattern: regex,
      message: translate(msg),
    },
  };
}

/**
 * Integer field validation schema
 *
 * @return {{presence: {allowEmpty: boolean, message: *}}}
 */
function getRequiredIntegerFieldSchema(onlyInteger = false, min = -Infinity, max = Infinity) {
  let msg = translate('form:mustBeInteger');
  if (!_.isEqual(min, -Infinity)) {
    msg += ' ' + translate('form:greaterOrEqualThen', {min});
  }
  if (!_.isEqual(max, Infinity)) {
    msg += ' ' + translate('form:lessOrEqualThen', {max});
  }
  return {
    presence: {
      allowEmpty: false,
      message: translate('form:isRequired'),
    },
    numericality: {
      onlyInteger: onlyInteger,
      greaterThanOrEqualTo: min,
      lessThanOrEqualTo: max,
      message: msg,
    },
  };
}
