import configService from 'services/config.service';
import {addErrorMessage} from 'actions/alertActions';
import {logger} from 'utils/logger';
import {depthOptions} from 'const/fieldForms';
import {getResponsePayload, isOk} from 'utils/request-response';
import {translate} from 'utils/translate';

export const SET_CROPS = 'SET_CROPS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_STATES = 'SET_STATES';
export const SET_DEPTHS = 'SET_DEPTHS';

export const getConfig = () => async(dispatch) => {
  dispatch(fetchDepths());
  dispatch(fetchCrops());
  dispatch(fetchCountries());
  dispatch(fetchStates());
};

function setDepths(depths) {
  return {
    type: SET_DEPTHS,
    depths,
  };
}

function setCrops(crops) {
  return {
    type: SET_CROPS,
    crops,
  };
}

function setCountries(countries) {
  return {
    type: SET_COUNTRIES,
    countries,
  };
}

function setStates(states) {
  return {
    type: SET_STATES,
    states,
  };
}

const fetchDepths = () => async(dispatch) => {
  try {
    dispatch(setDepths(depthOptions));
  } catch (e) {
    logger.error(e);
    dispatch(addErrorMessage(translate('config.depth.get.error')));
  }
};

const fetchCrops = () => async(dispatch) => {
  try {
    const cropsResponse = await configService.getCrops();
    if (isOk(cropsResponse)) {
      dispatch(setCrops(getResponsePayload(cropsResponse)));
    }
  } catch (e) {
    logger.error(e);
    dispatch(addErrorMessage(translate('config.crops.get.error')));
  }
};

const fetchCountries = () => async(dispatch) => {
  try {
    const countriesResponse = await configService.getCountries();
    if (isOk(countriesResponse)) {
      dispatch(setCountries(getResponsePayload(countriesResponse)));
    }
  } catch (e) {
    logger.error(e);
    dispatch(addErrorMessage(translate('config.countries.get.error')));
  }
};

const fetchStates = () => async(dispatch) => {
  try {
    const statesResponse = await configService.getStates();
    if (isOk(statesResponse)) {
      dispatch(setStates(getResponsePayload(statesResponse)));
    }
  } catch (e) {
    logger.error(e);
    dispatch(addErrorMessage(translate('config.states.get.error')));
  }
};
