import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';

import mock from 'utils/mock';

mock.onGet('/api/account/profile').reply(200, {
  profile: {
    avatar: '/images/avatars/avatar_11.png',
    canHire: false,
    country: 'USA',
    email: 'jovana.miljkovic@acrefx.com',
    firstName: 'Jovana',
    isPublic: true,
    lastName: 'Miljković',
    phone: '+40 777666555',
    state: 'Alabama',
    timezone: '4:32PM (GMT-4)'
  }
});

mock.onGet('/api/account/statistics').reply(200, {
  statistics: {
    payout: '4,250',
    projects: '12,500',
    visitors: '230',
    watching: '5'
  }
});

mock.onGet('/api/account/projects').reply(200, {
  projects: [
    {
      id: uuid(),
      title: 'Crop analysis',
      price: '3K - 6K',
      currency: '$',
      type: 'Full-Time',
      location: 'Europe',
      author: {
        name: 'Djordje Djordjevic',
        avatar: '/images/avatars/avatar_5.png'
      },
      tags: [
        {
          id: uuid(),
          text: 'Vue JS',
          color: colors.green[600]
        }
      ],
      updated_at: moment().subtract(24, 'minutes')
    }
  ]
});

mock.onGet('/api/account/todos').reply(200, {
  todos: [
    {
      id: uuid(),
      title: 'Visit field FIELD 1 and collect samples',
      deadline: moment().subtract(2, 'days'),
      done: false
    },
    {
      id: uuid(),
      title: 'Visit field FIELD 1 and collect samples',
      deadline: moment(),
      done: false
    },
    {
      id: uuid(),
      title:
        'Upload the files for weeds recorded at the field',
      deadline: moment().add(2, 'days'),
      done: false
    },
    {
      id: uuid(),
      title: 'Re-write the captured notes from last visit',
      deadline: moment().add(5, 'days'),
      done: false
    },
    {
      id: uuid(),
      title: 'Send soil samples for analysis',
      deadline: moment().add(5, 'days'),
      done: false
    },
    {
      id: uuid(),
      title:
        'Create new organization and invite users',
      deadline: moment().add(7, 'days'),
      done: true
    }
  ]
});

mock.onGet('/api/account/notifications').reply(200, {
  notifications: [
    {
      id: uuid(),
      title: 'New analysis result received',
      type: 'order',
      created_at: moment().subtract(2, 'hours')
    },
    {
      id: uuid(),
      title: 'New user added to the organization',
      type: 'user',
      created_at: moment().subtract(1, 'day')
    },
    {
      id: uuid(),
      title: 'Field has been planted',
      type: 'project',
      created_at: moment().subtract(3, 'days')
    },
    {
      id: uuid(),
      title: 'New products available',
      type: 'feature',
      created_at: moment().subtract(7, 'days')
    }
  ]
});

mock.onGet('/api/account/subscription').reply(200, {
  subscription: {
    name: 'Free',
    price: '5',
    currency: '$',
    proposalsLeft: 12,
    templatesLeft: 5,
    invitesLeft: 24,
    adsLeft: 10,
    hasAnalytics: true,
    hasEmailAlerts: true
  }
});

mock.onGet('/api/account/settings').reply(200, {
  settings: {}
});
