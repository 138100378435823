import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
  Polygon,
  Circle,
  InfoWindow,
} from '@react-google-maps/api';
import * as turf from "@turf/turf";
import { ActiveOrgContext } from 'App';
import convertShapeToGeoJSON from 'utils/shape';
import getColorAreas from 'utils/Legend';
import { createMap } from 'endpoints/fieldMaps';
import DrawingModal from './DrawingModal';
import Legend from './Legend';
import { Page } from 'components';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'material-react-toastify';
import getAreaWithoutObstacles from 'utils/AreaCalc';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
}));

const libraries = ['drawing'];

const PlotsMap = ({ selectedPlot, refetch, addObstacleMode, plotData, isNavigatorMap, setAddObstacleMode, isPlotSelected, allPlotsData, checkedParcelIds }) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [drawnShape, setDrawnShape] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [circles, setCircles] = useState([]);
  const [obstacle, setObstacle] = useState([]);
  const activeOrg = useContext(ActiveOrgContext);
  const [infoWindows, setInfoWindows] = useState([]);
  const mapRef = useRef();
  const drawingManagerRef = useRef(null);
  const classes = useStyles();


  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    const newInfoWindows = allPlotsData?.filter((parcel) => checkedParcelIds.includes(parcel.id)).map((parcel) => {
      const coordinates = parcel.geoData.features[0].geometry.coordinates;
      const type = parcel.geoData.features[0].geometry.type;
      const centroid = type !== 'Point' && turf.centroid({
        type: 'Feature',
        geometry: {
          type: type,
          coordinates: coordinates,
        },
      });
      const name = parcel.name;

      return (
        !isPlotSelected && centroid && <InfoWindow
          key={parcel.id}
          position={{ lat: centroid.geometry.coordinates[1], lng: centroid.geometry.coordinates[0] }}
        >
          <div>{name}</div>
        </InfoWindow>
      );
    });

    setInfoWindows(newInfoWindows);
  }, [allPlotsData, isPlotSelected, selectedPlot, checkedParcelIds]);

  const legendData = getColorAreas(selectedPlot);

  const onPolygonComplete = polygon => {
    if (!addObstacleMode) {
      if (drawingManagerRef.current) {
        drawingManagerRef.current.setOptions({
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_LEFT,
            drawingModes: [
              window.google.maps.drawing.OverlayType.MARKER,
              window.google.maps.drawing.OverlayType.POLYGON,
              // window.google.maps.drawing.OverlayType.POLYLINE,
              // window.google.maps.drawing.OverlayType.RECTANGLE,
            ],
          },
        });
      }
    }
  };

  const onObstaclePoplygonComplete = polygon => {
    if (addObstacleMode) {
      const paths = polygon
        .getPaths()
        .getArray()
        .map(path => {
          return path.getArray().map(coord => [coord.lng(), coord.lat()]);
        });
      const obstacleGeoJSON = {
        type: 'Feature',
        properties: {
          FillColor: 'transparent',
          FillOpacity: 0,
          BorderColor: '#FF0000',
          BorderWidth: 1,
          name: '',
          class: 'obstacle',
          },
          geometry: {
            type: 'Polygon',
            coordinates: paths,
          },
      };
      if (Array.isArray(selectedPlot)) {
        setObstacle(() => [ obstacleGeoJSON]);
      } else if (typeof selectedPlot === 'object' && selectedPlot !== null) {
        setObstacle(() => [obstacleGeoJSON]);
      } else {
        setObstacle(() => [obstacleGeoJSON]);
      }
    }
  };

  const onOverlayComplete = event => {
    if (!addObstacleMode) {
      setDrawnShape(event.overlay);
      setModalOpen(true);
    }
  };


  const getPolygonCoords = coordinates => {
    if (Array.isArray(coordinates) && Array.isArray(coordinates[0])) {
      if (
        typeof coordinates[0][0] === 'number' &&
        typeof coordinates[0][1] === 'number'
      ) {
        const polygonCoords = coordinates.map(coord => ({
          lat: coord[1],
          lng: coord[0],
        }));
        return polygonCoords;
      } else if (
        Array.isArray(coordinates[0][0]) &&
        typeof coordinates[0][0][0] === 'number' &&
        typeof coordinates[0][0][1] === 'number'
      ) {
        const polygonCoords = coordinates[0].map(coord => ({
          lat: coord[1],
          lng: coord[0],
        }));
        return polygonCoords;
      }
    }

    console.error('Invalid Polygon coordinates structure');
    console.error('coordinates: ', coordinates);
    return [];
  };

  const handleGeometry = useCallback(geometry => {
    if (!geometry?.type) {
      console.log('Invalid geometry data', geometry);
      return [];
    }

    switch (geometry.type) {
      case 'Polygon':
        return getPolygonCoords(geometry.coordinates);
      case 'Point':
        return [getLatLng(geometry.coordinates[0][0])];
      case 'LineString':
        return geometry.coordinates[0].map(coord => getLatLng(coord));
      case 'MultiPolygon':
        return geometry.coordinates.flatMap(polygon =>
          getPolygonCoords(polygon),
        );
      case 'Feature':
        return getPolygonCoords(geometry.coordinates);
      default:
        console.log('Unsupported geometry type: ' + geometry.type);
        return [];
    }
  }, []);

  useEffect(() => {
    if (selectedPlot) {
      const polygons = [];
      const markers = [];
      const circles = [];
      const bounds = new window.google.maps.LatLngBounds();

      if (Array.isArray(selectedPlot)) {
        selectedPlot[0].forEach(feature => {
          const coords = handleGeometry(feature.geometry);
          if (feature.geometry.type === 'Point') {
            const position = handleGeometry(feature.geometry);
            if (feature.properties.class === 'circle') {
              const circle = new window.google.maps.Circle({
                center: coords[0],
                radius: feature.properties.radius,
                map: map,
              });
              circles.push(circle);
              bounds.extend(coords[0]);
            }
            if (position) {
              const marker = new window.google.maps.Marker({ position: position });
              markers.push(marker);
              bounds.extend(position);
            }
          } else {
            const coords = handleGeometry(feature.geometry);
            if (coords.length > 0) {
              const polygon = new window.google.maps.Polygon({ paths: coords });
              polygons.push(polygon);
              coords.forEach(coord => bounds.extend(coord));
            }
          }
        });
      } else {
        if (selectedPlot.geometry?.type === 'Point') {
          const position = handleGeometry(selectedPlot.geometry);
          if (position) {
            const marker = new window.google.maps.Marker({ position: position });
            markers.push(marker);
            bounds.extend(position);
          }
        } else {
          const coords = handleGeometry(selectedPlot.geometry);
          if (coords.length > 0) {
            const polygon = new window.google.maps.Polygon({ paths: coords });
            polygons.push(polygon);
            coords.forEach(coord => bounds.extend(coord));
          }
        }
      }

      if (polygons.length > 0) {
        polygons.forEach(polygon => polygon.setMap(map));
        fitMapToBounds(bounds);
      }

      if (markers.length > 0) {
        markers.forEach(marker => marker.setMap(map));
        fitMapToBounds(bounds);
      }
      if (circles.length > 0) {
        circles.forEach(circle => circle.setMap(map));
        fitMapToBounds(bounds);
      }

      if (polygons.length === 0 && markers.length === 0) {
        console.log('No valid shapes or points found');
      }
    }
  }, []);

  useEffect(() => {
    polygons.forEach(polygon => polygon.setMap(null));
    polylines.forEach(polyline => polyline.setMap(null));
    markers.forEach(marker => marker.setMap(null));
    circles.forEach(circle => circle.setMap(null));

    setPolygons([]);
    setPolylines([]);
    setMarkers([]);
    setCircles([]);

    if (selectedPlot && isLoaded && map) {
      const newPolygons = [];
      const newPolylines = [];
      const newMarkers = [];
      const newCircles = [];
      const bounds = new window.google.maps.LatLngBounds();

      if (!Array.isArray(selectedPlot.features)) {
        selectedPlot.forEach(feature => {
          const coords = handleGeometry(feature.geometry);

          if (coords.length > 0) {
            let fillColor = 'black';
            let borderColor = '#000000';
            let fillOpacity = 0.3;
            let borderOpacity = 0.5;

            if (feature.properties) {
              if (feature.properties.FillColor || feature.properties.Color) {
                fillColor = feature.properties.FillColor || feature.properties.Color;
              }
              if (feature.properties.BorderColor) {
                borderColor = feature.properties.BorderColor;
              }
              if (feature.properties.FillColorOpacity) {
                fillOpacity = parseFloat(feature.properties.FillColorOpacity);
              }
              if (feature.properties.BorderColorOpacity) {
                borderOpacity = parseFloat(feature.properties.BorderColorOpacity);
              }
            }

            if (feature.geometry.type === 'Point') {
              if (feature.properties.class === 'circle') {
                const circle = new window.google.maps.Circle({
                  center: coords[0],
                  radius: feature.properties.radius,
                  map: map,
                  fillColor: fillColor,
                  fillOpacity: fillOpacity,
                  strokeColor: borderColor,
                  strokeOpacity: borderOpacity,
                });
                newCircles.push(circle);
                bounds.extend(coords[0]);
              } else {
                const marker = new window.google.maps.Marker({
                  position: coords[0],
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 5,
                    fillColor: fillColor,
                    fillOpacity: fillOpacity,
                    strokeColor: borderColor,
                    strokeOpacity: borderOpacity,
                  },
                });
                newMarkers.push(marker);
                bounds.extend(coords[0]);
              }
            } else {
              const polygon = new window.google.maps.Polygon({
                paths: coords,
                fillColor: fillColor,
                fillOpacity: fillOpacity,
                strokeColor: borderColor,
                strokeOpacity: borderOpacity,
              });
              newPolygons.push(polygon);
              coords.forEach(coord => bounds.extend(coord));
            }
          }
        });
      } else {
        selectedPlot.features.forEach(feature => {
          const coords = handleGeometry(feature.geometry);

          if (coords.length > 0) {
            let fillColor = '#000000';
            let borderColor = '#000000';
            let fillOpacity = 0.5;
            let borderOpacity = 1;

            if (feature.properties) {
              if (feature.properties.FillColor || feature.properties.Color) {
                fillColor = feature.properties.FillColor || feature.properties.Color;
              }
              if (feature.properties.BorderColor) {
                borderColor = feature.properties.BorderColor;
              }
              if (feature.properties.FillColorOpacity) {
                fillOpacity = parseFloat(feature.properties.FillColorOpacity);
              }
              if (feature.properties.BorderColorOpacity) {
                borderOpacity = parseFloat(feature.properties.BorderColorOpacity);
              }
            }

          if (feature.geometry.type === 'Point') {
              if (feature.properties.class === 'circle') {
                const circle = new window.google.maps.Circle({
                  center: coords[0],
                  radius: feature.properties.radius,
                  map: map,
                  fillColor: fillColor,
                  fillOpacity: fillOpacity,
                  strokeColor: borderColor,
                  strokeOpacity: borderOpacity,
                });
                newCircles.push(circle);
                bounds.extend(coords[0]);
              } else {
                const marker = new window.google.maps.Marker({
                  position: coords[0],
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 5,
                    fillColor: fillColor,
                    fillOpacity: fillOpacity,
                    strokeColor: borderColor,
                    strokeOpacity: borderOpacity,
                  },
                });
                newMarkers.push(marker);
                bounds.extend(coords[0]);
              }
            } else {
              const polygon = new window.google.maps.Polygon({
                paths: coords,
                fillColor: fillColor,
                fillOpacity: fillOpacity,
                strokeColor: borderColor,
                strokeOpacity: borderOpacity,
              });
              newPolygons.push(polygon);
              coords.forEach(coord => bounds.extend(coord));
            }
          }
        });
      }

      if (newPolygons.length > 0) {
        newPolygons.forEach(polygon => polygon.setMap(map));
        setPolygons(newPolygons.concat(polygons));
      }

      if (newPolylines.length > 0) {
        newPolylines.forEach(polyline => polyline.setMap(map));
        setPolylines(newPolylines.concat(polylines));
      }

      if (newMarkers.length > 0) {
        newMarkers.forEach(marker => marker.setMap(map));
        setMarkers(newMarkers.concat(markers));
      }

      if (newCircles.length > 0) {
        newCircles.forEach(circle => circle.setMap(map));
        setCircles(newCircles.concat(circles));
      }

      // This code checks if any plots are selected. If no plots are selected,
      // it attempts to get the user's current geolocation. If the geolocation
      // permission is granted, it uses that position to extend the map boundaries.
      // If the permission is denied, it adjusts the map view based on a predefined center.
      // If there are selected plots, it immediately adjusts the map view to the boundaries.



        fitMapToBounds(bounds);

    }
  }, [selectedPlot, handleGeometry, map, isLoaded]);


  const getLatLng = coord => {
    return { lat: coord[1], lng: coord[0] };
  };

  const getCenter = callback => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { address: 'Belgrade, Serbia' }, // hardkodirano na BG
      (results, status) => {
        if (status === 'OK') {
          const { lat, lng } = results[0].geometry.location;
          callback({ lat: lat(), lng: lng() });
        } else {
          toast.error(
            'Geocode was not successful for the following reason: ' + status,
          );
        }
      },
    );
  };

  const fitMapToBounds = bounds => {
    if (mapRef.current) {
      const googleMapInstance = mapRef.current.state.map;
      googleMapInstance.fitBounds(bounds);
    }
  };

  useEffect(() => {
    // call getCenter for belgrade coordinates only if selectedPlot doesn't exist
    if (map && (selectedPlot && !selectedPlot?.features)) {
      // getCenter(setCenter);
    }
  }, [map, selectedPlot?.features]);

  useEffect(() => {
    if (drawingManagerRef.current) {
      // Remove the old listener
      window.google.maps.event.clearListeners(
        drawingManagerRef.current,
        'polygoncomplete',
      );

      // Attach the new listener
      window.google.maps.event.addListener(
        drawingManagerRef.current,
        'polygoncomplete',
        addObstacleMode ? onObstaclePoplygonComplete : onPolygonComplete,
      );
    }
  }, [addObstacleMode]);

  useEffect(() => {
    if (addObstacleMode) {
      // Activate Polygon drawing mode in DrawingManager
      if (drawingManagerRef.current) {
        drawingManagerRef.current.setOptions({
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_LEFT,
            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
          },
        });
      }
    } else {
      // Deactivate Polygon drawing mode in DrawingManager
      if (selectedPlot && isPlotSelected) {
        if (drawingManagerRef?.current) {
          drawingManagerRef.current.setOptions({
              drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_LEFT,
                  drawingModes: [],
              },
          });
      }
      } else {
        if (drawingManagerRef?.current) {
        drawingManagerRef.current.setOptions({
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_LEFT,
            drawingModes: [
              window.google.maps.drawing.OverlayType.POLYGON,
            ],
          },
        });
      }
      }
    }
  }, [addObstacleMode, selectedPlot]);

  const options = {
    mapTypeControl: true, // Disable map/satellite toggle button
    streetViewControl: false, // Disable street view button
    mapTypeId: 'hybrid',
  };

  if (isLoaded && !loadError) {
    options.mapTypeControlOptions = {
      position: window.google.maps.ControlPosition.TOP_RIGHT,
    };
  }
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const initDrawingManager = mapInstance => {
    // if map is loaded from navigator analytics page, drawing manager is gonna be disabled.
    if (isNavigatorMap) {
      return;
    }

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      polygonOptions: {
        fillColor: 'black',
        strokeColor: '#0000FF',
      },
    });
    drawingManager.setMap(mapInstance);
    drawingManagerRef.current = drawingManager;

    if (drawingManagerRef.current) {
      window.google.maps.event.clearListeners(
        drawingManagerRef.current,
        'polygoncomplete',
      );
    }

    // posebni listeneri za svaki event, dodaj ostale ako zatrebaju
    // ne mogu se inicijalizovati zajedno, za svaki shape moraju posebno
    // ne moze drugacije da se izbegne da se okine listener za crtanje a crta se prepreka
    window.google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      addObstacleMode ? onObstaclePoplygonComplete : onPolygonComplete,
    );
    window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      onOverlayComplete,
    );
  };

  useEffect(() => {
    if (map) {
      initDrawingManager(map);
      // Morace ovako da se inicijalizuje, da bi se fully kontrolisao
    }
  }, [map]);

  const onLoad = mapInstance => {
    setMap(mapInstance);
  };

  // save shape-a iz drawing managera
  const handleSave = async name => {
    const geoData = convertShapeToGeoJSON(drawnShape);
    const coordinates = [...geoData.features[0].geometry.coordinates];

    const geoJson = {
      type: 'FeatureCollection',
      properties: {
        class: 'parcel',
      },
      features: [
        {
          type: 'Feature',
          properties: {
            FillColor: 'black',
            FillOpacity: 0.3,
            BorderColor: 'black',
            BorderWidth: 1,
          },
          geometry: {
            type: 'Polygon',
            coordinates: coordinates,
          },
        },
      ],
    };
    const area = getAreaWithoutObstacles(geoJson);
    const size = parseFloat(area);

    try {
      let params = { name, size, organizationId: activeOrg[0].id, geoData };
      await createMap(params);
      toast.success('Shape added successfully!');
      refetch();
      if (drawnShape) {
        drawnShape.setMap(null);
        setDrawnShape(null);
      }
    } catch (error) {
      if (drawnShape) {
        drawnShape.setMap(null);
        setDrawnShape(null);
      }
      toast.error(error.response.data.message);
    }
    setAddObstacleMode(false);
    setModalOpen(false);
    setAddObstacleMode(false);
    setObstacle(null);
  };

  const clearObstacleShape = () => {
    if (drawnShape) {
      drawnShape.setMap(null);
      setDrawnShape(null);
    }
  };

  const handleCancel = () => {
    if (drawnShape) {
      drawnShape.setMap(null);
      setDrawnShape(null);
    }
    setAddObstacleMode(false);
    setObstacle(null);
    setModalOpen(false);
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';


  return (
      <Page className={classes.root} title="Map component">
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            options={options}
            onLoad={onLoad}
            ref={mapRef}
          >
            {selectedPlot && (
              <>
                {selectedPlot.type === 'Polygon' && (
                  <Polygon
                    paths={getPolygonCoords(selectedPlot.coordinates)}
                    options={{
                      fillColor: selectedPlot?.properties?.FillColor,
                      fillOpacity: selectedPlot?.properties?.FillColorOp || 0.3,
                      strokeColor: selectedPlot?.properties?.BorderColor,
                      strokeWeight: selectedPlot?.properties?.BorderWidth || 1,
                    }}
                  />
                )}
                {selectedPlot.type === 'Feature' && (selectedPlot.properties?.class === 'circle' && (
                  <Circle
                    position={getLatLng(selectedPlot?.geometry?.coordinates)}
                    radius={selectedPlot?.properties.class}
                  />
                ))}
                {selectedPlot.type === 'Feature' && (
                  <Polygon
                    paths={getPolygonCoords(selectedPlot.coordinates)}
                    options={{
                      fillColor: selectedPlot?.properties?.FillColor,
                      fillOpacity: selectedPlot?.properties?.FillColor,
                      strokeColor: selectedPlot?.properties?.BorderColor,
                      strokeWeight: selectedPlot?.properties?.BorderWidth || 1,
                    }}
                  />
                )}
                {selectedPlot.type === 'Point' && (
                  <Marker
                    position={getLatLng(selectedPlot[0]?.geometry.coordinates)}
                    visible
                    options={{
                      zIndex: 999,
                    }}
                  />
                )}
                {selectedPlot.type === 'LineString' && (
                  <Polyline
                    path={selectedPlot.coordinates.map(getLatLng)}
                    options={{
                      fillColor: selectedPlot.properties.FillColor,
                      fillOpacity: selectedPlot.properties.FillColorOp || 0.3,
                      strokeColor: selectedPlot.properties.BorderColor,
                      strokeWeight: selectedPlot.properties.BorderWidth || 1,
                    }}
                  />
                )}
                {selectedPlot.type === 'MultiPoint' &&
                  selectedPlot.coordinates.map((coord, index) => (
                    <Marker
                      key={index}
                      position={getLatLng(coord)}
                    />
                  ))}
              </>
            )}
            {infoWindows}
            {legendData && isPlotSelected && <Legend legendData={legendData} />}
          </GoogleMap>
        )}
        <DrawingModal
          open={modalOpen}
          onSave={handleSave}
          onCancel={handleCancel}
          addObstacleMode={addObstacleMode}
          selectedPlot={selectedPlot}
          refetch={refetch}
          plotData={plotData}
          obstacle={obstacle}
          setModalOpen={setModalOpen}
          setAddObstacleMode={setAddObstacleMode}
          setMap={setMap}
          clearObstacleShape={clearObstacleShape}
        />
      </Page>
  );
};

export default PlotsMap;
