import axiosInstance from ".";

export const getUserSamplings = async (organizationId, fieldId) => {
  let url = `/lab/zone-samplings/${organizationId}?page=1&limit=30`;

  if (fieldId !== undefined) {
    url += `&fieldId=${fieldId}`;
  }

  return axiosInstance.get(url).then((res) => res.data);
};

export const updateSampling = async(
  name,
  size,
  organizationId,
  geoData,
  fieldId,
  zoneSamplingId
) => {
  const data = { name, size, organizationId, geoData, fieldId, zoneSamplingId };
  return axiosInstance.post("/lab/create", data).then(res => res.data);
};

export const uploadExcelFIle = async formData => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  return axiosInstance
    .post("/lab/upload", formData, config)
    .then(res => res.data);
};

export const getLabForZoneSampling = async zoneSamplingId => {
  return axiosInstance
    .get(`/lab/zone-sample/${zoneSamplingId}`)
    .then(res => res.data);
};
