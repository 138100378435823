export { default } from './ConfirmDialog';
























