/**
 * Get initials for given name
 *
 * @param name
 * @returns {string}
 */
export function getInitials(name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}

