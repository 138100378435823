import i18n from 'i18n';

/**
 * Translate string to selected user lang.
 * Example of translation with params:
 *
 **  key    = "{{what}} is {{how}}"
 **  params = { what: 'i18next', how: 'great' }
 **  t(key, params) = "i18next is great"
 *
 * @param str
 * @param params
 * @return {*}
 */
export function translate(str, params = {}) {
  return i18n.t(str, params);
}
