import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <polygon
            className="st0"
            points="43,20.9 47.1,20.9 37.2,3.8 12.5,3.8 17.7,11.7 10.1,25 12.8,29.6 16.8,29.6 14.1,25.1 21.8,11.5
				19.1,7.3 35.2,7.3 			"
          />
          <polygon
            className="st0"
            points="12.5,11.3 10.4,7.8 0.5,24.8 12.9,46.2 17.1,37.8 32.5,37.7 35.1,33.1 33.1,29.6 30.6,34.2
				14.9,34.3 12.7,38.8 4.6,24.8 			"
          />
          <polygon
            className="st0"
            points="19.4,42.7 17.4,46.2 37.1,46.2 49.5,24.8 40.1,25.4 32.3,12.1 27,12.2 25,15.6 30.2,15.5 38.1,29
				43.2,28.7 35.1,42.7 			"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
