import authService from 'services/auth.service';
import {
  SESSION_LOGIN,
  SESSION_LOGIN_STATUS,
  SESSION_LOGOUT,
  SESSION_SET_ACTIVE_ORGANIZATION,
  SESSION_SET_ACTIVE_SEASON,
  SESSION_SET_SEASONS,
} from 'actions';

const jwt = authService.getJwt();

const initialState = {
  isLoggedIn: !!jwt,
  user: {
    role: 'GUEST',
    organizations: [],
    activeOrganization: {},
  },
  seasons: [],
  activeSeason: {},
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_LOGIN: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.userData,
        },
      };
    }

    case SESSION_LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        user: {
          role: 'GUEST',
          organizations: [],
          activeOrganization: {},
        },
        seasons: [],
        activeSeason: {},
      };
    }

    case SESSION_LOGIN_STATUS: {
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    }

    case SESSION_SET_ACTIVE_ORGANIZATION: {
      return {
        ...state,
        user: {
          ...state.user,
          activeOrganization: {
            ...action.organization,
          },
        },
      };
    }

    case SESSION_SET_SEASONS: {
      return {
        ...state,
        seasons: action.seasons,
      };
    }

    case SESSION_SET_ACTIVE_SEASON: {
      return {
        ...state,
        activeSeason: {
          ...action.season,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
