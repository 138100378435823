/* eslint-disable */

// import React from 'react';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import HomeIcon from '@material-ui/icons/HomeOutlined';
// import PersonIcon from '@material-ui/icons/PersonOutlined';
// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
// import CalendarTodayOutlinedIcon
//   from '@material-ui/icons/CalendarTodayOutlined';
// import {
//   ClearAll,
//   Colorize,
//   Domain,
//   FilterHdr,
//   LocalFlorist,
// } from '@material-ui/icons';
// import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
// custom icons
import InfrastructureIcon from '../../../../components/Navigation/icons/InfrastructureIcon';
import { Colorize } from '@material-ui/icons';
import FieldBorders from '../../../../components/Navigation/icons/FieldBorders';
import FieldIrrigation from '../../../../components/Navigation/icons/FieldIrrigation';
import SoilAndLabManagement from '../../../../components/Navigation/icons/SoilAndLabManagement';
import SoilMaps from '../../../../components/Navigation/icons/SoilMaps';
import LabData from '../../../../components/Navigation/icons/LabData';
import FieldCropsPlan from '../../../../components/Navigation/icons/FieldCropsPlan';
import CropRotation from '../../../../components/Navigation/icons/CropRotation';
import ProductionAndTillage from '../../../../components/Navigation/icons/ProductionAndTillage';
import SeasonManagement from '../../../../components/Navigation/icons/SeasonManagement';
import AgtrinityAnalytics from '../../../../components/Navigation/icons/AgtrinityAnalytics';
import RxMaps from '../../../../components/Navigation/icons/RxMaps';
import Profile from '../../../../components/Navigation/icons/Profile';
import Organization from '../../../../components/Navigation/icons/Organization';
import Settings from '../../../../components/Navigation/icons/Settings';

export default [
  {
    title: 'Pages',
    pages: [
      // {
      //   title: 'dashboard',
      //   href: '/dashboards/default',
      //   icon: Dashboard,
      // },
      {
        title: 'infrastructure',
        href: '/dashboards',
        icon: InfrastructureIcon,
        children: [
          {
            title: 'fieldBorders',
            href: '/dashboards/field-borders',
            icon: FieldBorders,
          },
          // {
          //   title: "Grain storage",
          //   href: "/dashboards/analytics",
          //   icon: GrainStorage
          // },
          {
            title: 'fieldIrrigation',
            href: '/dashboards/analytics',
            icon: FieldIrrigation,
            disabled: true,
          },
          // {
          //   title: "Field roads",
          //   href: "/dashboards/analytics",
          //   icon: FieldRoads
          // }
        ],
      },
      {
        title: 'soilAndLab',
        href: '/management',
        icon: SoilAndLabManagement,
        children: [
          {
            title: 'soilMaps',
            href: '/management/soil-maps',
            icon: SoilMaps,
          },
          {
            title: 'labData',
            href: '/management/lab-data',
            icon: LabData,
          },
        ],
      },
      {
        title: 'seasonManagement',
        href: '/dashboards',
        disabled: true,
        icon: SeasonManagement,
        children: [
          // {
          //   title: 'prodAndTillage',
          //   href: '/dashboards/analytics',
          //   icon: ProductionAndTillage,
          // },
          // {
          //   title: 'fieldCrops',
          //   href: '/dashboards/analytics',
          //   icon: FieldCropsPlan,
          // },
          // {
          //   title: 'cropRotation',
          //   href: '/dashboards/analytics',
          //   icon: CropRotation,
          // },
          // {
          //   title: 'Vegetable crops plan',
          //   href: '/dashboards/analytics',
          //   icon: VegetableCropsPlan,
          // },
          // {
          //   title: 'Seed production plan',
          //   href: '/dashboards/analytics',
          //   icon: SeedProductionPlan,
          // },
          // {
          //   title: 'Field trails',
          //   href: '/dashboards/analytics',
          //   icon: FieldTrials,
          // },
        ],
      },
      // {
      //   title: 'Scouting and reporting',
      //   href: '/management',
      //   icon: ScoutingAndReporting,
      //   children: [
      //     {
      //       title: 'Organizing reports',
      //       href: '/dashboards/analytics',
      //       icon: OrganizingReports,
      //     },
      //     {
      //       title: 'Scouting activities',
      //       href: '/dashboards/analytics',
      //       icon: ScoutingActivities,
      //     },
      //     {
      //       title: 'Scouters',
      //       href: '/dashboards/analytics',
      //       icon: Scouters,
      //     },
      //     // {
      //     //   title: 'Lab data',
      //     //   href: '/management/lab-data',
      //     //   icon: Colorize,
      //     //   children: [
      //     //     {
      //     //       title: 'Chemical analysis',
      //     //       href: '/management/lab-data/chemical-analysis',
      //     //       children: [
      //     //         {
      //     //           title: 'Nitrogen management',
      //     //           href: '/management/lab-data/chemical-analysis/nitrogen-management',
      //     //         },
      //     //         {
      //     //           title: 'Carbon management',
      //     //           href: '/management/lab-data/chemical-analysis/carbon-management',
      //     //         },
      //     //         {
      //     //           title: 'PK management',
      //     //           href: '/management/lab-data/chemical-analysis/pk-management',
      //     //         },
      //     //         {
      //     //           title: 'Ca, S, Mg management',
      //     //           href: '/management/lab-data/chemical-analysis/ca-s-mg-management',
      //     //         },
      //     //         {
      //     //           title: 'Micronutrient management',
      //     //           href: '/management/lab-data/chemical-analysis/micronutrient-management',
      //     //         },
      //     //         {
      //     //           title: 'Ratio management',
      //     //           href: '/management/lab-data/chemical-analysis/ratio-management',
      //     //         },
      //     //       ],
      //     //     },
      //     //     {
      //     //       title: 'Mechanical analysis',
      //     //       href: '/management/lab-data/mechanical-analysis',
      //     //       children: [
      //     //         {
      //     //           title: 'Soil classification',
      //     //           href: '/management/lab-data/mechanical-analysis/soil-classification',
      //     //         },
      //     //         {
      //     //           title: 'Field water capacity',
      //     //           href: '/management/lab-data/mechanical-analysis/field-water-capacity',
      //     //         },
      //     //         {
      //     //           title: 'Water infiltration',
      //     //           href: '/management/lab-data/mechanical-analysis/water-infiltration',
      //     //         },
      //     //         {
      //     //           title: 'Bulk density',
      //     //           href: '/management/lab-data/mechanical-analysis/bulk-density',
      //     //         },
      //     //         {
      //     //           title: 'Porosity',
      //     //           href: '/management/lab-data/mechanical-analysis/porosity',
      //     //         },
      //     //       ],
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      {
        title: 'aggAnalytics',
        href: '/dashboards',
        icon: AgtrinityAnalytics,
        children: [
          // {
          //   title: 'Soil degradation assessment',
          //   href: '/dashboards/analytics',
          //   icon: SoilDegradationAssessment,
          // },
          // {
          //   title: 'Carbon balance assessment',
          //   href: '/dashboards/analytics',
          //   icon: CarbonBalanceAssessment,
          // },
          // {
          //   title: 'Nutrient potential assessment',
          //   href: '/dashboards/analytics',
          //   icon: NutrientPotentialAssessment,
          // },
          // {
          //   title: 'Climate assessment',
          //   href: '/dashboards/analytics',
          //   icon: ClimateAssessment,
          // },
          {
            title: 'rxMaps',
            href: '/dashboards/rx-maps',
            icon: RxMaps,
          },
          {
            title: 'zoneSampling',
            href: '/dashboards/zone-sampling',
            icon: CropRotation,
          },
          // {
          //   title: 'In-farm management',
          //   href: '/dashboards/analytics',
          //   icon: InFarmManagement,
          // },
          // {
          //   title: 'Logistic/storage assessment',
          //   href: '/dashboards/analytics',
          //   icon: LogisticStorageAssessment,
          // },
          // {
          //   title: 'Custom assessment',
          //   href: '/dashboards/analytics',
          //   icon: CustomAssessment,
          // },
        ],
      },
      // {
      //   title: 'manageFiles',
      //   href: '/dashboards',
      //   icon: ManageAllFiles,
      //   children: [
      //     {
      //       title: 'allFiles',
      //       href: '/dashboards/analytics',
      //       icon: SoilAndLabFiles,
      //     },
      //     // {
      //     //   title: 'Soil and lab files',
      //     //   href: '/dashboards/analytics',
      //     //   icon: SoilAndLabFiles,
      //     // },
      //     // {
      //     //   title: 'Season management files',
      //     //   href: '/management/season',
      //     //   icon: SeasonManagement,
      //     // },
      //     // {
      //     //   title: 'Scouting and reporting files',
      //     //   href: '/dashboards/analytics',
      //     //   icon: ScoutingAndReporting,
      //     // },
      //     // {
      //     //   title: 'Agtrinity analytics analystic',
      //     //   href: '/dashboards/analytics',
      //     //   icon: AgtrinityAnalytics,
      //     // },
      //   ],
      // },
      // {
      //   title: 'notifications',
      //   href: '/dashboards/default',
      //   icon: NotificationsNone
      // },
      {
        title: 'settings',
        href: '/settings',
        icon: Settings,
        children: [
          {
            title: 'profile',
            href: '/settings/profile',
            icon: Profile,
            pic: 'userPic'
          },
          {
            title: 'organizations',
            href: '/settings/organizations',
            icon: Organization,
          },
        ],
      },
    ],
  },
  // {
  //   title: 'Pages',
  //   pages: [
  //     {
  //       title: 'Overview',
  //       href: '/overview',
  //       icon: HomeIcon,
  //     },
  //     {
  //       title: 'Dashboards',
  //       href: '/dashboards',
  //       icon: DashboardIcon,
  //       children: [
  //         {
  //           title: 'Default',
  //           href: '/dashboards/default',
  //         },
  //         {
  //           title: 'Analytics',
  //           href: '/dashboards/analytics',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Management',
  //       href: '/management',
  //       icon: BarChartIcon,
  //       children: [
  //         {
  //           title: 'Soil maps',
  //           href: '/management/soil-maps',
  //           icon: FilterHdr,
  //         },
  //         {
  //           title: 'Plant data',
  //           href: '/management/plant-data',
  //           icon: LocalFlorist,
  //         },
  //         {
  //           title: 'Yield data',
  //           href: '/management/yield-data',
  //           icon: ClearAll,
  //         },
  //         {
  //           title: 'Lab data',
  //           href: '/management/lab-data',
  //           icon: Colorize,
  //           children: [
  //             {
  //               title: 'Chemical analysis',
  //               href: '/management/lab-data/chemical-analysis',
  //               children: [
  //                 {
  //                   title: 'Nitrogen management',
  //                   href: '/management/lab-data/chemical-analysis/nitrogen-management',
  //                 },
  //                 {
  //                   title: 'Carbon management',
  //                   href: '/management/lab-data/chemical-analysis/carbon-management',
  //                 },
  //                 {
  //                   title: 'PK management',
  //                   href: '/management/lab-data/chemical-analysis/pk-management',
  //                 },
  //                 {
  //                   title: 'Ca, S, Mg management',
  //                   href: '/management/lab-data/chemical-analysis/ca-s-mg-management',
  //                 },
  //                 {
  //                   title: 'Micronutrient management',
  //                   href: '/management/lab-data/chemical-analysis/micronutrient-management',
  //                 },
  //                 {
  //                   title: 'Ratio management',
  //                   href: '/management/lab-data/chemical-analysis/ratio-management',
  //                 },
  //               ],
  //             },
  //             {
  //               title: 'Mechanical analysis',
  //               href: '/management/lab-data/mechanical-analysis',
  //               children: [
  //                 {
  //                   title: 'Soil classification',
  //                   href: '/management/lab-data/mechanical-analysis/soil-classification',
  //                 },
  //                 {
  //                   title: 'Field water capacity',
  //                   href: '/management/lab-data/mechanical-analysis/field-water-capacity',
  //                 },
  //                 {
  //                   title: 'Water infiltration',
  //                   href: '/management/lab-data/mechanical-analysis/water-infiltration',
  //                 },
  //                 {
  //                   title: 'Bulk density',
  //                   href: '/management/lab-data/mechanical-analysis/bulk-density',
  //                 },
  //                 {
  //                   title: 'Porosity',
  //                   href: '/management/lab-data/mechanical-analysis/porosity',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           title: 'Season',
  //           href: '/management/season',
  //           icon: CalendarTodayOutlinedIcon,
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/calendar',
  //       icon: CalendarTodayIcon,
  //     },
  //     {
  //       title: 'Settings',
  //       href: '/settings',
  //       icon: SettingsIcon,
  //       children: [
  //         {
  //           title: 'Profile',
  //           href: '/settings/profile',
  //           icon: PersonIcon,
  //         },
  //         {
  //           title: 'Organizations',
  //           href: '/settings/organizations',
  //           icon: Domain,
  //         },
  //       ],
  //     },
  //   ],
  // },
];
