import uuid from 'uuid/v1';
import moment from 'moment';

import mock from 'utils/mock';

mock.onGet('/api/chat/conversations').reply(200, {
  conversations: [
    {
      id: uuid(),
      otherUser: {
        name: 'Goran Petrovic',
        avatar: '/images/avatars/avatar_7.png',
        active: true,
        lastActivity: moment()
      },
      messages: [
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Goran Petrovic',
            avatar: '/images/avatars/avatar_7.png',
            lastActivity: moment()
          },
          content:
            'Dobili smo odlican rezultat, hoces da prodiskutujemo?',
          contentType: 'text',
          created_at: moment().subtract(10, 'hours')
        },
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content:
            'Moramo da ponovimo analizu, promenili su se parametri.',
          contentType: 'text',
          created_at: moment().subtract(2, 'hours')
        },
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Goran Petrovic',
            avatar: '/images/avatars/avatar_7.png'
          },
          content:
            'Da li su se promenile cene u poslednjih 7 dana?',
          contentType: 'text',
          created_at: moment().subtract(5, 'minutes')
        },
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content:
            'Porazgovaracemo o analizi prekosutra. Cujemo se posle vikenda!',
          contentType: 'text',
          created_at: moment().subtract(3, 'minutes')
        },
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Goran Simic',
            avatar: '/images/avatars/avatar_7.png'
          },
          content: 'Najbolje sto mogu da ponudim je 500',
          contentType: 'text',
          created_at: moment().subtract(1, 'minute')
        },
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Goran Simic',
            avatar: '/images/avatars/avatar_7.png'
          },
          content: '/images/projects/bg1.jpg',
          contentType: 'image',
          created_at: moment().subtract(1, 'minute')
        }
      ],
      unread: 0,
      created_at: moment().subtract(1, 'minute')
    },
    {
      id: uuid(),
      otherUser: {
        name: 'Sanja Petrović',
        avatar: '/images/avatars/avatar_2.png',
        active: true,
        lastActivity: moment()
      },
      messages: [
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content: 'Sta mislis o poslednjoj liniji proizvoda?',
          contentType: 'text',
          created_at: moment().subtract(6, 'minutes')
        },
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Sanja Petrović',
            avatar: '/images/avatars/avatar_2.png'
          },
          content: 'Nisam sigurna da je to najbolje',
          contentType: 'text',
          created_at: moment().subtract(5, 'minutes')
        }
      ],
      unread: 3,
      created_at: moment().subtract(26, 'minutes')
    },
    {
      id: uuid(),
      otherUser: {
        name: 'Ana Petrovic',
        avatar: '/images/avatars/avatar_9.png',
        active: false,
        lastActivity: moment().subtract(2, 'minutes')
      },
      messages: [
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Ana Petrovic',
            avatar: '/images/avatars/avatar_9.png'
          },
          content: 'Zdravo, danas isporucujemo proizvod',
          contentType: 'text',
          created_at: moment().subtract(2, 'hours')
        },
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content: 'Oh, totally forgot about it',
          contentType: 'text',
          created_at: moment()
            .subtract(1, 'hour')
            .subtract(2, 'minutes')
        },
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content: 'Vazi',
          contentType: 'text',
          created_at: moment().subtract(1, 'hour')
        }
      ],
      unread: 0,
      created_at: moment().subtract(3, 'hours')
    },
    {
      id: uuid(),
      otherUser: {
        name: 'Zlatan Jugic',
        avatar: '/images/avatars/avatar_10.png',
        active: true,
        lastActivity: moment()
      },
      messages: [
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content:
            'Hej, mislim da krecemo projekat zajedno, poslacemo detalje kasnije',
          contentType: 'text',
          created_at: moment().subtract(3, 'days')
        },
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Goran Peric',
            avatar: '/images/avatars/avatar_10.png'
          },
          content: 'Haha, vazi uradicemo',
          contentType: 'text',
          created_at: moment().subtract(2, 'days')
        }
      ],
      unread: 1,
      created_at: moment().subtract(2, 'days')
    },
    {
      id: uuid(),
      otherUser: {
        name: 'Aleksa Krstic',
        avatar: '/images/avatars/avatar_3.png',
        active: false,
        lastActivity: moment().subtract(4, 'hours')
      },
      messages: [
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content: 'Jel stigla moja poruka?',
          contentType: 'text',
          created_at: moment().subtract(3, 'days')
        },
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Milan Rakic',
            avatar: '/images/avatars/avatar_3.png'
          },
          content: 'Pogledacu kasnije',
          contentType: 'text',
          created_at: moment().subtract(2, 'days')
        }
      ],
      unread: 0,
      created_at: moment().subtract(5, 'days')
    },
    {
      id: uuid(),
      otherUser: {
        name: 'Slavko Jovanovic',
        avatar: '/images/avatars/avatar_6.png',
        active: true,
        lastActivity: moment()
      },
      messages: [
        {
          id: uuid(),
          sender: {
            authUser: false,
            name: 'Slavko Jovanovic',
            avatar: '/images/avatars/avatar_6.png'
          },
          content: 'Odlicni projekti!',
          contentType: 'text',
          created_at: moment().subtract(2, 'days')
        },
        {
          id: uuid(),
          sender: {
            authUser: true,
            name: 'Jovana Miljković',
            avatar: '/images/avatars/avatar_11.png'
          },
          content: 'Da, da dajemo sve od sebe',
          contentType: 'text',
          created_at: moment().subtract(3, 'days')
        }
      ],
      unread: 0,
      created_at: moment().subtract(5, 'days')
    }
  ]
});

mock.onGet('/api/chat/activity').reply(200, {
  groups: [
    {
      id: 'clients',
      name: 'Clients'
    },
    {
      id: 'friends',
      name: 'Friends'
    }
  ],
  connections: [
    {
      id: uuid(),
      name: 'Sanja Petrović',
      avatar: '/images/avatars/avatar_2.png',
      active: false,
      lastActivity: moment().subtract(24, 'minutes'),
      group: 'clients'
    },
    {
      id: uuid(),
      name: 'Vladimir Pec',
      avatar: '/images/avatars/avatar_3.png',
      active: true,
      lastActivity: moment(),
      group: 'clients'
    },
    {
      id: uuid(),
      name: 'Djordje Djordjevic',
      avatar: '/images/avatars/avatar_5.png',
      active: false,
      lastActivity: moment().subtract(1, 'minutes'),
      group: 'clients'
    },
    {
      id: uuid(),
      name: 'Ivana Janjic',
      avatar: '/images/avatars/avatar_8.png',
      active: true,
      lastActivity: moment(),
      group: 'clients'
    },
    {
      id: uuid(),
      name: 'Milan Petrovic',
      avatar: '/images/avatars/avatar_6.png',
      active: true,
      lastActivity: moment(),
      group: 'friends'
    },
    {
      id: uuid(),
      name: 'Nikola Lazic',
      avatar: '/images/avatars/avatar_7.png',
      active: false,
      lastActivity: moment().subtract(24, 'minutes'),
      group: 'friends'
    },
    {
      id: uuid(),
      name: 'Ina Djuric',
      avatar: '/images/avatars/avatar_9.png',
      active: true,
      lastActivity: moment(),
      group: 'friends'
    },
    {
      id: uuid(),
      name: 'Slavko Milic',
      avatar: '/images/avatars/avatar_10.png',
      active: true,
      lastActivity: moment(),
      group: 'friends'
    },
    {
      id: uuid(),
      name: 'Jovana Miljković',
      avatar: '/images/avatars/avatar_11.png',
      active: true,
      lastActivity: moment(),
      group: 'friends'
    },
    {
      id: uuid(),
      name: 'Ana Grbic',
      avatar: '/images/avatars/avatar_12.png',
      active: false,
      lastActivity: moment().subtract(2, 'days')
    }
  ]
});
