import React, { useEffect, useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar/Sidebar";
import { ActiveOrgContext } from "App";
import PlotsMap from "views/SoilMaps/PlotsMap";
import AddRxModal from "./AddRxModal";
import { useQuery } from "react-query";
import { getOrgRxMaps } from "endpoints/rxMaps";
import { getUserMaps } from "endpoints/fieldMaps";
import ParcelSearchBar from "utils/SearchBar";
import * as turf from "@turf/turf";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExportSamplingModal from "views/LabData/ExportModal";
import DeleteModal from "views/SoilMaps/DeleteModal";

const useStyles = makeStyles(theme => ({
  bottomButton: {
    position: "sticky",
    bottom: "0",
    width: "100%",
    height: "52px",
    margin: "30px 0 0 0"
  },
  titleBox: {
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    color: "white",
    marginBottom: "20px"
  },
  accordion: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  accordionSummary: {
    borderRadius: theme.spacing(1)
  },
  accordionDetails: {
    flexDirection: "column"
  },
  accordionButtonStyle: {
    border: "1px solid gray",
    borderRadius: "5px",
    marginTop: "10px"
  },
  mapContainer: {
    height: "calc(100vh - 128px)",
    overflowY: "scroll"
  },
  accordionCheckbox: {
    position: "absolute",
    top: 2,
    right: 35
  }
}));

const RxMaps = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [plotData, setPlotData] = useState("");
  const [addObstacleMode, setAddObstacleMode] = useState(false);
  const [isPlotSelected, setIsPlotSelected] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const anyExpandedAccordion = !!expandedAccordion;
  const [userOrgContextValue] = useContext(ActiveOrgContext);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [selectedRxMap, setSelectedRxMap] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [checkedParcelIds, setCheckedParcelIds] = useState([]);

  const queryInfo = useQuery(["getUserMaps"], () =>
    getUserMaps(userOrgContextValue.id)
  );

  const { data, isLoading, refetch } = queryInfo;

  const { data: rxMaps, refetch: refecthRxMaps } = useQuery(
    [
      "getOrgRxMaps",
      { organizationId: userOrgContextValue.id, fieldId: plotData?.id }
    ],
    () =>
      getOrgRxMaps({
        organizationId: userOrgContextValue.id,
        fieldId: plotData?.id
      }),
    {
      enabled: !!plotData
    }
  );

  useEffect(() => {
    if (data) {
      const allParcelIds = data.data.map((parcel) => parcel.id);
      setCheckedParcelIds(allParcelIds);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const updatedPlot = data.data.find(plot => plot.id === expandedAccordion);
      if (updatedPlot) {
        setSelectedPlot(updatedPlot.geoData);
      }
    }
  }, [data, expandedAccordion]);

  const handleAccordionChange = plotId => (event, newExpanded) => {
    setExpandedAccordion(newExpanded ? plotId : false);
  };

  const handleParcelCheckboxChange = (plotId, event) => {
    event.stopPropagation();

    if (checkedParcelIds.includes(plotId)) {
      setCheckedParcelIds(prevCheckedIds =>
        prevCheckedIds.filter(id => id !== plotId)
      );
    } else {
      setCheckedParcelIds(prevCheckedIds => [...prevCheckedIds, plotId]);
    }
  };


  const handlePlotClick = (plotData, plotId) => {
    if (plotData.features) {
      setSelectedPlot(plotData.features);
    } else {
      setSelectedPlot(plotData);
    }
    setIsPlotSelected(prevState =>
      expandedAccordion === plotId ? !prevState : true
    );
    refecthRxMaps();
  };

  const handleRxMapClick = plot => {
    if (plot.features) {
      setExportModalOpen(true);
      setSelectedPlot(plot);
    } else {
      setSelectedPlot(plot);
    }
  };

  const handleDeleteModalOpen = rxMap => {
    setDeleteModalOpen(true);
    setSelectedRxMap(rxMap);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setSelectedRxMap(null);
  };

  useEffect(() => {
    if (!anyExpandedAccordion) {
      setSelectedPlot("");
    }
  }, [anyExpandedAccordion]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearch = term => {
    setSearchTerm(term);
  };

  const filteredParcels = data?.data?.filter(parcel =>
    parcel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const plotToReturn = useMemo(() => {
    if (isPlotSelected) return selectedPlot;
    if (data) {
      const formatedData = data.data.map(item => item.geoData.features).flat();
      return {
        features: formatedData,
        properties: { name: 'Global' },
        type: "FeatureCollection"
      };
    }
  }, [data, isPlotSelected, selectedPlot]);

  return (
    <>
      <Sidebar style={{ height: "100vh" }}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box className={classes.titleBox}>
            <Typography variant="h6">Rx maps</Typography>
          </Box>
          {isLoading && <LinearProgress />}
          <ParcelSearchBar onSearch={handleSearch} />
          <Box className={classes.mapContainer}>
            {filteredParcels?.map(plot => (
              <Accordion
                key={plot.id}
                className={classes.accordion}
                expanded={expandedAccordion === plot.id}
                onChange={handleAccordionChange(plot.id)}
              >
                <AccordionSummary
                  className={classes.accordionSummary}
                  onClick={() => {
                    handlePlotClick(plot.geoData, plot.id);
                    setPlotData(plot);
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>{plot.name}</Typography>
                  {!isPlotSelected &&
                    <Checkbox
                      checked={checkedParcelIds.includes(plot.id)}
                      onChange={event =>
                        handleParcelCheckboxChange(plot.id, event)
                      }
                      onClick={e => e.stopPropagation()}
                      className={classes.accordionCheckbox}
                    />
                  }
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Button className={classes.accordionButtonStyle}>
                    Size: {(turf.area(plot.geoData) * 0.0001).toFixed(2)} HA
                  </Button>
                  {rxMaps?.data.map(rxMap => (
                    <>

                    <Button
                      key={rxMap.id}
                      className={classes.accordionButtonStyle}
                      onClick={() => {
                        handleRxMapClick(rxMap.geoData)
                        setSelectedRxMap(rxMap)
                      }}
                    >
                      {`${rxMap.type}: ${rxMap.name}`}
                    </Button>

                     <Button
                     onClick={() =>
                      handleDeleteModalOpen(rxMap)
                     }

                     className={classes.accordionButtonStyle}
                   >
                     Delete Rx Map
                   </Button>
                    </>
                  ))}

                  <Button
                    className={classes.accordionButtonStyle}
                    onClick={handleOpenModal}
                  >
                    Create Fixed Maps
                  </Button>
                  <Button
                    className={classes.accordionButtonStyle}
                    // onClick={handleOpenModal}
                  >
                    Create Rx Variable Maps
                  </Button>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Sidebar>
      <PlotsMap
        selectedPlot={plotToReturn}
        refetch={refetch}
        addObstacleMode={addObstacleMode}
        plotData={plotData}
        setAddObstacleMode={setAddObstacleMode}
        isPlotSelected={isPlotSelected}
        allPlotsData={data?.data}
        checkedParcelIds={checkedParcelIds}
      />
        {selectedRxMap && (
          <ExportSamplingModal
            isOpen={isExportModalOpen}
            onClose={() => setExportModalOpen(false)}
            selectedSamplingItem={selectedRxMap?.geoData}
            selectedSampingName={selectedRxMap?.name}
            selectedSamplingId={selectedRxMap?.id}
            type='rxMap'
          />
        )
      }


          <DeleteModal
          open={deleteModalOpen}
          isOpen={deleteModalOpen}
          onClose={handleDeleteModalClose}
          plotData={selectedRxMap}
          refetch={refecthRxMaps}
          type='rxMap'
        />

      <AddRxModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        plotData={plotData}
        refecthRxMaps={refecthRxMaps}
      />
    </>
  );
};

export default RxMaps;
