import {RestService} from './rest.service';

class OrganizationService extends RestService {

  getOrganization() {
    return super.get('organization');
  }

  getOrganizationMembers() {
    return super.get('organization/members');
  }

  createOrganization(data) {
    return super.post('organizations', data);
  }

  updateOrganization(data) {
    return super.put('organization', data);
  }

  deleteOrganization() {
    return super.destroy('organization');
  }

  updateOrganizationAvatar(data) {
    return super.post('organization/logo', data);
  }

  removeLogo() {
    return super.destroy('organization/logo');
  }

  updateOrganizationDomain(subdomain) {
    return super.put('organization/domain', {
      subdomain,
    });
  }
}

export default OrganizationService;
