import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M49.4,39.4l0-0.5c0-0.7,0-1.5,0-2.2c0-0.5-0.4-0.9-0.6-1.2c-0.1-0.1-0.1-0.2-0.2-0.2
				c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.2-0.8,0.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.3-0.6,0.7-0.6,1.2c0,1.1,0,2.2,0,3.2
				c0,0.7,0,1.3,0,2c0,2.5-1.9,4.3-4.3,4.3c-0.6,0-1.2,0-1.7,0c-1.1,0-2.2,0-3.4,0c-0.5,0-0.9,0.3-1.2,0.5c-0.1,0.1-0.2,0.1-0.3,0.2
				c-0.3,0.2-0.5,0.4-0.5,0.8c0,0.3,0.1,0.6,0.4,0.8c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.3,0.7,0.6,1.3,0.6c0.9,0,1.9,0.1,2.8,0.1
				c0.6,0,1.2,0,1.8,0l0.7,0c3.4,0,6.2-2.2,7-5.5c0.2-1,0.3-2,0.3-2.9c0-0.4,0-0.8,0.1-1.2C49.5,39.7,49.5,39.5,49.4,39.4z"
          />
          <path
            className="st0"
            d="M14.4,47c-0.3-0.2-0.7-0.6-1.2-0.6c-1.3-0.1-2.7,0-4,0l-0.5,0c-1.7,0-3-0.4-3.8-1.2
				c-0.8-0.8-1.2-2.1-1.2-3.7l0-1c0-1.2,0-2.4,0-3.5c0-0.5-0.3-1-0.6-1.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.4-0.4-0.8-0.4
				c-0.3,0-0.6,0.2-0.8,0.5c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.5,0.7-0.5,1.2c-0.1,0.7-0.1,1.4,0,2.1l0,0.7c0,0,0,0.1,0,0.1l0,0.9
				c0,0.6,0,1.3,0,1.9c0.1,3.8,3.1,6.8,6.9,7c0.8,0,1.6,0,2.3,0c1.2,0,2.4,0,3.4-0.1c0.5,0,0.9-0.3,1.2-0.6c0.1-0.1,0.2-0.1,0.2-0.2
				c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.4-0.8C14.6,47.1,14.5,47,14.4,47z"
          />
          <path
            className="st0"
            d="M14.4,1.2c-0.3-0.2-0.7-0.5-1.2-0.6c-0.7-0.1-1.4-0.1-2.1,0l-0.6,0c-0.1,0-0.1,0-0.2,0l-0.8,0
				c-0.6,0-1.2,0-1.9,0c-3.9,0.1-6.9,3.1-7,6.9c-0.1,1.7-0.1,3.6,0,5.6c0,0.6,0.3,1,0.6,1.3c0.1,0.1,0.1,0.2,0.2,0.3
				c0.2,0.3,0.5,0.4,0.8,0.4c0,0,0,0,0,0c0.3,0,0.6-0.2,0.8-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.5-0.7,0.5-1.2
				c0.1-1.3,0-2.6,0-3.9l0-0.8C3.7,7,4.1,5.8,4.9,5c0.8-0.8,2-1.3,3.5-1.2l0.9,0c1.2,0,2.5,0,3.7,0c0.5,0,0.9-0.3,1.3-0.5
				c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.5,0.5-0.8s-0.2-0.6-0.4-0.8C14.5,1.4,14.5,1.3,14.4,1.2z"
          />
          <path
            className="st0"
            d="M35.5,3.1c0.3,0.2,0.7,0.5,1.2,0.6c1.3,0.1,2.5,0,3.8,0l0.8,0c1.7,0,2.9,0.4,3.7,1.2
				c0.8,0.8,1.2,2.1,1.2,3.7l0,0.9c0,1.2,0,2.4,0,3.6c0,0.6,0.4,1,0.6,1.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.5,0.4,0.8,0.4
				c0,0,0,0,0,0c0.3,0,0.6-0.2,0.8-0.5c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.5-0.7,0.5-1.2c0.1-1.9,0.1-3.8,0-5.6
				c-0.1-3.2-2.3-5.9-5.3-6.7c-1-0.2-2-0.3-2.9-0.3c-0.4,0-0.8,0-1.2-0.1c-0.1,0-0.3,0-0.4,0.1l-0.5,0c-0.8,0-1.5,0-2.3,0
				c-0.5,0-0.9,0.4-1.2,0.6c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.3,0.2,0.6,0.4,0.8C35.4,3,35.4,3.1,35.5,3.1z"
          />
          <path
            className="st0"
            d="M18.4,3.1c0.3,0.3,0.8,0.6,1.3,0.6c1.8,0,3.5,0,5.3,0c1.7,0,3.5,0,5.2,0c0.6,0,1-0.3,1.3-0.6
				c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.5,0.4-0.8c0-0.3-0.2-0.6-0.5-0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.8-0.5-1.3-0.5
				c-1.2-0.1-2.5,0-3.8,0l-1.4,0c0,0-0.1,0-0.1,0l-1.3,0c-1.3,0-2.6,0-3.8,0c-0.5,0-0.9,0.3-1.3,0.5c-0.1,0.1-0.2,0.1-0.3,0.2
				c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.2,0.6,0.4,0.8C18.3,3,18.3,3.1,18.4,3.1z"
          />
          <path
            className="st0"
            d="M3.6,19.7c0-0.5-0.3-0.9-0.5-1.2C3.1,18.4,3,18.3,3,18.2c-0.2-0.3-0.4-0.5-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.4
				c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0.3-0.6,0.7-0.6,1.3c-0.1,3.5-0.1,7.1,0,10.7c0,0.6,0.4,1,0.6,1.3c0.1,0.1,0.1,0.2,0.2,0.2
				c0.2,0.2,0.5,0.4,0.8,0.4c0,0,0,0,0,0c0.3,0,0.6-0.2,0.8-0.4C3,31.7,3,31.7,3.1,31.6c0.2-0.3,0.5-0.7,0.6-1.2c0-1.3,0-2.6,0-3.9
				l0-1.3l0-1.4C3.7,22.4,3.7,21.1,3.6,19.7z"
          />
          <path
            className="st0"
            d="M46.3,30.3c0,0.6,0.3,1,0.6,1.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.5,0.4,0.8,0.4c0,0,0,0,0,0
				c0.3,0,0.6-0.2,0.8-0.5c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.5-0.7,0.5-1.3c0-3.6,0-7.2,0-10.5c0-0.6-0.4-1-0.6-1.3
				c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.2-0.8,0.5c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.5,0.7-0.5,1.2
				c0,1.3,0,2.6,0,3.9l0,1.4l0,1.4C46.2,27.7,46.2,29,46.3,30.3z"
          />
          <path
            className="st0"
            d="M31.6,46.9c-0.3-0.2-0.7-0.6-1.2-0.6c-1.3,0-2.7,0-4,0l-1.3,0l-1.4,0c-1.3,0-2.6,0-4,0
				c-0.5,0-0.9,0.3-1.2,0.6c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.3,0.2,0.6,0.4,0.8c0.1,0.1,0.2,0.1,0.2,0.2
				c0.3,0.2,0.7,0.6,1.3,0.6c1.8,0,3.7,0,5.5,0c1.7,0,3.4,0,5.1,0c0.6,0,1-0.3,1.3-0.6c0.1-0.1,0.2-0.1,0.2-0.2
				c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.4-0.8C31.7,47.1,31.6,47,31.6,46.9z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
