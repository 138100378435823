import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';

import mock from 'utils/mock';

mock.onGet('/api/calendar').reply(200, {
  draft: [],
  events: [
    {
      id: uuid(),
      title: 'Zakazi obilazak',
      desc: 'Proveri stanje useva',
      color: colors.green['700'],
      start: moment('2019-07-01 16:55:00'),
      end: moment('2019-07-01 17:02:00')
    },
    {
      id: uuid(),
      title: 'Posalji analizu',
      desc: 'Uzeti su uzorci sa svih pozicija',
      start: moment('2019-07-03 08:55:00'),
      end: moment('2019-07-04 15:02:00')
    },
    {
      id: uuid(),
      title: 'Laboratorija',
      desc: 'Proveri status',
      allDay: true,
      start: moment('2019-07-14 16:55:00')
    },
    {
      id: uuid(),
      title: 'Plan setve',
      desc: 'Raspodela po poljima',
      allDay: true,
      start: moment('2019-07-18 07:00:00')
    },
    {
      id: uuid(),
      title: 'Proveri cenu goriva',
      desc: 'Ukupno, za sve masine',
      color: colors.green['700'],
      start: moment('2019-07-20 08:55:00'),
      end: moment('2019-07-20 09:30:00')
    },
    {
      id: uuid(),
      title: 'Nazovi vlasnika organizacija',
      desc: 'Proveri da li su korisnici prijavljeni',
      allDay: true,
      start: moment('2019-07-30 08:00:00')
    },
    {
      id: uuid(),
      title: 'Poseti usev',
      color: colors.green['700'],
      desc: 'Zabelezi stanje',
      start: moment('2019-07-30 08:00:00'),
      end: moment('2019-07-30 09:00:00')
    }
  ]
});
