import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Alert} from '@material-ui/lab';
import {useDispatch} from 'react-redux';
import {removeMessage} from 'actions/alertActions';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  alertWrapper: {
    maxWidth: '100%',
    minWidth: '50%',
    marginTop: '10px',
    zIndex: 99,
  },
}));


const Popup = props => {
  const { id, message, severity } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const onClose = () => dispatch(removeMessage(id));

  useEffect(() => {
    setTimeout(() => dispatch(removeMessage(id)), 6000);
  }, [dispatch, id]);

  return (
    <div className={classes.root}>
      <Alert
        onClose={onClose}
        className={classes.alertWrapper}
        severity={severity}
        variant={'filled'}
      >
        {message}
      </Alert>
    </div>
  );
};

Popup.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
};

export default Popup;
