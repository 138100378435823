import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  CardHeader,
  colors,
  Divider,
  Grid,
  Popover,
  TextField,
} from '@material-ui/core';
import {EmptyList, OrganizationsList} from './components';
import {
  getFieldError,
  getFormInitState,
  handleDateFieldChange,
  handleFieldChange,
  isFieldInvalid,
  REQUIRED,
  REQUIRED_EMAIL,
  REQUIRED_REGEX,
  validateForm,
} from 'utils/form';
import {useTranslation} from 'react-i18next';
import {
  getUsersActiveOrganizationDomain,
  userHasActiveOrganizationDomain,
} from 'utils/user';
import OrganizationService from 'services/organization.service';
import {isCreated, isOk} from 'utils/request-response';
import {
  addErrorMessage,
  addSuccessMessage,
  refreshUserProfile,
} from 'actions';
import {logger} from 'utils/logger';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmDialog from 'components/Dialog';
import _ from 'lodash';
import {DateField, OptionField} from 'components/Form/Fields';
import {PageLoaderContext} from 'context/Page';

const baseDomain = process.env.REACT_APP_API_DOMAIN;

const useStyles = makeStyles(theme => ({
  root: {
    width: 350,
    maxWidth: '100%',
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center',
  },
  addButton: {
    position: 'absolute',
    right: '8px',
    top: '10px',
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  form: {
    position: 'relative',
  },
  input: {
    width: '100%',
    marginBottom: 0,
  },
  helperText: {
    marginLeft: '5px',
    marginRight: '5px',
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formSaveButton: {
    width: '48%',
    marginTop: '20px',
    marginBottom: '20px',
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  formCancelButton: {
    width: '48%',
    marginTop: '20px',
    marginBottom: '20px',
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900],
    },
  },
}));

const validationSchema = {
  name: REQUIRED,
  country: REQUIRED,
  email: REQUIRED_EMAIL,
  subdomain: REQUIRED_REGEX('[a-zA-Z0-9-]+', 'form:organization.organizationGeneralSettings.domainValidationPattern'),
};

const OrganizationsPopover = props => {
  const {organizations, anchorEl, ...rest} = props;
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {setPageLoading} = useContext(PageLoaderContext);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [formState, setFormState] = useState(getFormInitState());
  const user = useSelector(state => state.session.user);
  const countriesConf = useSelector(state => state.config.countries)
    .sort((a, b) => a.name > b.name ? 1 : -1);
  const countries = _.uniqBy(countriesConf, 'name');

  const handleSubmit = async(event) => {
    event.preventDefault();
    if (userHasActiveOrganizationDomain(user)) {
      setIsAddModalOpen(false);
      setPageLoading(true);
      try {
        const {values} = formState;
        const {
          subdomain,
          email,
          name,
          longName,
          founded_at,
          phone,
          country,
        } = values;

        const data = {
          email: email,
          name: name,
          long_name: longName,
          founded_at: founded_at,
          domain: subdomain + '.' + baseDomain,
          telephones: {
            label: 'telephone',
            value: phone,
          },
          addresses: {
            label: 'address1',
            country: country,
          },
        };

        const organizationService = new OrganizationService();
        organizationService.setDomain(getUsersActiveOrganizationDomain(user));
        const response = await organizationService.createOrganization(data);
        if (isOk(response) || isCreated(response)) {
          dispatch(refreshUserProfile());
          setFormState(getFormInitState());
          dispatch(addSuccessMessage(t('form:organization.organizationGeneralSettings.create.success')));
        } else {
          dispatch(addErrorMessage(t('form:organization.organizationGeneralSettings.create.error')));
        }
        setPageLoading(false);
      } catch (e) {
        logger.error(e);
        dispatch(addErrorMessage(t('form:organization.organizationGeneralSettings.create.error')));
        setPageLoading(false);
      }
    }
  };

  useEffect(() => validateForm(formState.values, validationSchema, setFormState), [formState.values]);

  const handleChange = event => handleFieldChange(event, formState, setFormState);

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Organizations"/>
        <Button
          className={classes.addButton}
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => {
            setIsAddModalOpen(true);
          }}
        >
          {t('page:organization.addOrganization')}
        </Button>
        <Divider/>
        {organizations.length > 0 ?
          <OrganizationsList organizations={organizations}/> : <EmptyList/>}
        <ConfirmDialog
          isOpen={isAddModalOpen}
          setIsOpen={setIsAddModalOpen}
          title={t('form:organization.organizationGeneralSettings.create.modalTitle')}
          showActions={false}
        >
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  className={classes.input}
                  required={true}
                  label={t('form:organization.organizationGeneralSettings.shortName')}
                  onChange={handleChange}
                  name="name"
                  value={_.has(formState, ['values', 'name']) ? formState.values.name : ''}
                  error={isFieldInvalid('name', formState)}
                  helperText={getFieldError('name', formState)}
                  variant="outlined"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  className={classes.input}
                  label={t('form:organization.organizationGeneralSettings.longName')}
                  onChange={handleChange}
                  name="longName"
                  value={_.has(formState, ['values', 'longName']) ? formState.values.longName : ''}
                  error={isFieldInvalid('longName', formState)}
                  helperText={getFieldError('longName', formState)}
                  variant="outlined"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  className={classes.input}
                  label={t('form:organization.organizationGeneralSettings.email')}
                  onChange={handleChange}
                  name="email"
                  required={true}
                  value={_.has(formState, ['values', 'email']) ? formState.values.email : ''}
                  error={isFieldInvalid('email', formState)}
                  helperText={getFieldError('email', formState)}
                  variant="outlined"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  className={classes.input}
                  label={t('form:organization.organizationGeneralSettings.phone')}
                  onChange={handleChange}
                  name="phone"
                  value={_.has(formState, ['values', 'phone']) ? formState.values.phone : ''}
                  error={isFieldInvalid('phone', formState)}
                  helperText={getFieldError('phone', formState)}
                  variant="outlined"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <OptionField
                  className={classes.input}
                  label={t('form:organization.organizationGeneralSettings.country')}
                  onChange={handleChange}
                  name="country"
                  value={_.has(formState, ['values', 'country']) ? formState.values.country : ''}
                  error={isFieldInvalid('country', formState)}
                  helperText={getFieldError('country', formState)}
                  options={countries.map(country => ({
                    id: country.name,
                    label: country.name,
                  }))}
                  required={true}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <DateField
                  className={classes.input}
                  label={t('form:organization.organizationGeneralSettings.founded')}
                  onChange={event => handleDateFieldChange(event, 'founded_at', formState, setFormState)}
                  name="founded_at"
                  value={_.has(formState, ['values', 'founded_at']) ? formState.values.founded_at : ''}
                  error={isFieldInvalid('founded_at', formState)}
                  helperText={getFieldError('founded_at', formState)}
                  variant="outlined"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  className={classes.input}
                  label={t('form:organization.organizationGeneralSettings.domain')}
                  onChange={handleChange}
                  name="subdomain"
                  required={true}
                  value={_.has(formState, ['values', 'subdomain']) ? formState.values.subdomain : ''}
                  error={isFieldInvalid('subdomain', formState)}
                  helperText={getFieldError('subdomain', formState)}
                  variant="outlined"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  InputProps={{
                    endAdornment: <span>.{baseDomain}</span>,
                  }}
                />
              </Grid>
            </Grid>
            <div className={classes.formButtons}>
              <Button
                className={classes.formSaveButton}
                type="submit"
                variant="contained"
                disabled={!formState.isValid}
              >
                {t('form:save')}
              </Button>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  setIsAddModalOpen(false);
                  setFormState(getFormInitState());
                }}
                className={classes.formCancelButton}
                type="submit"
                variant="contained"
                disabled={false}
              >
                {t('form:cancel')}
              </Button>
            </div>
          </form>
        </ConfirmDialog>
      </div>
    </Popover>
  );
};

OrganizationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  organizations: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default OrganizationsPopover;
