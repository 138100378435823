import {translate} from 'utils/translate';

export const depthOptions = [
  {id: '0-30', label: '0-30cm'},
  {id: '30-60', label: '30-60cm'},
  {id: '60-90', label: '60-90cm'},
];

export const fieldStatusOptions = [
  {id: 'available', label: translate('form:polygon.status.available')},
  {id: 'unavailable', label: translate('form:polygon.status.unavailable')},
];
