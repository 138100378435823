import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import appEn from 'locales/en/acre.json';
import formEn from 'locales/en/form.json';
import pageEn from 'locales/en/page.json';
import AppSr from 'locales/sr/acre.json';
import formSr from 'locales/sr/form.json';
import pageSr from 'locales/sr/page.json';
import langEn from 'locales/en/languages.json';
import langSr from 'locales/sr/languages.json';
import {isDevelopment} from 'utils/util';


i18n
  .use(initReactI18next) 
  .init({
    lng: localStorage.getItem('lang') || 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        app: appEn,
        form: formEn,
        page: pageEn,
        lang: langEn,
      },
      sr: {
        app: AppSr,
        form: formSr,
        page: pageSr,
        lang: langSr,
      },
    },
    // files to load
    ns: ['app', 'form', 'page', 'lang'],
    defaultNS: 'app',
    interpolation: {
      escapeValue: false,
    },
    debug: true && isDevelopment(),
  });

export default i18n;
