import _ from 'lodash';

/**
 * Get user full name
 *
 * @param user
 * @return {string}
 */
export function getUserFullName(user) {
  const {
    first_name,
    last_name,
  } = user;
  return `${first_name} ${last_name}`;
}

/**
 * Check if user has organization domain
 *
 * @param user
 * @return {boolean}
 */
export function userHasActiveOrganizationDomain(user) {
  return getUsersActiveOrganizationDomain(user) !== null;
}

/**
 * Get users organization domain
 *
 * @param user
 * @return {any}
 */
export function getUsersActiveOrganizationDomain(user) {
  const {
    activeOrganization,
  } = user;

  return getOrganizationDomain(activeOrganization);
}

/**
 * Is organization has domain
 *
 * @param organization
 * @return {boolean}
 */
export function organizationHasDomain(organization) {
  return getOrganizationDomain(organization) != null;
}

/**
 * Get organization domain
 *
 * @param organization
 * @return {any}
 */
export function getOrganizationDomain(organization) {
  return organization && _.has(organization, 'domain') ?
    organization.domain : null;
}

/**
 * Get user organization. First try to fetch organization owned by user.
 * If no organization owned by user => fetch first one.
 *
 * @param organizations
 * @return {*}
 */
export function getDefaultOrganization(organizations) {
  let organization = null;

  if (organizations && _.isArray(organizations) && !_.isEmpty(organizations)) {
    const ownedOrganizations = organizations.filter(o => o.owner);
    if (!_.isEmpty(ownedOrganizations)) {
      organization = _.first(ownedOrganizations);
    } else {
      organization = _.first(organizations);
    }
  }

  return organization;
}
