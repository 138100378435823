import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import { getSingleOrganization } from 'endpoints/organizations';

import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification,
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';

import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

const history = createBrowserHistory();
const store = configureStore();
export const AuthContext = React.createContext(null);
export const ActiveOrgContext = React.createContext(null);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });
  const [authContext, setAuthContext] = useState(
    JSON.parse(localStorage.getItem('userData')),
  );
  const [activeOrg, setActiveOrg] = useState(
    JSON.parse(localStorage.getItem('activeOrg')),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={[authContext, setAuthContext]}>
        <ActiveOrgContext.Provider value={[activeOrg, setActiveOrg]}>
          <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={history}>
                  <ScrollReset />
                  <GoogleAnalytics />
                  <CookiesNotification />
                  {renderRoutes(routes)}
                </Router>
              </MuiPickersUtilsProvider>
              <ToastContainer />
            </ThemeProvider>
          </StoreProvider>
        </ActiveOrgContext.Provider>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
