import axiosInstance from ".";

export const getSamplingMapsByParcel = async id => {
    return axiosInstance
      .get(`/zone-sampling/${id}`)
      .then(res => res.data);
  };

export const getSamplingMapsByOrg = async(organizationId, fieldId) => {
    return axiosInstance
      .get(`/zone-sampling/organization/${organizationId}?page=1&limit=20&fieldId=${fieldId}`)
      .then(res => res.data);
  };

export const createSamplingMap = async params => {
    return axiosInstance
      .post('/zone-sampling/create', params);
  };

export const deleteSamplingMap =async  id => {
    return axiosInstance
      .delete(`/zone-sampling/${id}`).then(res => res.data);
  };

export const updateSamplingMap = (id, params) => {
    return axiosInstance
      .put(`/zone-sampling/${id}/update`, params);
  };

export const getSamplingFile = async params => {
    return axiosInstance
      .post('/zone-sampling/file/export', params);
  };

  export const downloadExcelFile = async (zoneSamplingId) => {
    return axiosInstance
      .get(`/lab/template/${zoneSamplingId}`, {
        responseType: 'blob',
      })
      .then(res => res.data);
  };


