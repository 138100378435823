import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M25.4,12.1V1.6H0.5v46.8h23.2h1.8h24.1V12.1H25.4z M21.9,12.1h-7v-7h7V12.1z M4,15.6h7.4v7.9H4V15.6z M4,27
		h7.4v6.6H4V27z M14.9,27h7v6.6h-7V27z M14.9,23.5v-7.9h7v7.9H14.9z M11.4,5.1v7H4v-7H11.4z M4,37h7.4v7.9H4V37z M14.9,44.9V37h7
		v7.9H14.9z M46,44.9H25.4V37h3.5v-3.5h-3.5V27h3.5v-3.5h-3.5v-7.9H46V44.9z"
        />
        <rect x="36.8" y="23.5" className="st0" width="3.9" height="3.5" />
        <rect x="36.8" y="33.5" className="st0" width="3.9" height="3.5" />
      </g>
    </SvgIcon>
  );
};

export default Icon;
