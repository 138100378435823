import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M37.9,47.4l0-8l6.3,0c1.1,0,1.6-0.5,1.6-1.6V5.5C45.9,3,43.9,1,41.5,1c-6.3,0-13,0-20.4,0
			c-2.1,0-3.9,1.5-4.3,3.5c-0.1,0.6-0.1,1.3-0.1,1.9l0,0.6l-0.5,0c-1.4,0-2.8,0-4.3,0c-1.1,0-2.3,0-3.4,0C6,7.1,4.1,9,4.1,11.5v35.9
			c0,0.7,0.2,1.2,0.7,1.4c0.4,0.3,1,0.2,1.6-0.1l1.9-1.1c4.2-2.3,8.3-4.6,12.5-6.9l0.2-0.1l0.2,0.1c4.8,2.7,9.6,5.3,14.4,8
			c0.6,0.3,1.1,0.4,1.6,0.1C37.7,48.6,37.9,48.1,37.9,47.4z M35.2,45.4l-6.4-3.5c-2.3-1.3-4.6-2.5-6.9-3.8c-0.6-0.4-1.2-0.4-1.9,0
			c-3.3,1.8-6.5,3.6-9.8,5.5l-3.4,1.9V11.6c0-0.6,0.2-1.1,0.5-1.4C7.6,10,8.1,9.8,8.7,9.8l4.5,0c1.5,0,3,0,4.5,0c2,0,4.1,0,6.1,0
			l9.4,0c0.7,0,1.2,0.1,1.6,0.5c0.3,0.3,0.5,0.8,0.5,1.6V45.4z M33.4,7.1l-14.1,0l0-0.5c0-0.6,0-1.1,0.1-1.6
			c0.2-0.9,1.1-1.3,1.8-1.3l7.4,0l12.5,0c1.3,0,2,0.7,2,2l0,30.9h-5.2l0-25C37.9,8.9,36.2,7.1,33.4,7.1z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
