import {
  SET_CROPS,
  SET_STATES,
  SET_DEPTHS,
  SET_COUNTRIES,
} from 'actions';

const initialState = () => {
  return {
    crops: [],
    depths: [],
    countries: [],
    states: [],
  };
};

const configReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SET_CROPS:
      return {
        ...state,
        crops: action.crops,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    case SET_STATES:
      return {
        ...state,
        states: action.states,
      };
    case SET_DEPTHS:
      return {
        ...state,
        depths: action.depths,
      };
    default :
      return state;
  }
};

export default configReducer;
