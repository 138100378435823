import { combineReducers } from 'redux';

import sessionReducer from 'reducers/sessionReducer';
import configReducer from 'reducers/configReducer';
import alertReducer from 'reducers/alertReducer';

const rootReducer = combineReducers({
  alerts: alertReducer,
  config: configReducer,
  session: sessionReducer,
});

export default rootReducer;
