import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g>
	<g>
		<path class="st0" d="M100,104.21c-16.85,0-30.55-13.71-30.55-30.55S83.15,43.11,100,43.11s30.55,13.71,30.55,30.55
			S116.85,104.21,100,104.21z M100,51.54c-12.2,0-22.12,9.92-22.12,22.12S87.8,95.79,100,95.79s22.12-9.92,22.12-22.12
			S112.2,51.54,100,51.54z"/>
	</g>
	<g>
		<path class="st0" d="M153.2,156.89H46.8c-2.33,0-4.21-1.89-4.21-4.21c0-21.55,17.43-38.98,38.85-38.98h37.14
			c21.42,0,38.85,17.43,38.85,38.85C157.42,154.87,155.53,156.89,153.2,156.89z M51.28,148.46h97.44
			c-2-14.85-14.76-26.34-30.15-26.34H81.43C66.04,122.12,53.28,133.61,51.28,148.46z"/>
	</g>
</g>
    </SvgIcon>
  );
};

export default Icon;
