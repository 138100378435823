import axios from 'axios';
import authService from 'services/auth.service';
import {isDevelopment} from 'utils/util';
import {STATUS_CODE_FORBIDDEN} from 'utils/request-response';

const instance = axios.create();

axios.interceptors.request.use(
  async req => {
    const token = authService.getJwt();

    // get token from secure store
    if (token) {
      req.headers['Authorization'] = `Bearer ${token}`;
    }
    return req;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  res => res,
  async(error) => {
    const originalRequest = error.config;
    if (!isDevelopment() && error.response && error.response.status === STATUS_CODE_FORBIDDEN) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const refreshTokenResponse = await authService.refreshToken();
        const jwtToken = refreshTokenResponse.payload.token;
        authService.setJwt(jwtToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
        return axios(originalRequest);
      } else {
        authService.deleteJwt();
        authService.deleteRefreshToken();
        window.location.replace('/auth/login');
        return Promise.reject(error);
      }
    }
    const defaultMessage = 'Oops, it looks like something went wrong!!!';
    const err = error.response ? error.response.data : {
      type: 'Unknown Server Error',
      message: defaultMessage,
      raw_error: error,
    };
    err.raw_error = error;

    if (!err.message) {
      err.message = defaultMessage;
    }

    return Promise.reject(err);
  });

export default instance;
