import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <path
        className="st0"
        d="M43.9,30.2c-0.8-0.6-1.7-1.3-2.6-1.9c-0.6-0.4-1.3-0.8-1.9-1.2c1.6-0.9,2.3-1.4,3.8-2.3
	c-2.2,0.1-4.2,0.7-6.3,1.2c-0.5-0.2-1.1-0.4-1.7-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.7-0.6,5.3-1.5,8.1-1.3c1.6-0.8,2.5-1.2,4.3-1.5
	c-3.4-1.1-6.1,0.3-9.2,0.9c-3.2,0.5-7.3,0-10.9-0.1c-1,0.8-1.6,1.1-3,1.3l0.3,0.1l-0.4,0.1l-0.6,0.1l0.3,0.1
	c1.7,0.7,3.5,1.3,5.2,1.8l0.3,0.1c-2.5-0.2-4.6-1.2-6.9-2.2c-1,0.1-2,0.1-3,0c0.4,0.3,0.9,0.6,1.4,0.9c-0.2,0.1-0.5,0.1-0.7,0.2
	c-0.5-0.4-1.1-0.8-1.7-1.3c-0.8-0.2-1.1-0.4-2.3-1c1.1,1.3,2.2,2.6,3.4,3.8L20,26.4h0l0,0c-1.2-1.3-2.3-2.7-3.2-4
	C15.2,21.5,14.6,21,13,20c1.4,2.4,3.1,4.7,4.9,6.8c-0.2,0-0.5,0.1-0.8,0.1l-0.4,0l0.4,0.1c0.6,0.2,1.2,0.5,1.8,0.7
	c0.3,0.4,0.7,0.7,1,1.1c-0.9-0.4-1.8-0.7-2.7-1.1c-2.4-2.7-4.3-5.9-6.3-9c-2.1-1-4.2-1.8-6.3-2.7c2.9,3.5,5.1,7.6,8.1,11
	c-0.7,0-1.4,0-2.4,0l-0.6,0l0.6,0.2c1.2,0.4,2.5,0.8,3.8,1.2c0.3,0.3,0.7,0.6,1,0.9l0.3,0.3c-2.6-0.9-5.2-1.7-7.7-2.4h0h0
	c-2.2,0.4-4.4,1-6.5,1.6l-0.4,0.1l0.4,0.1c1.3,0.2,2.6,0.4,3.9,0.6c5.6,0.8,11.4,1.6,16.5,4.1h0h0c0.9,0,1.5-0.1,2.2-0.1
	c0.4,0,0.9,0,1.4-0.1l0.4,0L25,33.5c-0.7-0.3-1.4-0.6-2-0.9c-0.9-0.5-1.7-1-2.5-1.6c2,0.8,4.1,1.7,6.2,2.6h0h0
	c1.4,0,2.7-0.1,4.1-0.3l0.4-0.1l-0.4-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c0.3-0.1,0.5-0.2,0.8-0.4c0.6,0.2,1.3,0.4,1.9,0.5h0l0.5-0.1
	c1.2-0.2,2.4-0.4,3.6-0.5l0.7,0l-0.7-0.2c-1.3-0.3-2.5-0.7-3.8-1c0.3-0.2,0.6-0.4,0.9-0.6c1.6,0.6,3.1,1.2,4.7,1.8h0
	c1.2,0,2.5,0.1,3.7,0.2l0.4,0l-0.3-0.2c-2-1.3-4.1-2.4-6.4-3.2c0.3-0.2,0.5-0.4,0.8-0.6c0.2,0.1,0.3,0.1,0.5,0.2
	c1.6,0.7,3.1,1.7,4.6,2.6c0.7,0.4,1.4,0.9,2.1,1.3h0c1.4,0.2,2.8,0.5,4.2,0.8l0.7,0.2l-0.6-0.3C47,32.4,45.4,31.4,43.9,30.2z
	 M1.4,29.1c2-0.6,4-1.1,6.1-1.5c2.6,0.8,5.4,1.6,8.2,2.5c1.3,1,2.7,2,4.1,2.8c-4.7-1.9-9.9-2.7-14.9-3.4C3.7,29.4,2.6,29.2,1.4,29.1
	z M26.4,24.7l0.7-0.1c2.5,0.9,5.2,1.2,7.8,1c0.6,0.2,1.2,0.4,1.8,0.6c-1.1,0.3-2.2,0.4-3.3,0.5c-0.4,0-0.7,0-1,0l-1-0.3
	C29.6,25.9,28,25.4,26.4,24.7L26.4,24.7z M23.2,25.5c2.1,1.3,4.2,2.6,6.6,3c1.2,0.2,2.5,0.1,3.7-0.1c0.5,0.2,0.9,0.3,1.4,0.5
	c-1.4,0.3-2.9,0.3-4.3,0.2c-1.7-0.6-3.3-1.1-5-1.7l-0.8-0.3c-0.7-0.4-1.5-0.9-2.2-1.5C22.7,25.7,22.9,25.6,23.2,25.5L23.2,25.5z
	 M17.4,27.1c0.2,0,0.4-0.1,0.6-0.1c0.1,0.2,0.3,0.3,0.4,0.5C18.1,27.4,17.7,27.2,17.4,27.1L17.4,27.1z M10.7,27.4c0.7,0,1.3,0,1.9,0
	h0.1c0.3,0.3,0.5,0.6,0.8,0.9C12.6,27.9,11.7,27.6,10.7,27.4L10.7,27.4z M18.6,29.4c-0.4-0.4-0.8-0.8-1.2-1.2
	c0.9,0.4,1.9,0.7,2.8,1.1c1.2,1.1,2.5,2.2,4,3c-1.4-0.6-2.8-1.2-4.2-1.7C19.5,30.2,19,29.8,18.6,29.4L18.6,29.4z M30.4,33.2
	c-0.8,0.1-1.5,0.2-2.3,0.2c0.6-0.1,1.1-0.2,1.6-0.5L30.4,33.2z M23.8,30c2.2,0.9,4.5,1.8,6.8,2.5C28.1,32.4,25.8,31.4,23.8,30
	L23.8,30z M33.2,32.7l-0.4,0.1c-3.3-0.8-6.5-2-9.6-3.3c-1-0.9-2-1.8-2.9-2.8c0.6,0.2,1.3,0.5,1.9,0.7c2.9,2.7,6.3,4.6,10.6,3.9
	c1.1,0.4,2.3,0.7,3.5,1C35.1,32.4,34.3,32.5,33.2,32.7z M25.8,27.8c1.3,0.4,2.5,0.8,3.8,1.3C28.2,28.8,26.9,28.4,25.8,27.8z
	 M42.1,32.4c-1.2-0.1-1.9-0.1-3.3-0.1c-1.5-0.6-3-1.2-4.5-1.7c0.6-0.4,1.1-0.8,1.7-1.1C38.1,30.1,40.1,31.2,42.1,32.4L42.1,32.4z
	 M35.3,28.9c-0.5-0.2-0.9-0.3-1.4-0.5c0.5-0.1,1.1-0.2,1.6-0.3c0.4,0.2,0.8,0.3,1.2,0.5C36.3,28.7,35.8,28.8,35.3,28.9L35.3,28.9z
	 M44.3,32.6c-0.7-0.4-1.4-0.9-2.1-1.3c-1.5-1-3-1.9-4.6-2.6l-0.4-0.2l0.1-0.1l-0.2,0.1c-0.4-0.2-0.8-0.3-1.2-0.5
	c1.1-0.3,2.2-0.5,3.3-0.8c0.7,0.4,1.4,0.8,2.1,1.2c0.9,0.6,1.7,1.3,2.6,1.9c1.3,1,2.7,2,4.1,2.9C46.7,33,45.5,32.8,44.3,32.6
	L44.3,32.6z"
      />
    </SvgIcon>
  );
};

export default Icon;
