/* eslint-disable react/display-name */
import React, { useState, forwardRef, useContext, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse, colors, Avatar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'App';
import AuthImage from 'components/AuthImage';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const NavigationListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    disabled,
    pic,
    drawerCollapsed,
    ...rest
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const [session: authContext] = useContext(AuthContext);
  const [resetCounter, setResetCounter] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setResetCounter(new Date());
  }, [session]);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft: drawerCollapsed ? 16 : paddingLeft,
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
      >
        <Button
          disabled={disabled}
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          {pic && (
            <Avatar
              key={session?.picture}
              alt="Person"
              className={classes.icon}
              style={{width: 24, height: 24}}
              children={
                <AuthImage
                  src={
                    session?.picture
                      ? `${session.picture}?timestamp=${resetCounter}`
                      : '/images/empty-avatar.png'
                  }
                />
              }
            />
          )}
          {Icon && <Icon className={classes.icon} />}
          {!drawerCollapsed ? t(`page:navigation.${title}`) : null}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
      >
        <Button
          disabled={disabled}
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={CustomRouterLink}
          exact
          style={style}
          to={href}
        >
          {pic ? (
            <Avatar
              key={session?.logo}
              alt="Person"
              className={classes.icon}
              style={{width: 24, height: 24}}
              children={
                <AuthImage
                  src={
                    session?.picture
                      ? `${session.picture}?timestamp=${resetCounter}`
                      : '/images/empty-avatar.png'
                  }
                />
              }
            />
          ) : (
            Icon && <Icon className={classes.icon} />
          )}
          {!drawerCollapsed && <>
          {!pic ? t(`page:navigation.${title}`) : `${session?.firstName} ${session?.lastName}`}
          </>}
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false,
};

export default NavigationListItem;
