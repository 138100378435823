import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M49.5,17c-0.1-0.6-0.2-0.9-0.5-1.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
		l-0.5-0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.7-0.4l-2.2-1.2c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.4-0.2-0.5-0.3
		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.3-0.8-0.5c-0.3-0.2-0.6-0.3-0.9-0.5l-3.3-1.8
		C36.2,9,36.1,9,35.9,8.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1
		C34.3,8,34.1,7.9,34,7.8c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.8-0.4-1.1-0.6l-0.6-0.3c-0.1-0.1-0.3-0.2-0.4-0.2L31,6.3
		c-0.1-0.1-0.3-0.2-0.4-0.2l-0.4-0.2c-0.2-0.1-0.3-0.2-0.4-0.2l-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.6-0.3
		l-0.9-0.5C27.2,4.1,27,4,26.8,3.9c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.5-0.3-1-0.4-1.6-0.1L24,3.5
		c-0.5,0.3-1,0.6-1.5,0.8L21,5.1c-0.6,0.3-1.2,0.6-1.8,1l-5.5,3c-0.6,0.3-1.3,0.7-1.9,1c-0.7,0.4-1.4,0.7-2.1,1.1
		c-0.8,0.4-1.5,0.8-2.3,1.3l-1.7,0.9c-1.1,0.6-2.1,1.2-3.2,1.7c-0.4,0.2-0.7,0.4-1.1,0.6L0.8,16c-0.2,0.1-0.3,0.3-0.3,0.6v1.3
		c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.1,0.3,0.2l2.5,1.3C4,20,4.4,20.3,4.8,20.5l1.5,0.8c0.2,0.1,0.4,0.2,0.6,0.3
		c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0,0,0,0l-0.2,0.2c-0.2,0.2-0.3,0.5-0.2,0.8c0.1,0.2,0.2,0.3,0.4,0.4L8,23.6
		c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.7,0.3l0.5,0.2c0.2,0.1,0.4,0.2,0.6,0.3
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.2,0.8,0.3l0.6,0.2
		c0.2,0.1,0.3,0.1,0.5,0.2c0.5,0.2,0.9,0.3,1.3,0.2c0,0,0,0,0.1,0c0.2,0.1,0.3,0.2,0.5,0.2l0.1,0.1c0.1,0,0.1,0.1,0.2,0.1
		c0,0,0,0,0.1,0c0.2,0.4,0.6,0.5,0.8,0.6c0,0,0.1,0,0.1,0.1c0.2,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.3,0.1,0.5,0.2
		c0.2,0.1,0.4,0.2,0.7,0.3l3.6,1.9c0.3,0.1,0.5,0.3,0.8,0.4c0.6,0.4,1.2,0.5,1.7,0.2c0.1,0,0.1,0,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1
		c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0,0.5-0.1,0.8-0.3c0.3-0.3,0.7-0.6,1-0.9c0.3-0.3,0.6-0.6,1-0.9L30,29c0.3-0.3,0.6-0.6,0.9-0.7
		c1.1-0.6,2.1-1.2,3.2-1.7l0.2-0.1c0.2,0.3,0.5,0.4,0.6,0.4L35,27c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0.1,0.4,0.2,0.5,0.2
		c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.2,0.7,0.4l3.5,1.9c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.3,0.8,0.3,1.3,0.1l2.3-1.2
		c1.1-0.6,2.3-1.2,3.4-1.8c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.2-0.5,0.2-0.7c0-0.3-0.2-0.5-0.5-0.6c-0.2-0.1-0.5-0.3-0.7-0.4
		L47,24.7c-0.2-0.1-0.4-0.2-0.7-0.3l-0.5-0.3c-0.3-0.1-0.5-0.3-0.8-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.1-0.5-0.2
		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-1-0.5c-0.1,0-0.2-0.1-0.3-0.1c1.9-1,3.7-2,5.6-3l1.2-0.6c0.1,0,0.2-0.1,0.3-0.2
		C49.3,18.2,49.6,18,49.5,17z M25.4,28.3c-0.4,0.2-0.4,0.2-0.6,0.1c-0.4-0.3-0.8-0.5-1.2-0.7c-0.2-0.1-0.3-0.2-0.5-0.2l-0.3-0.1
		c-0.3-0.2-0.7-0.4-1-0.5L21,26.3c-0.4-0.2-0.9-0.5-1.4-0.7c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.5-0.3-0.9-0.3c0,0,0,0-0.1,0l-1.3-0.8
		c-0.3-0.2-0.6-0.4-0.9-0.5c-0.4-0.2-0.7-0.4-1.1-0.6c-0.2-0.1-0.5-0.2-0.7-0.4l-0.7-0.4c-0.1-0.1-0.3-0.2-0.4-0.2l-1.8-1
		c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.4-0.2-0.7-0.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.2-0.1-0.2-0.1l-0.7-0.4
		c-0.3-0.2-0.6-0.3-0.9-0.5c-0.2-0.1-0.3-0.2-0.5-0.3C7.2,19,7,18.9,6.8,18.8c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.1-0.4-0.2
		L5.3,18c-0.3-0.2-0.6-0.3-0.9-0.5c-0.2-0.1-0.4-0.2-0.6-0.3L23.5,6.6c0.5-0.2,1-0.5,1.4-0.8c0.2-0.1,0.2-0.1,0.4,0
		c0.3,0.2,0.6,0.4,1,0.5c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.6,0.3,0.9,0.5l0.8,0.5c0.1,0.1,0.3,0.1,0.4,0.2L29.1,8
		c0.2,0.1,0.3,0.1,0.5,0.2c0.6,0.3,1.2,0.6,1.8,1l0.8,0.5c0.4,0.2,0.7,0.4,1.1,0.6c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.1,0.4,0.2,0.6,0.3
		c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.5,0.3
		c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.5,0.3l1.1,0.6c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0.2,0.6,0.3l1.9,1
		c0.3,0.2,0.6,0.3,0.9,0.5l0.9,0.5c0.4,0.2,0.8,0.4,1.1,0.6c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.2,0.1,0.3,0.1l-1.2,0.6
		c-0.6,0.3-1.3,0.7-1.9,1l-2.7,1.5c-1,0.5-1.9,1.1-2.9,1.6c-0.7,0.4-1.5,0.8-2.2,1.2l-2.5,1.4c-0.5,0.3-1.1,0.6-1.6,0.9
		c-0.3,0.2-0.7,0.3-1,0.5c-0.4,0.2-0.7,0.4-1.1,0.5C27.9,26.9,26.6,27.6,25.4,28.3z"
        />
        <path
          className="st0"
          d="M48.1,32.6c-0.2-0.1-0.4-0.3-0.7-0.4L45,30.9c-0.1-0.1-0.3-0.2-0.4-0.2l-0.8-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
		c-0.3-0.1-0.7,0-0.9,0.1c-0.2,0.1-0.3,0.3-0.4,0.5c-0.3,0.3-0.3,0.6-0.2,0.8c0,0.2,0.1,0.5,0.5,0.7c0.1,0.1,0.2,0.1,0.3,0.2
		l2.3,1.3c-1,0.5-2,1.1-2.9,1.6l-6.2,3.3c-0.5,0.2-0.9,0.5-1.4,0.7L33,39.9c-0.5,0.3-1.1,0.6-1.6,0.9c-0.9,0.5-1.8,0.9-2.6,1.4
		l-2.5,1.3c-0.4,0.2-0.9,0.5-1.3,0.7l-1.6-0.9c-0.1-0.1-0.3-0.1-0.4-0.2L22.7,43c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3l-0.4-0.2c-0.4-0.2-0.7-0.4-1.1-0.6c-0.3-0.2-0.6-0.3-0.8-0.5l-0.4-0.2
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.3-0.7-0.4
		c-0.2-0.1-0.5-0.2-0.7-0.4L14,38.3c-0.2-0.1-0.4-0.2-0.6-0.3l-1.1-0.6c-0.1-0.1-0.3-0.1-0.4-0.2l-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
		l-1.7-0.9c-0.2-0.1-0.4-0.2-0.6-0.3l-0.5-0.3c-0.3-0.1-0.5-0.3-0.8-0.4l-0.5-0.3c-0.1-0.1-0.3-0.1-0.4-0.2L6.1,34
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c0.3-0.2,0.6-0.3,0.9-0.5c0.5-0.3,1-0.5,1.5-0.8c0.3-0.1,0.4-0.3,0.7-0.6
		c0.2-0.2,0.3-0.5,0.2-0.9c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.4-0.6-0.7-0.7c-0.2-0.1-0.5-0.1-0.7,0l-0.8,0.4c-1.2,0.7-2.4,1.3-3.7,2
		c-0.3,0.1-0.5,0.3-0.7,0.5c-0.3,0.3-0.4,0.6-0.4,1c0,0.3,0.2,0.6,0.6,0.8c0.2,0.1,0.4,0.2,0.6,0.3l0.3,0.1c0.4,0.2,0.9,0.5,1.3,0.7
		l1.5,0.8c0.2,0.1,0.4,0.2,0.5,0.3l0.6,0.3c0.2,0.1,0.5,0.3,0.7,0.4c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0.1,0.5,0.2,0.7,0.4
		c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.5,0.2,0.8,0.4c0.4,0.2,0.8,0.4,1.2,0.6
		l0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.2l0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.5,0.3,0.7,0.4
		c0.2,0.1,0.5,0.3,0.7,0.4l0.5,0.3c0.4,0.2,0.8,0.4,1.3,0.7l2.8,1.5c0.2,0.1,0.4,0.2,0.5,0.3l1.4,0.7c0.1,0.1,0.3,0.2,0.4,0.2
		c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.7,0.3c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.2,0.9-0.3c1.4-0.8,2.9-1.6,4.3-2.3l2.7-1.4
		c1.3-0.7,2.5-1.3,3.8-2c0.7-0.4,1.4-0.8,2.1-1.1c0.6-0.3,1.2-0.7,1.8-1l2.8-1.5c1.4-0.7,2.8-1.5,4.1-2.2c0.3-0.1,0.5-0.3,0.7-0.6
		c0.1-0.1,0.4-0.4,0.4-0.9C48.6,32.9,48.2,32.6,48.1,32.6z"
        />
        <path
          className="st0"
          d="M10.1,30.7c0.3-0.2,0.6-0.4,0.9-0.6c1-0.5,2-1.1,3-1.6c0.5-0.3,1-0.6,1.5-0.8c0.2-0.1,0.4-0.1,0.6,0
		c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0.1,0.5,0.2,0.7,0.3
		c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.3,0.9,0.4c0.2,0.1,0.3,0.1,0.4,0.2c0.3,0.1,0.5,0.2,0.8,0.4c0.2,0.1,0.4,0.2,0.5,0.2
		c0.4,0.2,0.8,0.3,1.3,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.3,0.2,0.2,0.3c-0.3,0.3-0.5,0.7-0.9,1
		c-1.2,1.1-2.4,2.1-3.6,3.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.3,0.1-0.4,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.3-0.7-0.4
		c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.2-0.6-0.3-0.9-0.5c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.2-0.7-0.4-1-0.6c-0.3-0.2-0.6-0.3-0.9-0.5
		c-0.4-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-0.8-0.4-1.2-0.7c-0.4-0.2-0.8-0.4-1.2-0.6c-0.3-0.2-0.6-0.3-0.9-0.5
		C10.3,30.8,10.2,30.8,10.1,30.7z"
        />
        <path
          className="st0"
          d="M39.9,30.7c-0.3,0.2-0.5,0.4-0.8,0.5c-1.4,0.8-2.8,1.5-4.2,2.2c-0.1,0.1-0.3,0.2-0.4,0.3
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.1-0.7-0.3-1-0.4c-0.4-0.2-0.9-0.3-1.3-0.5
		c-0.1-0.1-0.3-0.1-0.4-0.2c-0.4-0.2-0.7-0.3-1.1-0.4c-0.2-0.1-0.1-0.2,0-0.3c0.3-0.3,0.6-0.6,0.9-0.9c0.9-0.8,1.8-1.7,2.8-2.5
		c0.2-0.2,0.5-0.4,0.7-0.5c0.1-0.1,0.3-0.1,0.5,0c0.2,0,0.3,0.2,0.5,0.2c0.3,0.2,0.7,0.4,1,0.6c0.3,0.2,0.7,0.4,1,0.6
		c0.4,0.2,0.9,0.5,1.3,0.7c0.3,0.2,0.7,0.4,1,0.6c0.2,0.1,0.4,0.2,0.6,0.3C39.6,30.4,39.7,30.6,39.9,30.7z"
        />
        <path
          className="st0"
          d="M6.3,23.7c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.8,0.4
		C9.1,24.9,9.3,25,9.5,25c0.3,0.1,0.6,0.2,0.9,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.5,0.2,0.8,0.3
		c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.2,0.2,0,0.4c-0.5,0.5-1.1,0.7-1.6,1c-0.9,0.5-1.8,1-2.8,1.5c-0.2,0.1-0.5,0.2-0.7,0.3
		c-0.1,0-0.1,0-0.2,0c-0.5-0.2-0.9-0.5-1.4-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.5-0.3-0.9-0.5-1.4-0.8
		c-0.4-0.2-0.7-0.4-1.1-0.5c-0.3-0.1-0.5-0.3-0.8-0.5c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0.1-0.2,0.1-0.2
		c0.3-0.2,0.6-0.4,1-0.6c1-0.5,1.9-1,2.9-1.6C6,23.8,6.1,23.8,6.3,23.7z"
        />
        <path
          className="st0"
          d="M25.1,38.8c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.3-1.3-0.7-1.9-1c-0.3-0.2-0.3-0.3-0.1-0.5c0.6-0.6,1.2-1.1,1.8-1.7
		c0.8-0.7,1.5-1.4,2.3-2.1c0.2-0.2,0.5-0.4,0.8-0.6c0,0,0.1-0.1,0.2-0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.1,0.5,0.2
		c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.6,0.2,1,0.4c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0.1,0.1,0.2,0,0.3
		c-0.6,0.3-1.2,0.7-1.8,1c-1.1,0.6-2.1,1.2-3.2,1.7c-0.6,0.3-1.3,0.7-1.9,1C25.3,38.7,25.2,38.7,25.1,38.8z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
