import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M26.4,49.5c-1,0-1.9,0-2.9,0c-0.2,0-0.3-0.1-0.5-0.1c-0.7-0.1-1.5-0.1-2.2-0.3c-1.6-0.3-2.6-1.3-2.8-2.9
				c-0.2-1.3-0.3-2.5-0.5-3.8c0-0.4-0.1-0.6-0.5-0.8c-0.9-0.4-1.7-0.9-2.5-1.4c-0.3-0.2-0.5-0.2-0.9-0.1c-1.1,0.5-2.1,0.9-3.2,1.4
				c-1.7,0.7-3.3,0.3-4.4-1c-1.7-2.1-3-4.3-4-6.8c-0.7-1.8-0.2-3.3,1.3-4.4c0.9-0.7,1.9-1.4,2.8-2.1c0.3-0.2,0.4-0.4,0.4-0.8
				c-0.1-1-0.1-1.9,0-2.9c0-0.4-0.1-0.6-0.4-0.8c-1-0.7-1.9-1.4-2.8-2.1c-1.5-1.1-1.9-2.6-1.3-4.4C3,13.9,4.3,11.6,6,9.6
				c1.3-1.5,2.8-1.9,4.6-1.1c1.1,0.5,2.1,0.9,3.2,1.4c0.2,0.1,0.4,0,0.6-0.1c0.9-0.5,1.8-1,2.7-1.6c0.1-0.1,0.3-0.3,0.3-0.4
				c0.2-1.2,0.3-2.3,0.4-3.5c0.2-2.1,1.4-3.3,3.4-3.5c0.7-0.1,1.5-0.2,2.2-0.3c1,0,1.9,0,2.9,0c0.2,0,0.4,0.1,0.6,0.1
				c0.7,0.1,1.5,0.1,2.2,0.3c1.5,0.3,2.5,1.4,2.8,2.9c0.2,1.3,0.3,2.6,0.5,3.9c0,0.3,0.1,0.5,0.4,0.6c0.9,0.5,1.8,1,2.6,1.5
				c0.2,0.2,0.4,0.2,0.7,0.1c1.2-0.5,2.4-1.1,3.6-1.5c1.5-0.6,3.1-0.2,4.1,1.1c1.7,2.1,3,4.3,4,6.8c0.7,1.7,0.2,3.3-1.3,4.4
				c-1,0.7-2,1.5-2.9,2.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,1,0,2.1,0,3.1c0,0.3,0,0.4,0.3,0.6c1,0.7,2,1.4,2.9,2.2c1.6,1.2,2,2.7,1.3,4.5
				c-0.9,2.4-2.3,4.7-3.9,6.7c-1.2,1.4-2.7,1.8-4.5,1.1c-1.1-0.5-2.2-1-3.3-1.4c-0.2-0.1-0.4,0-0.6,0c-0.9,0.5-1.8,1-2.7,1.6
				c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,1-0.3,2.1-0.4,3.1c-0.2,2.4-1.3,3.5-3.6,3.8C27.8,49.3,27.1,49.4,26.4,49.5z M44,32.4
				c-0.1-0.1-0.3-0.2-0.4-0.3c-1.4-1.1-2.9-2.1-4.3-3.2C39,28.8,39,28.6,39,28.3c0.5-2.2,0.5-4.5,0-6.7c-0.1-0.3,0-0.4,0.2-0.6
				c1.3-0.9,2.6-1.9,3.8-2.9c0.9-0.7,0.9-0.7,0.4-1.7c-0.7-1.5-1.6-2.9-2.6-4.2c-1.8,0.8-3.5,1.5-5.3,2.3c-0.3,0.1-0.6,0.2-0.8-0.1
				c-1.7-1.5-3.5-2.6-5.7-3.3c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.5-0.1-1-0.2-1.5c-0.2-1.4-0.3-2.9-0.5-4.3c-2-0.3-4-0.3-6.1,0
				c-0.2,1.9-0.5,3.9-0.7,5.8c0,0.3-0.1,0.5-0.5,0.6c-2.1,0.7-4.1,1.8-5.7,3.3c-0.2,0.2-0.4,0.2-0.7,0.1c-1.6-0.7-3.2-1.4-4.9-2.1
				c-0.3-0.1-0.5-0.1-0.8,0.2c-1.1,1.4-1.9,2.9-2.6,4.5c-0.1,0.3-0.1,0.5,0.2,0.7c1.5,1.1,2.9,2.2,4.4,3.2c0.2,0.2,0.3,0.3,0.2,0.6
				c-0.5,2.2-0.5,4.4,0,6.6c0.1,0.3,0,0.5-0.3,0.7c-1.5,1.1-2.9,2.1-4.3,3.2c-0.1,0.1-0.3,0.4-0.2,0.5c0.7,1.8,1.7,3.5,3,5.1
				c0.1-0.1,0.3-0.1,0.4-0.2c1.6-0.7,3.3-1.4,4.9-2.1c0.3-0.1,0.5-0.1,0.8,0.1c0.4,0.4,0.8,0.7,1.2,1c1.3,1,2.8,1.7,4.4,2.2
				c0.3,0.1,0.5,0.2,0.5,0.6c0.1,0.8,0.2,1.6,0.3,2.3c0.1,1.1,0.3,2.3,0.4,3.4c2,0.3,4.1,0.3,6.1,0c0-0.2,0.1-0.3,0.1-0.4
				c0.2-1.8,0.4-3.5,0.6-5.3c0-0.2,0.1-0.5,0.3-0.5c2.2-0.7,4.2-1.8,5.9-3.4c0.1-0.1,0.4-0.1,0.6-0.1c0.7,0.3,1.4,0.6,2.1,0.9
				c1.1,0.5,2.2,0.9,3.3,1.4C41.9,36.6,43.5,34,44,32.4z"
          />
          <path
            className="st0"
            d="M33.1,25c0,4.5-3.7,8.2-8.2,8.2c-4.5,0-8.1-3.6-8.1-8.2c0-4.5,3.6-8.2,8.2-8.2C29.5,16.9,33.1,20.5,33.1,25z
				 M25,20.9c-2.2,0-4.1,1.8-4.1,4.1c0,2.2,1.8,4.1,4.1,4.1c2.2,0,4.1-1.8,4.1-4.1C29.1,22.8,27.2,20.9,25,20.9z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
