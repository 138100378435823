import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M43.3,44.6C43.3,44.6,43.3,44.6,43.3,44.6l-4.9-12.2c-2-5-4-10.1-6-15.1c0-0.1-0.1-0.2-0.1-0.3
				c0-4.1,0-8.2,0-12.3c0.4-0.1,0.7-0.4,1-0.7c0.5-0.7,0.6-1.5,0.3-2.3c-0.4-0.8-1.1-1.2-2-1.2l-11.9,0c-0.5,0-0.9,0-1.4,0
				c-1.1,0-2,0.8-2.1,1.9c-0.1,1.1,0.4,1.9,1.5,2.3v0.8c0,3.9,0,7.7,0,11.6c0,0,0,0.1-0.1,0.2c-1.2,3.1-2.5,6.2-3.7,9.3L12.2,31
				c-1.8,4.6-3.7,9.1-5.5,13.7C6.2,45.9,6.4,47,7.3,48c0.9,1,2.1,1.5,3.5,1.5l13.8,0l14.7,0c1.3,0,2.4-0.5,3.3-1.4
				C43.6,47.1,43.8,46,43.3,44.6z M16.5,44.3c-0.3-0.4-0.8-0.6-1.3-0.6c-0.5,0-1,0.2-1.3,0.5c-0.4,0.4-0.6,0.8-0.6,1.3
				c0,0.5,0.2,1,0.5,1.3c0.1,0.1,0.2,0.2,0.4,0.3c-1.1,0-2.2,0-3.3,0c-0.7,0-1.3-0.2-1.8-0.7c-0.4-0.4-0.4-0.7-0.3-1.1
				c0.6-1.6,1.3-3.1,1.9-4.7l0.1-0.3c0.3-0.8,0.6-1.6,0.9-2.3c1.8-0.3,3.4-0.4,4.9,0c0.5,0.1,1.1,0.3,1.6,0.5l0.5,0.2
				c1.4,0.5,2.8,0.4,4.2-0.1c0.9-0.3,1.8-0.6,2.9-0.8c1-0.2,2,0,2.8,0.3l0.4,0.1c1.2,0.4,2.4,0.7,3.6,1.1c0.6,0.2,1.2,0.2,1.8,0.1
				c-0.9,0.2-1.6,1-1.6,2c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0,0,0,0,0,0c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.5
				c0-1.1-0.9-2-2-2c0.5-0.1,0.9-0.2,1.3-0.3c0.5-0.1,0.9-0.3,1.4-0.4l0.7-0.2c0.9,2.4,1.9,4.8,2.9,7.1c0.1,0.2,0.1,0.4-0.1,0.7
				c-0.5,0.5-1,0.8-1.7,0.9c-0.4,0-0.7,0-1.1,0l-16.8,0c-1.2,0-2.5,0-3.7,0c-0.5,0-1.1,0-1.6,0c0.5-0.3,0.9-0.9,0.9-1.6
				C17.1,45.1,16.9,44.6,16.5,44.3z M27.5,32.6c1.2-0.9,2.3-1.2,3.5-0.9c0.5,0.1,1,0.4,1.5,0.8c-0.2,0.3-0.4,0.5-0.8,0.4
				c-0.6,0-1.3-0.1-1.9-0.2l-0.4-0.1C28.7,32.7,28.1,32.6,27.5,32.6z M23.1,29c-0.9-0.3-1.9-0.6-2.8-0.8l-0.7-0.2
				c-0.2-0.1-0.4-0.1-0.7-0.2c-0.3-0.1-0.5-0.4-0.5-0.7c1.2-0.5,2.4-0.3,3.4,0.5C22.4,28,22.8,28.5,23.1,29z M35.3,37
				c-0.9,0.3-1.6,0.3-2.4,0l-0.7-0.2c-1.1-0.3-2.2-0.7-3.3-1c-0.7-0.2-1.4-0.3-2.2-0.3c-0.2,0-0.5,0-0.7,0v-0.5c0,0,0-0.1,0-0.1
				c0,0,0.1,0,0.1,0c1.3-0.2,2.5,0,4,0.2c0.6,0.1,1.1,0.1,1.6,0.1c1.5,0,2.5-0.7,3-2.1c0.4-1.1,0.3-1.4-0.5-2.2
				c-2.3-2.2-5.6-2.2-8.2-0.1c-0.1-1.9-1-3.5-2.6-4.9c-1.4-1.1-2.9-1.6-4.5-1.3c-0.6,0.1-1.2,0.3-1.7,0.5l-0.3,0.1c0,0,0,0,0,0
				l0.1-0.2c0.9-2.4,1.9-4.7,2.8-7.1c0,0,0,0,0,0c0.1-0.3,0.2-0.6,0.2-0.9c0-3.3,0-6.7,0-10V4.9h10.1l0,3.2c0,3,0,6.1,0,9.1
				c0,0.3,0.1,0.6,0.2,0.9l7.3,18.3C36.8,36.6,36.1,36.8,35.3,37z M18.5,30.1c0.5,0.2,1,0.3,1.6,0.4c0.5,0.1,1.1,0.3,1.6,0.4
				c0.5,0.2,1,0.4,1.5,0.6l0.1,0c0.2,0.1,0.3,0.1,0.5,0.2l0,0.8c0,1.1,0,2.3,0,3.5c-0.4,0.1-0.9,0.3-1.5,0.5c-1,0.4-1.9,0.4-2.9,0.1
				l-1.6-0.5c-1-0.3-2.1-0.5-3.4-0.5c-0.5,0-1.1,0-1.7,0.1l3.4-8.5C16.2,28.7,17,29.7,18.5,30.1z"
          />
          <path
            className="st0"
            d="M28.1,5.2c-0.3,0-0.5,0.3-0.6,0.5c-0.1,0.1-0.1,0.3-0.1,0.5l0,9.1c0,0.1,0,0.2,0,0.3
				c0.1,0.4,0.4,0.6,0.8,0.6c0,0,0,0,0,0c0.4,0,0.7-0.2,0.8-0.6c0-0.1,0-0.2,0-0.3l0-9.2c0-0.1,0-0.3-0.1-0.5
				C28.6,5.4,28.3,5.2,28.1,5.2z"
          />
          <path
            className="st0"
            d="M26.4,42.3C26.4,42.3,26.4,42.3,26.4,42.3c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.2-2.1-2.2c0,0,0,0,0,0
				c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,1-0.6,1.5C24.3,41.3,25.2,42.3,26.4,42.3z"
          />
          <path
            className="st0"
            d="M25.2,46.6C25.3,46.6,25.3,46.6,25.2,46.6c1.1,0,2-0.9,2-2c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6
				c0,0,0,0,0,0c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1,0.6,1.4C24.2,46.4,24.7,46.6,25.2,46.6z"
          />
          <path
            className="st0"
            d="M31.2,46.5C31.2,46.5,31.2,46.5,31.2,46.5c1.1,0,1.9-0.9,1.9-1.9c0-0.5-0.2-1-0.6-1.4
				c-0.4-0.4-0.8-0.6-1.4-0.6c0,0,0,0,0,0c-1,0-1.9,0.9-1.9,1.9C29.2,45.6,30.1,46.5,31.2,46.5z"
          />
          <path
            className="st0"
            d="M13.6,38.8C13.6,38.8,13.6,38.8,13.6,38.8c-0.5,0-1,0.2-1.3,0.5c-0.4,0.4-0.6,0.8-0.6,1.3
				c0,1.1,0.8,1.9,1.9,1.9c0,0,0,0,0,0c0.5,0,1-0.2,1.3-0.5c0.4-0.4,0.6-0.8,0.6-1.3v0C15.4,39.6,14.6,38.8,13.6,38.8z"
          />
          <path
            className="st0"
            d="M38.6,47.2c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.3v0c0-1-0.8-1.8-1.8-1.8c0,0,0,0,0,0
				c-0.5,0-0.9,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3C36.8,46.4,37.6,47.2,38.6,47.2z"
          />
          <path
            className="st0"
            d="M19.5,44c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.9-0.7-1.6-1.7-1.7c0,0,0,0,0,0
				c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2v0C17.9,43.3,18.6,44,19.5,44z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
