import React from "react";
import ObstacleIcon from "components/Navigation/icons/ObstalceIcon";

const Legend = ({ legendData, bottom, isRxMap }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: bottom || "50px",
        left: "20px",
        backgroundColor: "white",
        padding: "10px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        zIndex: 1000
      }}
    >
      <ul style={{ listStyle: "none", padding: 0 }}>
        {legendData.map((item, index) => (
          <li key={index} style={{ marginBottom: "5px" }}>
            {item.color === "obstacle" ? (
              <ObstacleIcon />
            ) : (
              <span
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  backgroundColor: item.color,
                  marginRight: "5px"
                }}
              ></span>
            )}
            {`${item.size} Ha`}
            {isRxMap && (
              <>
                {item.fertilizerType !== "N/A" && item.kgPerH !== "N/A" && (
                  <span>
                    | <strong>Fertilizer type:</strong> {item.fertilizerType} |{" "}
                    <strong>Kg/Ha:</strong> {item.kgPerH}
                  </span>
                )}
                {item.numberOfSeeds !== "N/A" && item.seedBrand !== "N/A" && (
                  <span>
                    | <strong>Number of seeds:</strong> {item.numberOfSeeds} |{" "}
                    <strong>Seed brand:</strong> {item.seedBrand}
                  </span>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Legend;
