import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import {makeStyles} from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Avatar, colors,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import StoreIcon from '@material-ui/icons/Store';
import gradients from 'utils/gradients';
import {useDispatch, useSelector} from 'react-redux';
import {
  addErrorMessage,
  addSuccessMessage,
  refreshUserProfile,
  setActiveOrganization,
} from 'actions';
import {PageLoaderContext} from 'context/Page';
import ConfirmDialog from 'components/Dialog';
import {useTranslation} from 'react-i18next';
import {
  getDefaultOrganization,
  getOrganizationDomain,
  organizationHasDomain,
} from 'utils/user';
import OrganizationService from 'services/organization.service';
import {isAccepted} from 'utils/request-response';
import {logger} from 'utils/logger';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },

  },
  activeOrganization: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  avatarGreen: {
    backgroundImage: gradients.green,
  },
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: colors.grey[600],
    '&:hover': {
      backgroundColor: colors.grey[900],
    },
  },
}));

const OrganizationsList = props => {
  const {organizations, className, ...rest} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const classes = useStyles();

  const user = useSelector(state => state.session.user);
  const {activeOrganization} = user;
  const {setPageLoading} = useContext(PageLoaderContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState(undefined);

  const avatars = {
    project: (
      <Avatar className={classes.avatarGreen}>
        <StoreIcon/>
      </Avatar>
    ),
  };

  const handleChangeOrganization = async(organization) => {
    if (!_.isEqual(organization.id, activeOrganization.id)) {
      setPageLoading(true);
      dispatch(setActiveOrganization(organization));
      setPageLoading(false);
    }
  };

  const handleDelete = async(event) => {
    event.preventDefault();
    alert('delete');
    if (organizationHasDomain(organizationToDelete) && false) {
      try {
        setPageLoading(true);
        const organizationService = new OrganizationService();
        const domain = getOrganizationDomain(organizationToDelete);
        organizationService.setDomain(domain);
        const organizationUpdateResponse = await organizationService.deleteOrganization();
        if (isAccepted(organizationUpdateResponse)) {
          const defaultOrganization = getDefaultOrganization(organizations);
          dispatch(setActiveOrganization(defaultOrganization));
          dispatch(refreshUserProfile());
          dispatch(addSuccessMessage(t('form:organization.organizationGeneralSettings.delete.success')));
        } else {
          dispatch(addErrorMessage(t('form:organization.organizationGeneralSettings.delete.error')));
        }
        setPageLoading(false);
      } catch (e) {
        logger.error(e);
        dispatch(addErrorMessage(t('form:organization.organizationGeneralSettings.delete.error')));
        setPageLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <List
        {...rest}
        className={clsx(classes.root, className)}
        disablePadding
      >
        {organizations.map((organization, i) => (
          <ListItem
            className={`${classes.listItem} ${activeOrganization.id === organization.id ? classes.activeOrganization : ''}`}
            divider={i < organizations.length - 1}
            key={organization.id}
            onClick={() => handleChangeOrganization(organization)}
          >
            <ListItemAvatar>{avatars.project}</ListItemAvatar>
            <ListItemText
              primary={organization.name}
              primaryTypographyProps={{variant: 'body1'}}
              secondary={organization.long_name || ''}
            />
            <IconButton
              title={t('form:delete')}
              className={classes.deleteButton}
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => {
                setOrganizationToDelete(organization);
                setIsDeleteModalOpen(true);
              }}
            >
              <DeleteIcon/>
            </IconButton>
          </ListItem>
        ))}
      </List>
      {organizationToDelete && <ConfirmDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title={t('form:areYouSure')}
        description={t('form:organization.organizationGeneralSettings.delete.modalDescription', {
          organizationName: organizationToDelete && organizationToDelete.name ? organizationToDelete.name : '',
        })}
        onYesClick={(event) => handleDelete(event)}
        onNoClick={() => setIsDeleteModalOpen(false)}
      />}
    </React.Fragment>
  );
};

OrganizationsList.propTypes = {
  className: PropTypes.string,
  organizations: PropTypes.array.isRequired,
};

export default OrganizationsList;
