import {RestService} from './rest.service';

class SeasonService extends RestService {

  /**
   * Get all seasons
   *
   * @return {Promise<* | void>}
   */
  getSeasons() {
    return super.get('organization/seasons');
  }

  /**
   * Get season
   *
   * @param seasonId
   * @return {Promise<* | void>}
   */
  getSeason(seasonId) {
    return super.get(`organization/seasons/${seasonId}`);
  }

  /**
   * Create season
   *
   * @param data
   * @return {Promise<* | void>}
   */
  createSeason(data) {
    return super.post('organization/seasons', data);
  }

  /**
   * Update season
   *
   * @param seasonId
   * @param data
   * @return {Promise<* | void>}
   */
  updateSeason(seasonId, data) {
    return super.put(`organization/seasons/${seasonId}`, data);
  }

  /**
   * Delete season
   * @param {uuid} seasonId
   * @return {Promise<* | void>}
   */
  deleteSeason(seasonId) {
    return super.destroy(`organization/seasons/${seasonId}`);
  }

}

export default SeasonService;
