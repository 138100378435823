import React from 'react';
import MomentUtils from '@date-io/moment';
import clsx from 'clsx';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {getLocale} from 'utils/locale';
import {useTranslation} from 'react-i18next';
import {getFormat} from 'utils/date';

const locale = getLocale();
moment.locale(locale);

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginBottom: '20px',
  },
  helperText: {
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

const DateField = (props) => {

  const {
    name,
    label,
    onChange,
    value,
    required,
    error,
    className,
    disabled,
    ...rest
  } = props;

  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <MuiPickersUtilsProvider
      locale={locale}
      utils={MomentUtils}
      libInstance={moment}
    >
      <DatePicker
        {...rest}
        className={clsx(classes.datePicker, className)}
        label={label}
        name={name}
        TextFieldComponent={
          (props) => (
            <TextField
              {...props}
              error={error}
              required={required}
              variant="outlined"
              disabled={disabled}
            />
          )
        }
        onChange={onChange}
        value={value}
        variant="outlined"
        format={getFormat()}
        autoOk={true}
        okLabel={t('form:ok')}
        clearLabel={t('form:clear')}
        cancelLabel={t('form:cancel')}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};


DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string,
};

DateField.defaultProps = {
  disabled: false,
  required: false,
  error: false,
};


export default DateField;
