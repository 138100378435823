import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/es-us';

export const FORMAT_DMY = 'DD.MM.YYYY';
export const FORMAT_YMD = 'MM/DD/YYYY';

/**
 * Get formatted date
 *
 * @param date
 * @return {string}
 */
export function getFormattedDate(date) {
  let result = '';
  if (_.isString(date)) {
    result = moment(date).format(getFormat());
  } else if (moment.isMoment(date)) {
    result = date.format(getFormat());
  }
  return result;
}

/**
 * Get format for date
 *
 * @return {string}
 */
export function getFormat() {
  return FORMAT_DMY;
}
