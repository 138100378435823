import React, { useState } from "react";
import { Modal, Box, Button, Typography, TextField } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { uploadExcelFIle } from "endpoints/labData";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "material-react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    minWidth: 800
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  fileInput: {
    display: "none"
  },
  dropArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px dashed ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    height: 200,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100]
    }
  },
  dropAreaActive: {
    backgroundColor: theme.palette.grey[200]
  },
  dropAreaIcon: {
    fontSize: 60,
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(1)
  },
  dropAreaText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[400]
  },
  dropAreaContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  browseButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  fileNames: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[800]
  },
  dialogActions: {
    justifyContent: "space-between",
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
    marginBottom: theme.spacing(5)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const FileItem = ({ file, onRemove }) => {
  const handleRemove = () => {
    onRemove(file);
  };
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="body1" style={{ marginRight: "10px" }}>
        {file.name}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRemove}
        size="small"
        ml={1}
      >
        Remove
      </Button>
    </Box>
  );
};

const UploadExcelFile = ({ isOpen, onClose, plotName, samplingId, labDataRefetch }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [labName, setLabName] = useState("");
  const [excelFiles, setExcelFiles] = useState([]);
  const [excelFileNames, setExcelFileNames] = useState("");

  const mutation = useMutation(formData => uploadExcelFIle(formData), {
    onSuccess: () => {
      queryClient.invalidateQueries("uploadExcelFIle");
      labDataRefetch();
      onClose();
      toast.success('File uploaded successfully!');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleSave = () => {

    const formData = new FormData();
    formData.append("name", labName);
    formData.append("file", excelFiles[0]);
    formData.append("zoneSamplingId", samplingId);

    mutation.mutate(formData);
  };

  const handleClose = () => {
    setExcelFileNames("");
    setExcelFiles([]);
    onClose();
    setLabName("");
  };

  const handleExcelFileChange = event => {
    const files = Array.from(event.target.files);

    files.forEach(file => {
      const fileExtension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (['.xls', '.xlsx'].includes(`.${fileExtension}`)) {
        setExcelFiles(prevFiles => [...prevFiles, file]);
        setExcelFileNames(prevFileNames => [...prevFileNames, file.name]);
      } else {
        toast.error("Please upload only .xls");
      }
    });
    event.target.value = null;
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        <Box bgcolor="white" padding={4} style={{ minWidth: 700 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px"
            }}
          >
            Upload excel file for plot: {plotName}
          </Typography>
          <TextField
            className={classes.textField}
            label="Lab Name"
            variant="outlined"
            fullWidth
            value={labName}
            onChange={e => setLabName(e.target.value)}
          />
          <div className={`${classes.dropArea}`}>
            <CloudUploadIcon className={classes.dropAreaIcon} />
            <span className={classes.dropAreaText}>Upload your excel file</span>
            <input
              accept=".xls, .xlsx"
              className={classes.fileInput}
              id="excel-file"
              type="file"
              multiple
              onChange={handleExcelFileChange}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              {!excelFiles.length && (
                <label htmlFor="excel-file">
                  <Button variant="outlined" color="primary" component="span">
                    Browse
                  </Button>
                </label>
              )}
              {excelFileNames && (
                <Box ml={2}>
                  <Typography variant="body1">
                    {excelFiles.map((file, index) => (
                      <FileItem
                        key={index}
                        file={file}
                        onRemove={() => {
                          setExcelFiles(prevFiles =>
                            prevFiles.filter(f => f !== file)
                          );
                          setExcelFileNames(prevFileNames =>
                            prevFileNames.filter(name => name !== file.name)
                          );
                        }}
                      />
                    ))}
                  </Typography>
                </Box>
              )}
            </Box>
          </div>
          <Box
            mt={2}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!labName || !excelFiles.length}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => handleClose()}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default UploadExcelFile;
