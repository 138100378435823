import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';

import mock from 'utils/mock';

mock.onGet('/api/projects').reply(200, {
  projects: [
    {
      id: uuid(),
      title: 'Crop analysis',
      author: {
        name: 'Djordje Djordjevic',
        avatar: '/images/avatars/avatar_5.png'
      },
      price: '12,500',
      currency: '$',
      type: 'Full-Time',
      location: 'Europe',
      status: 'In progress',
      members: 5,
      tags: [
        {
          text: 'Agro',
          color: colors.red[600]
        }
      ],
      start_date: moment(),
      end_date: moment(),
      updated_at: moment().subtract(24, 'minutes')
    },
    {
      id: uuid(),
      title: 'Products promotion',
      author: {
        name: 'Slavica Simir',
        avatar: '/images/avatars/avatar_9.png'
      },
      price: '15,750',
      currency: '$',
      type: 'Full-Time',
      location: 'Europe',
      status: 'In progress',
      members: 3,
      tags: [
        {
          text: 'Media',
          color: colors.grey[600]
        }
      ],
      start_date: moment(),
      end_date: moment(),
      updated_at: moment().subtract(1, 'hour')
    },
    {
      id: uuid(),
      title: 'Weeds analysis',
      author: {
        name: 'Petar Petrovic',
        avatar: '/images/avatars/avatar_10.png'
      },
      price: '15,750',
      currency: '$',
      type: 'Full-Time',
      location: 'Europe',
      status: 'Completed',
      members: 8,
      tags: [
        {
          text: 'Field',
          color: colors.indigo[600]
        }
      ],
      start_date: moment(),
      end_date: moment(),
      updated_at: moment().subtract(16, 'hour')
    },
    {
      id: uuid(),
      title: 'Combined chemical analysis',
      author: {
        name: 'Jovana Miljković',
        avatar: '/images/avatars/avatar_11.png'
      },
      price: '12,500',
      currency: '$',
      type: 'Full-Time',
      location: 'Europe',
      status: 'In progress',
      members: 10,
      tags: [
        {
          text: 'Lab',
          color: colors.green[600]
        }
      ],
      start_date: moment(),
      end_date: moment(),
      updated_at: moment().subtract(3, 'days')
    },
    {
      id: uuid(),
      title: 'Add moderators',
      author: {
        name: 'Stefan Jovanović',
        avatar: '/images/avatars/avatar_3.png'
      },
      price: '15,750',
      currency: '$',
      type: 'Full-Time',
      location: 'Europe',
      status: 'Canceled',
      members: 2,
      tags: [
        {
          text: 'Experts',
          color: colors.red[600]
        },
        {
          text: 'Quality',
          color: colors.grey[600]
        }
      ],
      start_date: moment(),
      end_date: moment(),
      updated_at: moment().subtract(7, 'days')
    },
    {
      id: uuid(),
      title: 'Field preparation',
      author: {
        name: 'Djordje Djordjevic',
        avatar: '/images/avatars/avatar_5.png'
      },
      price: '15,750',
      currency: '$',
      type: 'Full-Time',
      location: 'Europe',
      status: 'Completed',
      members: 12,
      tags: [
        {
          text: 'Field',
          color: colors.green[600]
        }
      ],
      start_date: moment(),
      end_date: moment(),
      updated_at: moment().subtract(8, 'days')
    }
  ]
});

mock.onGet('/api/projects/1').reply(200, {
  project: {
    title: 'Create analysis',
    author: {
      name: 'Slavica Simir',
      avatar: '/images/avatars/avatar_9.png',
      bio: 'Get all samples and produce analysis.'
    },
    brief: `
    Message to the customers.
    `,
    price: '12,500',
    currency: 'USD',
    tags: [
      {
        text: 'Lab',
        color: colors.indigo[600]
      }
    ],
    members: [
      {
        id: uuid(),
        name: 'Sanja Petrović',
        avatar: '/images/avatars/avatar_2.png',
        bio: 'Front End Developer'
      },
      {
        id: uuid(),
        name: 'Milan Jovanovic',
        avatar: '/images/avatars/avatar_3.png',
        bio: 'UX Designer'
      },
      {
        id: uuid(),
        name: 'Djordje Djordjevic',
        avatar: '/images/avatars/avatar_5.png',
        bio: 'Copyright'
      }
    ],
    files: [
      {
        id: uuid(),
        name: 'example-project1.jpg',
        url: '/images/projects/bg2.jpg',
        mimeType: 'image/png',
        size: 1024 * 1024 * 3
      },
      {
        id: uuid(),
        name: 'docs.zip',
        url: '#',
        mimeType: 'application/zip',
        size: 1024 * 1024 * 25
      },
      {
        id: uuid(),
        name: 'example-project2.jpg',
        url: '/images/projects/bg1.jpg',
        mimeType: 'image/png',
        size: 1024 * 1024 * 2
      }
    ],
    activities: [
      {
        id: uuid(),
        subject: 'Project expert',
        subject_type: 'user',
        action_type: 'upload_file',
        action_desc: 'has uploaded a new file',
        created_at: moment().subtract(23, 'minutes')
      },
      {
        id: uuid(),
        subject: 'Adrian Kralj',
        subject_type: 'user',
        action_type: 'join_team',
        action_desc: 'joined team as agronomist',
        created_at: moment().subtract(2, 'hours')
      },
      {
        id: uuid(),
        subject: 'Robert Nadj',
        action_type: 'join_team',
        action_desc: 'joined team as a lawyer',
        created_at: moment().subtract(9, 'hours')
      },
      {
        id: uuid(),
        subject: 'Project owner',
        subject_type: 'user',
        action_type: 'price_change',
        action_desc: 'raised the project budget',
        created_at: moment().subtract(2, 'days')
      },
      {
        id: uuid(),
        subject: 'Contest',
        subject_type: 'project',
        action_type: 'contest_created',
        action_desc: 'created',
        created_at: moment().subtract(4, 'days')
      }
    ],
    subscribers: [
      {
        id: uuid(),
        name: 'Sanja Petrović',
        avatar: '/images/avatars/avatar_2.png',
        cover: '/images/covers/cover_1.jpg',
        common_connections: 12,
        labels: [
          'User Experience',
          'Plan health',
          'Lab',
          'Field',
          'Management'
        ]
      },
      {
        id: uuid(),
        name: 'Nikola Jankovic',
        avatar: '/images/avatars/avatar_3.png',
        cover: '/images/covers/cover_2.jpg',
        common_connections: 5,
        labels: [
          'Data Input',
          'Analysis',
          'Field',
          'Lab'
        ]
      },
      {
        id: uuid(),
        name: 'Vladimir Miletic',
        avatar: '/images/avatars/avatar_6.png',
        cover: '/images/covers/cover_2.jpg',
        common_connections: 17,
        labels: ['Accounts', 'Contracts', 'Legal', 'Misc']
      }
    ],
    deadline: moment().add(7, 'days'),
    updated_at: moment().subtract(23, 'minutes')
  }
});
