import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ArrowLeft from '@material-ui/icons/ArrowLeft';

const useStyles = makeStyles({
  rotate: {
    transform: 'rotate(180deg)',
  },
  openDrawer: {
    height: '100%',
    width: '250px',
    transition: 'width 0.25s ease-in-out',
    transitionDelay: 'padding 0.25s',
    overflow: 'hidden',
  },
  closedDrawer: {
    height: '100%',
    width: '0',
    transition: 'width 0.25s ease-in-out',
    overflow: 'hidden',
  },
  openArrow: {
    transition: 'transform 0.25s ease',
  },
  closedArrow: {
    transition: 'transform 0.25s ease',
    transform: 'rotate(180deg)',
  },
});

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDrawer = () => setIsOpen(!isOpen);

  const classes = useStyles();

  return (
    <>
      <Box
        display="inline-flex"
        alignItems="center"
        height="100%"
      >
        <Box className={isOpen ? classes.openDrawer : classes.closedDrawer}>
          {children}
        </Box>
        <Box
          css={{ backgroundColor: 'white', cursor: 'pointer' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          onClick={toggleDrawer}
        >
          <ArrowLeft
            className={isOpen ? classes.openArrow : classes.closedArrow}
          />
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
