export const UNDEFINED = undefined;

export const ranks = [
  'Founder',
  'Consultant',
  'Director',
  'CEO',
  'CT',
  'CFO',
  'CIO',
  'Agronomist',
  'PlantProtection',
  'MachineryEngineer',
];
