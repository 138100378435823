import uuid from 'uuid';

export const ADD_SUCCESS_MESSAGE = 'ADD_SUCCESS_MESSAGE';
export const ADD_INFO_MESSAGE = 'ADD_INFO_MESSAGE';
export const ADD_WARNING_MESSAGE = 'ADD_WARNING_MESSAGE';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export function addSuccessMessage(message) {
  return {
    type: ADD_SUCCESS_MESSAGE,
    id: uuid.v4(),
    message,
  };
}

export function addInfoMessage(message) {
  return {
    type: ADD_INFO_MESSAGE,
    id: uuid.v4(),
    message,
  };
}

export function addWarningMessage(message) {
  return {
    type: ADD_WARNING_MESSAGE,
    id: uuid.v4(),
    message,
  };
}


export function addErrorMessage(message) {
  return {
    type: ADD_ERROR_MESSAGE,
    id: uuid.v4(),
    message,
  };
}


export function removeMessage(id) {
  return {
    type: REMOVE_MESSAGE,
    id,
  };
}
