import React, { Fragment, useEffect, useContext, useState } from 'react';
import { ActiveOrgContext, AuthContext } from 'App';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  Paper,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import AuthImage from 'components/AuthImage';
import InputIcon from '@material-ui/icons/Input';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, drawerCollapsed, ...rest } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const router = useRouter();
  const [session: authContext] = useContext(AuthContext);
  const [activeOrg, setActiveOrg] = useContext(ActiveOrgContext);
  const [resetCounter, setResetCounter] = useState(0);
  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  useEffect(() => {
    setResetCounter(new Date());
  }, [activeOrg]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('userData');
    localStorage.removeItem('activeOrg');
    setActiveOrg(null);
    history.replace('/auth/login');
  };

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          key={session.picture}
          alt="Person"
          className={classes.avatar}
          children={
            <AuthImage src={activeOrg?.logo ? `${activeOrg.logo}?timestamp=${resetCounter}` : '/images/empty-organization.png'} />
          }
        />
        {!drawerCollapsed && <Typography className={classes.name} variant="h4">
          {activeOrg?.name}
        </Typography>}
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
          drawerCollapsed={drawerCollapsed}
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
      <Hidden lgUp>
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          {t('form:logout')}
        </Button>
      </Hidden>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
