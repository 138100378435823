import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';

import mock from 'utils/mock';

mock.onGet('/api/mail').reply(200, {
  emails: [
    {
      id: uuid(),
      seen: true,
      favorited: true,
      labels: [
        {
          text: 'Posao',
          color: colors.blue[600]
        },
        {
          text: 'Licno',
          color: colors.orange[600]
        }
      ],
      subject: 'Detaljni plan regulacije',
      message: `
Zdravo,

Trebalo bi da napravimo detaljni plan regulacije za sve parcele.

Sanja Petrović
      `,
      receiver: {
        name: 'Sanja Petrović',
        email: 'sanja.petrovic@acrefx.com',
        avatar: '/images/avatars/avatar_2.png'
      },
      created_at: moment()
    },
    {
      id: uuid(),
      seen: false,
      favorited: false,
      labels: [],
      subject: 'Odlicno uradjeno!',
      message:
        'Odlicno uradjena analiza, hoces da se sastanemo i popricamo o tome?',
      receiver: {
        name: 'Goran Petrovic',
        email: 'goran.petrovic@acrefx.com',
        avatar: '/images/avatars/avatar_7.png'
      },
      created_at: moment()
    },
    {
      id: uuid(),
      seen: false,
      favorited: false,
      subject: 'Podsetnik',
      labels: [
        {
          text: 'Posao',
          color: colors.green[600]
        }
      ],
      message:
        'Zdravo, pisem ti da te obavestim da je pocela izrada plana irigacije.',
      receiver: {
        name: 'Ivica Maksimovic',
        avatar: '/images/avatars/avatar_6.png'
      },
      created_at: moment()
    },
    {
      id: uuid(),
      seen: true,
      favorited: true,
      labels: [],
      subject: 'Moguce izmene u planu setve',
      message:
        'Podrav, poslednjih mesec dana su se desile promene koje ce iznuditi revidiranje plana setve. Moracemo da napravimo novi plan.',
      receiver: {
        name: 'Goran Minic',
        avatar: '/images/avatars/avatar_3.png'
      },
      created_at: moment()
    }
  ]
});
