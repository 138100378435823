export const LOCALE_EN = 'en';

/**
 * Get locale
 *
 * @return {string}
 */
export function getLocale() {
  return LOCALE_EN;
}
