import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Modal from '@mui/material/Modal';
import { useMutation } from 'react-query';
import { toast } from 'material-react-toastify';
import { deleteMap } from 'endpoints/fieldMaps';
import { deleteRxMap } from 'endpoints/rxMaps';
import { deleteSamplingMap } from 'endpoints/zoneSampling';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '24px',
    maxWidth: '400px',
    borderRadius: '8px',
    textAlign: 'center',
  },
  button: {
    margin: '8px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
  }
}));

const DeleteModal = ({ isOpen, onClose, plotData, refetch, type='map' }) => {
  const classes = useStyles();
  const plotName = plotData?.name;
  const id = plotData?.id;
 

  const mutation = useMutation(id => type === 'map' ? deleteMap(id) : type === 'rxMap' ? deleteRxMap(id) : deleteSamplingMap(id) , {
    onSuccess: async => {

      try {
        toast.success(type === 'map' ? 'Plot deleted successfully!' : type === 'rxMap' ? 'RxMap deleted successfully!' : 'Sampling Map deleted successfully!');
        onClose();
      } catch (error) {
        toast.error('Error deleting plots');
      }
    },
  });



  const onConfirm = async() => {
    await mutation.mutateAsync(id);
    refetch();
  };

  return (
    <Modal open={isOpen} onClose={onClose} className={classes.modal}>
      <Box className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          Delete Plot
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {`Are you sure you want to delete ${plotName}?`}
        </Typography>
        <Box className={classes.buttonBox}>
        <Button variant="contained" color="error" className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="success" className={classes.button} onClick={() => onConfirm(id)}>
          Ok
        </Button>
        </Box>
      </Box>
    </Modal>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  type: PropTypes.string,
};

export default DeleteModal;