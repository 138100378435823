import uuid from 'uuid/v1';
import moment from 'moment';

import mock from 'utils/mock';

mock.onGet('/api/kanban').reply(200, {
  lists: [
    {
      id: 'incoming',
      title: 'Incoming'
    },
    {
      id: 'in_progress',
      title: 'In progress'
    },
    {
      id: 'in_review',
      title: 'In review'
    },
    {
      id: 'completed',
      title: 'Completed'
    }
  ],
  tasks: [
    {
      id: uuid(),
      ref: '38',
      list: 'incoming',
      title: 'Call with sales of HubSpot',
      desc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      members: [
        '/images/avatars/avatar_1.png',
        '/images/avatars/avatar_5.png',
        '/images/avatars/avatar_6.png'
      ],
      files: 0,
      comments: 1,
      progress: 0,
      deadline: moment().add(7, 'days')
    },
    {
      id: uuid(),
      ref: '37',
      list: 'incoming',
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      desc:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiums',
      members: ['/images/avatars/avatar_2.png', '/images/avatars/avatar_3.png'],
      files: 0,
      comments: 2,
      progress: 0,
      deadline: moment().add(6, 'days')
    },
    {
      id: uuid(),
      ref: '39',
      list: 'incoming',
      title: 'Excepteur sint occaecat cupidatat non proident',
      desc:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      members: ['/images/avatars/avatar_11.png'],
      files: 0,
      comments: 0,
      progress: 0,
      deadline: moment().add(5, 'days')
    },
    {
      id: uuid(),
      ref: '19',
      list: 'incoming',
      title: 'Excepteur sint occaecat cupidatat non proident',
      desc:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      members: [
        '/images/avatars/avatar_6.png',
        '/images/avatars/avatar_5.png',
        '/images/avatars/avatar_9.png'
      ],
      files: 2,
      comments: 1,
      progress: 0,
      deadline: moment().add(4, 'days')
    },
    {
      id: uuid(),
      ref: '15',
      list: 'in_progress',
      title: 'Excepteur sint occaecat cupidatat non proident',
      desc:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      members: ['/images/avatars/avatar_10.png'],
      files: 2,
      comments: 0,
      progress: 60,
      deadline: moment().add(4, 'hours')
    },
    {
      id: uuid(),
      ref: '10',
      list: 'in_progress',
      title: 'Cupidatat non proident',
      desc:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      members: [
        '/images/avatars/avatar_10.png',
        '/images/avatars/avatar_11.png'
      ],
      files: 0,
      comments: 2,
      progress: 76,
      deadline: moment().add(4, 'hours')
    },
    {
      id: uuid(),
      ref: '24',
      list: 'in_review',
      title: 'Cupidatat non proident',
      desc:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      members: [
        '/images/avatars/avatar_6.png',
        '/images/avatars/avatar_4.png',
        '/images/avatars/avatar_2.png'
      ],
      files: 0,
      comments: 7,
      progress: 90,
      deadline: moment().subtract(1, 'days')
    },
    {
      id: uuid(),
      ref: '21',
      list: 'in_review',
      title: 'Cupidatat non proident',
      desc:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      members: [
        '/images/avatars/avatar_6.png',
        '/images/avatars/avatar_4.png',
        '/images/avatars/avatar_8.png',
        '/images/avatars/avatar_2.png'
      ],
      files: 0,
      comments: 7,
      progress: 90,
      deadline: moment().subtract(1, 'days')
    },
    {
      id: uuid(),
      ref: '17',
      list: 'completed',
      title: 'Cupidatat non proident',
      desc: 'officia deserunt mollit',
      members: [
        '/images/avatars/avatar_6.png',
        '/images/avatars/avatar_2.png',
        '/images/avatars/avatar_9.png'
      ],
      files: 3,
      comments: 3,
      progress: 100,
      deadline: moment().subtract(7, 'days')
    },
    {
      id: uuid(),
      ref: '23',
      list: 'completed',
      title: 'Cupidat',
      desc:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      members: [
        '/images/avatars/avatar_6.png',
        '/images/avatars/avatar_4.png',
        '/images/avatars/avatar_5.png',
        '/images/avatars/avatar_2.png',
        '/images/avatars/avatar_9.png'
      ],
      files: 1,
      comments: 0,
      progress: 100,
      deadline: moment().subtract(7, 'days')
    }
  ]
});
