import {
  ADD_SUCCESS_MESSAGE,
  ADD_INFO_MESSAGE,
  ADD_WARNING_MESSAGE,
  ADD_ERROR_MESSAGE,
  REMOVE_MESSAGE,
} from 'actions/alertActions';

const alertReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_SUCCESS_MESSAGE:
      return [
        ...state,
        {
          id: action.id,
          message: action.message || 'Success',
          severity: 'success',
        },
      ];
    case ADD_INFO_MESSAGE:
      return [
        ...state,
        {
          id: action.id,
          message: action.message || 'Info',
          severity: 'info',
        },
      ];
    case ADD_WARNING_MESSAGE:
      return [
        ...state,
        {
          id: action.id,
          message: action.message || 'Warning',
          severity: 'warning',
        },
      ];
    case ADD_ERROR_MESSAGE:
      return [
        ...state,
        {
          id: action.id,
          message: action.message || 'Error',
          severity: 'error',
        },
      ];
    case REMOVE_MESSAGE:
      return state.filter(item => item.id !== action.id);
    default:
      return state;
  }
};

export default alertReducer;
