import axios from 'axios';
import {logger} from 'utils/logger';
import telemetryService from 'services/telemetry.service';

const baseUrl = process.env.REACT_APP_API_HOST;

export class RestService {

  constructor(base = baseUrl) {
    this.baseUrl = base;
  }

  setDomain(domain) {
    this.baseUrl = `https://${domain}/api/v1/`;
    return this;
  }

  post(url, payload, options) {
    return axios.post(`${this.baseUrl}${url}`, payload, options)
      .then(response => response.data)
      .catch((error) => {
        logger.error(error);
        telemetryService.saveEvent(error);
      });
  }

  get(url, queryParams) {
    return axios.get(`${this.baseUrl}${url}`, {params: queryParams})
      .then(response => response.data)
      .catch((error, b, c) => {
        logger.error(error);
        telemetryService.saveEvent(error);
      });
  }

  put(url, payload) {
    return axios.put(`${this.baseUrl}${url}`, payload)
      .then(response => response.data)
      .catch((error) => {
        logger.error(error);
      });
  }

  destroy(url) {
    return axios.delete(`${this.baseUrl}${url}`)
      .then(response => response.data)
      .catch((error) => {
        logger.error(error);
        telemetryService.saveEvent(error);
      });
  }
}
