import authService from 'services/auth.service';
import OrganizationService from 'services/organization.service';
import _ from 'lodash';
import {logger} from 'utils/logger';
import {addErrorMessage} from 'actions/alertActions';
import {getResponsePayload, isOk} from 'utils/request-response';
import {getDefaultOrganization, getOrganizationDomain} from 'utils/user';
import {translate} from 'utils/translate';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_LOGIN_STATUS = 'SESSION_LOGIN_STATUS';
export const SESSION_SET_ACTIVE_ORGANIZATION = 'SESSION_SET_ACTIVE_ORGANIZATION';
export const SESSION_SET_SEASONS = 'SESSION_SET_SEASONS';
export const SESSION_SET_ACTIVE_SEASON = 'SESSION_SET_ACTIVE_SEASON';

export const setActiveOrganization = organization => {
  return {
    type: SESSION_SET_ACTIVE_ORGANIZATION,
    organization,
  };
};

export const setSeasons = seasons => {
  return {
    type: SESSION_SET_SEASONS,
    seasons,
  };
};

export const setActiveSeason = season => {
  return {
    type: SESSION_SET_ACTIVE_SEASON,
    season,
  };
};

export const setIsLoggedIn = isLoggedIn => {
  return {
    type: SESSION_LOGIN_STATUS,
    isLoggedIn,
  };
};

const sessionLogout = () => {
  return {
    type: SESSION_LOGOUT,
  };
};

const sessionLogin = (data) => {
  return {
    type: SESSION_LOGIN,
    userData: {
      first_name: data.given_name,
      last_name: data.family_name,
      email: data.email,
      avatar: data.photo_url,
      bio: data.title,
      role: 'ADMIN', // ['GUEST', 'USER', 'ADMIN'],
      organizations: data.organizations,
      telephones: data.telephones,
      addresses: data.addresses,
      notifications: data.notifications,
    },
  };
};

export const login = () => async dispatch => {
  try {
    const user = await authService.getMe();
    const notificationsResponse = await authService.getMeNotifications();
    const organizationsResponse = await authService.getOrganisations();
    const notifications = isOk(notificationsResponse) ? getResponsePayload(notificationsResponse) : [];
    const organizations = isOk(organizationsResponse) ? getResponsePayload(organizationsResponse) : [];
    if (!_.isEmpty(organizations)) {
      const defaultOrganization = getDefaultOrganization(organizations);
      const domain = getOrganizationDomain(defaultOrganization);
      if (!domain) {
        dispatch(addErrorMessage(translate('login.error.organizationNotAssigned')));
        throw new Error('login.error.organizationNotAssigned');
      }
      dispatch(fetchAndSetActiveOrganization(defaultOrganization));
    }
    dispatch(sessionLogin({
      ...user.payload,
      organizations,
      notifications,
    }));
  } catch (e) {
    logger.log(e);
    authService.deleteJwt();
    authService.deleteRefreshToken();
    dispatch(sessionLogout());
  }
};

export const refreshUserProfile = () => async dispatch => {
  try {
    const user = await authService.getMe();
    const notificationsResponse = await authService.getMeNotifications();
    const organizationsResponse = await authService.getOrganisations();
    const organizations = getResponsePayload(organizationsResponse);
    const notifications = getResponsePayload(notificationsResponse);
    if (organizations.length > 0) {
      const defaultOrganization = getDefaultOrganization(organizations);
      dispatch(fetchAndSetActiveOrganization(defaultOrganization));
      dispatch(sessionLogin({
        ...user.payload,
        organizations: organizations,
        notifications: notifications,
      }));
    }
  } catch (e) {
    logger.log(e);
    authService.deleteRefreshToken();
    authService.deleteJwt();
    dispatch(sessionLogout());
  }
};

export const logout = () => async dispatch => {
  try {
    await authService.logoutUser();
    authService.deleteRefreshToken();
    authService.deleteJwt();
    dispatch(sessionLogout());
  } catch (e) {
    logger.log(e);
    authService.deleteRefreshToken();
    authService.deleteJwt();
    dispatch(sessionLogout());
  }
};

export const fetchAndSetActiveOrganization = (org) => async dispatch => {
  try {
    const service = new OrganizationService();
    service.setDomain(getOrganizationDomain(org));
    const response = await service.getOrganization();
    const membersResponse = await service.getOrganizationMembers();
    if (!isOk(response) || !isOk(membersResponse)) {
      throw new Error(translate('organization.get.error'));
    }
    const organization = getResponsePayload(response);
    const members = getResponsePayload(membersResponse);
    dispatch(setActiveOrganization({
      ...organization,
      members,
    }));
  } catch (e) {
    logger.log(e);
    dispatch(addErrorMessage(translate('organization.get.error')));
  }
};
