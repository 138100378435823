import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

const ParcelSearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <TextField
      label="Search Parcels"
      variant="outlined"
      fullWidth
      value={searchTerm}
      onChange={handleSearchChange}
      style={{marginBottom: '10px'}}
    />
  );
};

export default ParcelSearchBar;