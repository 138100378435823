import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <g>
            <path
              className="st0"
              d="M41.6,49.5c-0.5,0-0.9,0-1.4,0l-1.4,0l-32.1,0c-1,0-1.9,0-2.5-0.6c-0.6-0.6-0.6-1.6-0.6-2.6
					c0.1-3.3,2.2-5.5,5.5-5.7l0.2,0v-0.9c-0.2,0-0.3,0.1-0.5,0.1c-3.8,0.6-6.5-1.3-7.5-5.2c-0.4-1.7-0.7-3.1,0-4.3
					c0.7-1.1,2.2-1.5,3.9-1.8c1.7-0.3,3.3-0.1,4.7,0.6c0.2-0.2,0.5-0.3,0.8-0.3h1.6c0.6,0,1.2,0.5,1.2,1.2v0.5
					c2.3-0.9,4.6-0.4,6.6,0.2c2,0.7,2,2.1,1.9,2.9c0,0.3-0.1,0.5-0.1,0.8c0,0.5-0.1,0.9-0.2,1.4c-0.5,2.2-1.5,3.8-2.9,4.7h12.5
					c-1.4-0.9-2.4-2.4-2.9-4.5c-0.9-4.1-0.5-4.9,2.9-5.7l0.8-0.2c1.6-0.4,3-0.3,4.4,0.3V30c0-0.6,0.5-1.2,1.2-1.2h1.7
					c0.3,0,0.6,0.1,0.8,0.3c2.7-1.4,5.3-0.6,7.6,0.3c0.9,0.4,1.3,1.6,1.3,2.4c0,0.9-0.2,1.9-0.4,2.9c-1,3.9-3.8,5.6-7.7,5l-0.2,0
					v0.9l0.4,0c1.8,0.1,3.3,0.9,4.4,2.3c1,1.4,1.3,3.1,0.9,4.7c-0.3,1-1.6,1.7-2.4,1.7C43.2,49.5,42.4,49.5,41.6,49.5z M6,47.1
					c0.3,0,0.6,0,0.8,0l33.5,0c1.2,0,2.4,0,3.6,0c0.1,0,0.2-0.1,0.3-0.1c0.2-0.9,0-1.8-0.6-2.6C43,43.5,42,43,41,43l-1.5-0.1
					c-0.4,0-0.7-0.2-0.9-0.5c-0.2,0.3-0.6,0.5-1,0.5H12.5c-0.4,0-0.7-0.2-1-0.5c-0.2,0.3-0.5,0.5-0.9,0.5L9.2,43
					c-2,0.1-3.2,1.3-3.2,3.4C6,46.5,6,46.9,6,47.1z M44.2,46.9L44.2,46.9L44.2,46.9z M14.4,39c2.8,0.4,4.4-0.8,5-3.7
					c0.1-0.4,0.1-0.7,0.2-1.1c0-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.2,0-0.2l0,0c0,0-0.1-0.1-0.4-0.2c-2.4-0.8-4.4-1-6.3,0.5
					c-0.4,0.3-0.8,0.3-1.2,0.1c-0.4-0.2-0.7-0.6-0.7-1.1v-0.6C11.1,32,11,32,10.9,32.1c-0.4,0.1-0.8,0.1-1.1-0.2
					c-1.2-1-2.5-1.3-4.2-1c-0.5,0.1-2.1,0.4-2.3,0.8C3.1,32,3.5,33.5,3.6,34c0.7,2.8,2.3,3.8,4.9,3.5c0,0,0,0,0.1,0
					c-0.5-0.3-1-0.6-1.4-1c-0.5-0.5-0.6-1.1-0.7-1.6c0-0.1,0-0.2,0-0.3c-0.1-0.3,0-0.6,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.5
					c0.1,0,0.2-0.1,0.3-0.1C8,33,8.6,32.8,9.2,33c1.8,0.6,2.4,1.6,2.6,3c0.4-0.6,1.1-1.1,2.1-1.5c0.7-0.2,2.2-0.1,2.6,0.9
					c0.2,0.7,0.3,1.9-0.5,2.5C15.5,38.4,14.9,38.7,14.4,39z M34,32.3c-0.4,0-0.8,0.1-1.3,0.2l-0.8,0.2c-0.6,0.2-1.4,0.3-1.5,0.5
					c-0.1,0.3,0.3,1.8,0.4,2.5c0.6,2.7,2,3.7,4.9,3.4c0,0,0,0,0.1,0c-0.5-0.3-1-0.6-1.4-1c-0.5-0.5-0.6-1.1-0.7-1.6
					c0-0.1,0-0.2,0-0.3c-0.1-0.3,0-0.6,0.2-0.9c0.2-0.3,0.4-0.4,0.8-0.5c0.1,0,0.2,0,0.3-0.1c0.4-0.1,1-0.3,1.7,0
					c0.5,0.3,1.4,0.7,2,1.5c0.1-1.7,1.1-2.8,2.8-3.2c0.8-0.2,1.9,0.2,2.3,1c0.4,0.8,0.2,2-0.5,2.5c-0.5,0.4-1,0.7-1.5,1.1
					c2.6,0.4,4.2-0.7,4.9-3.3c0.2-0.8,0.3-1.6,0.3-2.4c0-0.1,0-0.2-0.1-0.2c-2.5-1-4.3-1.3-6.1,0.1c-0.3,0.2-0.6,0.3-0.7,0.3
					c-0.4,0.1-0.7,0-1-0.2v0.9c0,0.5-0.3,0.9-0.7,1.1c-0.4,0.2-0.9,0.1-1.3-0.2C36,32.7,35,32.3,34,32.3z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M24.8,9.5c-4.9,0-9.7,0-14.6,0c-1.9,0-3.6-0.7-4.7-2C4.4,6.1,4,4.3,4.4,2.3c0.4-1.9,2-1.8,2.6-1.8l0.2,0
					c4.6,0,9.2,0,13.8,0l21.7,0c1,0,1.9,0,2.5,0.6c0.6,0.6,0.6,1.5,0.6,2.4c0,3.7-2.2,5.9-5.8,5.9C34.9,9.5,29.8,9.5,24.8,9.5z
					 M6.7,2.9c-0.2,1.3,0,2.3,0.6,3.1C8,6.7,9,7.1,10.2,7.1c9.9,0,19.8,0,29.7,0c2.4,0,3.5-1.1,3.5-3.6c0-0.2,0-0.5,0-0.7
					c-0.2,0-0.6,0-0.7,0l-17.4,0l0,0H21c-4.6,0-9.2,0-13.8,0l-0.3,0C6.9,2.9,6.8,2.9,6.7,2.9z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M39.4,30.6c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.7,0.3-1.1,0.2
					c-1.9-0.7-3.4-2.2-4.1-4.2c-0.6-1.8-0.5-3.6,0.4-5c0.8-1.4,2-2.5,3.2-3.5l0.2-0.2c0.8-0.7,2.1-0.5,2.9-0.2
					c0.7,0.3,1.2,0.8,1.7,1.2l0.2,0.2c1.9,1.8,2.9,4,2.6,6.1c-0.2,2.2-1.8,4.2-4.3,5.7C39.8,30.5,39.6,30.6,39.4,30.6z M38.4,19.2
					l-0.1,0.1c-1,0.9-2.1,1.9-2.8,3c-0.5,0.8-0.6,1.9-0.2,3c0.2,0.7,0.6,1.3,1.1,1.8c0-0.5,0-1,0.1-1.5c0.1-0.7,0.6-1.2,0.9-1.5
					c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.3,0.6-0.4,0.9-0.4c0.3,0,0.7,0.2,0.9,0.4c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.3,0.8,0.8,0.9,1.4
					c0,0.5,0.1,0.9,0.1,1.4c0.8-0.8,1.2-1.7,1.3-2.6c0.2-1.4-0.5-2.8-1.9-4.1l-0.2-0.2c-0.3-0.3-0.7-0.7-1-0.8
					C38.7,19.3,38.5,19.2,38.4,19.2z M38.3,19.2L38.3,19.2L38.3,19.2z M38.5,19.1L38.5,19.1C38.5,19.1,38.5,19.1,38.5,19.1z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M10.5,30.8c-0.3,0-0.5-0.1-0.7-0.3c-2-1.6-4.4-3.6-4.1-7c0.3-3.3,2.7-5.2,4.7-6.5c0.9-0.6,2.2,0,2.3,0.1
					c0.6,0.3,1.1,0.8,1.5,1.1c2.2,1.9,3.2,4.1,3,6.2c-0.2,2.2-1.6,4.3-4.2,6c-0.4,0.2-0.8,0.3-1.2,0c-0.1-0.1-0.2-0.1-0.3-0.2
					c-0.1,0.2-0.3,0.3-0.5,0.4C10.9,30.7,10.7,30.8,10.5,30.8z M11.5,23.5C11.6,23.5,11.6,23.5,11.5,23.5c0.4,0,0.8,0.2,1,0.6
					c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.4,0.7,0.9,0.7,1.5c0,0.3,0.1,0.7,0.1,1c0.8-0.9,1.3-1.8,1.3-2.7c0.1-1.7-1.1-3.3-2.2-4.2
					c-0.4-0.4-0.8-0.7-1.1-0.8c-0.1,0-0.1,0-0.2-0.1C9.1,20.6,8.1,22,8,23.7C7.9,25,8.4,26,9.3,26.9c0-0.3,0-0.7,0.1-1
					c0.1-0.7,0.5-1.2,0.9-1.6c0.1-0.1,0.2-0.2,0.3-0.3C10.8,23.7,11.2,23.5,11.5,23.5z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M29.6,10.1c0.2,2.8-1,4.2-3.6,4.2c-0.7,0-1.3,0-2,0c-2.6,0-3.8-1.5-3.4-4.2c0.3,0,0.7-0.1,1.1-0.1
					C24.3,10.1,26.9,10.1,29.6,10.1z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M19.8,16.3c-0.6,0.7-1.2,1.5-1.9,2.3c-0.1,0.1-0.3,0.1-0.4,0c-0.3-0.2-0.7-0.6-0.7-0.7
					c0.5-0.9,1.1-1.8,1.8-2.6c0.1-0.1,0.7,0.2,1,0.4C19.7,15.7,19.7,15.9,19.8,16.3z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M33.6,17.6c-0.3,0.4-0.5,0.7-0.8,0.8c-0.3,0.1-0.8,0-1-0.2c-0.5-0.5-0.9-1.2-1.3-1.8c-0.1-0.2,0-0.8,0.2-1
					c0.2-0.2,0.8-0.3,1-0.2C32.4,15.9,33,16.7,33.6,17.6z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M25.9,17.8c0,0.3,0.1,0.7,0,1c-0.2,0.4-0.5,0.6-0.8,0.9c-0.3-0.3-0.7-0.6-0.8-1c-0.1-0.5-0.2-1.1,0-1.6
					c0.1-0.4,0.6-0.8,0.9-1.1c0.3,0.4,0.6,0.7,0.8,1.1C26,17.2,25.9,17.5,25.9,17.8C25.9,17.8,25.9,17.8,25.9,17.8z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M25.9,23.1c0,0.4,0.1,0.8,0,1.1c-0.1,0.3-0.5,0.7-0.8,0.7c-0.3,0-0.7-0.4-0.8-0.7c-0.1-0.6-0.2-1.2,0-1.8
					c0.1-0.4,0.5-0.8,0.8-1.2c0.3,0.4,0.6,0.7,0.8,1.2C26,22.6,25.9,22.9,25.9,23.1C25.9,23.1,25.9,23.1,25.9,23.1z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
