import React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {Popup} from 'components/ErrorPopup';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    flexDirection: 'column',
    top: '65px',
  },
}));


const Popups = props => {
  const classes = useStyles();
  const alerts = useSelector(state => state.alerts);

  return (
    <div className={classes.root}>
      {alerts.map(({id, message, severity}) => (
        <Popup key={id} id={id} message={message} severity={severity}/>
      ))}
    </div>
  );
};

Popups.propTypes = {};

export default Popups;
