import React from 'react';
import PropTypes from 'prop-types';
import {Backdrop, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    position: 'absolute',
    color: '#fff',
  },
}));

const CircularLoader = props => {
  const {
    isLoading,
  } = props;

  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  );
};

CircularLoader.propTypes = {
  isLoading: PropTypes.bool,
};

CircularLoader.defaultProps = {
  isLoading: false,
};

export default CircularLoader;
