import React from 'react';

const ObstacleIcon = (props) => {
  return (
    <svg
      viewBox="0 0 428.65 351.63"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="25"
      {...props}
      style={{ verticalAlign: 'middle', ...props }}
    >
      <path fill="#F90000" d="M334.77,210.22c-8.78,0-54.66,0.38-61.87,0.38c-0.39-0.58-0.78-1.17-1.18-1.75c1.69-1.24,3.57-2.28,5.05-3.74
        c10.41-10.3,20.65-20.77,31.19-30.94c1.67-1.62,4.5-2.69,6.85-2.83c6.64-0.37,13.31-0.13,19.96-0.12
        C334.77,184.21,334.77,197.22,334.77,210.22z" />
      <path fill="#F90000" d="M109.77,118.2c8.78,0,54.82,0.05,62.99,0.05c-1.39,1.71-2.09,2.74-2.95,3.6
        c-11.04,11.07-22.01,22.21-33.24,33.09c-1.67,1.62-4.51,2.62-6.89,2.79c-6.61,0.47-13.27,0.36-19.91,0.47
        C109.77,144.87,109.77,131.54,109.77,118.2z" />
      <path fill="#F90000" d="M334.77,158.21c-15.79-0.12-31.58-0.24-47.38-0.34c-4.8-0.03-9.61,0-15.17,0c0.96-1.35,1.45-2.29,2.16-3
        c11.27-11.32,22.5-22.68,33.96-33.81c1.55-1.51,4.25-2.42,6.48-2.55c6.63-0.38,13.3-0.24,19.95-0.3
        C334.77,131.54,334.77,144.87,334.77,158.21z" />
      <path fill="#F90000" d="M109.77,171.21c20.76,0,41.51,0,62.27,0c0.26,0.38,0.52,0.76,0.78,1.14c-7.91,7.69-15.82,15.38-23.73,23.07
        c-0.24,0.23-0.48,0.46-0.72,0.70
        c-4.89,4.72-9.04,11.69-14.85,13.59
        c-7.2,2.36-15.77,0.53-23.75,0.50
        C109.77,197.22,109.77,184.21,109.77,171.21z" />
      <path fill="#F90000" d="M211.97,157.85c7.59-7.58,14.16-14.13,20.71-20.68c5.42-5.41,10.75-10.91,16.30-16.19
        c1.31-1.25,3.32-2.45,5.03-2.48
        c12.14-0.21,24.29-0.11,37.43-0.11
        c-1.37,1.61-2.19,2.70-3.14,3.65
        c-11.05,11.08-22.08,22.18-33.24,33.15
        c-1.29,1.27-3.31,2.52-5.01,2.55
        C237.93,157.95,225.80,157.85,211.97,157.85z" />
      <path fill="#F90000" d="M153.17,156.53c3.36-3.10,6.82-6.10,10.07-9.31
        c8.77-8.66,17.42-17.43,26.18-26.09
        c1.12-1.11,2.62-2.58,3.97-2.60
        c12.58-0.20,25.17-0.12,38.77-0.12
        c-1.73,1.93-2.74,3.15-3.85,4.26
        c-10.59-10.61-21.26-21.14-31.74-31.86
        c-2.42-2.48-4.89-3.45-8.32-3.39
        c-11.42-0.19-22.85-0.07-34.27-0.07
        C153.71,157.42,153.44,156.98,153.17,156.53z" />
      <path fill="#F90000" d="M292.09,171.20
        c-13.31,13.24-25.54,25.44-37.84,37.56
        c-0.86,0.85-2.27,1.59-3.43,1.60
        c-12.43,0.11-24.87,0.07-38.22,0.07
        c-1.48-1.70-2.33-2.80-3.31-3.78
        c-10.67-10.72-21.43-21.36-32.01-32.18
        c-2.40-2.46-4.84-3.41-8.27-3.35
        C267.64,171.33,279.09,171.20,292.09,171.20z" />
      <path fill="#F90000" d="M232.52,171.22
        c-13.17,13.16-25.27,25.29-37.46,37.34
        c-0.96,0.95-2.53,1.78-3.82,1.79
        c-12.29,0.13-24.59,0.08-37.71,0.08
        c-1.21-1.52-1.88-2.55-2.72-3.40
        c-11.05-11.08-22.10-22.16-33.22-33.17
        c-1.11-1.10-2.63-2.51-3.99-2.53
        C206.04,171.14,218.62,171.22,232.52,171.22z" />
      <g>
        <path fill="#5E5D5D" d="M168.71,252.42
          c-12.93,0-25.54,0-38.55,0
          c0-4.08,0-8.13,0-12.71
          c3.98,0,8.06,0,12.60,0
          c0-13.25,0-15.35,0-28.45
          c4.52,0,8.56,0,13.07,0
          c0-12.87,0-14.97,0-28.25
          c4.50,0,8.57,0,12.88,0
          C168.71,243.98,168.71,247.91,168.71,252.42z" />
        <path fill="#5E5D5D" d="M143.28,105.15
          c4.09,0,8.03,0,12.30,0
          c0,8.52,0,4.11,0,12.80
          c-4,0-7.94,0-12.30,0
          C143.28,109.71,143.28,114.12,143.28,105.15z" />
      </g>
    </svg>
  );
};

export default ObstacleIcon;
