import {RestService} from './rest.service';

class ConfigService extends RestService {
  getCrops() {
    return super.get('/crops');
  }

  getCountries() {
    return super.get('/countries');
  }

  getStates() {
    return super.get('/countries/states');
  }
}

const configService = new ConfigService();

export default configService;
